/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BooleanResponse } from '../models/boolean-response';
import { RegisterWabaPhoneNumberRequest } from '../models/register-waba-phone-number-request';

@Injectable({ providedIn: 'root' })
export class MessagingWhatsAppOnboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingWhatsAppOnboardRegisterWabaPhoneNumber()` */
  static readonly MessagingWhatsAppOnboardRegisterWabaPhoneNumberPath = '/api/messaging-whats-app-onboard/register-waba-phone-number';

  /**
   * Register Waba Phone Number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppOnboardRegisterWabaPhoneNumber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppOnboardRegisterWabaPhoneNumber$Response(
    params: {
      body: RegisterWabaPhoneNumberRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingWhatsAppOnboardService.MessagingWhatsAppOnboardRegisterWabaPhoneNumberPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponse>;
      })
    );
  }

  /**
   * Register Waba Phone Number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppOnboardRegisterWabaPhoneNumber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppOnboardRegisterWabaPhoneNumber(
    params: {
      body: RegisterWabaPhoneNumberRequest
    },
    context?: HttpContext
  ): Observable<BooleanResponse> {
    return this.messagingWhatsAppOnboardRegisterWabaPhoneNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

}
