/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ActivityDashboardGrandTotalsResponse } from '../models/activity-dashboard-grand-totals-response';
import { ActivityDashboardRequest } from '../models/activity-dashboard-request';
import { ActivityDashboardResponse } from '../models/activity-dashboard-response';
import { ActivityDashboardTimeSeriesTotalsResponse } from '../models/activity-dashboard-time-series-totals-response';
import { ActivityDashboardTopByAgentsResponse } from '../models/activity-dashboard-top-by-agents-response';
import { ActivityDashboardTopByGroupsResponse } from '../models/activity-dashboard-top-by-groups-response';

@Injectable({ providedIn: 'root' })
export class ActivityDashboardService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardData()` */
  static readonly ActivityDashboardV2GetActivityDashboardDataPath = '/api/reports-v2/activity-dashboard-data';

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ActivityDashboardService.ActivityDashboardV2GetActivityDashboardDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardResponse>;
      })
    );
  }

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardResponse> {
    return this.activityDashboardV2GetActivityDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardResponse>): ActivityDashboardResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardGrandTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardGrandTotalsPath = '/api/reports-v2/activity-dashboard-grand-totals';

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardGrandTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardGrandTotalsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ActivityDashboardService.ActivityDashboardV2GetActivityDashboardGrandTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardGrandTotalsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardGrandTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardGrandTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardGrandTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardGrandTotalsResponse>): ActivityDashboardGrandTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTimeSeriesTotalsPath = '/api/reports-v2/activity-dashboard-time-series-totals';

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ActivityDashboardService.ActivityDashboardV2GetActivityDashboardTimeSeriesTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardTimeSeriesTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>): ActivityDashboardTimeSeriesTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByGroupTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByGroupTotalsPath = '/api/reports-v2/activity-dashboard-top-groups';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByGroupTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardTopByGroupsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ActivityDashboardService.ActivityDashboardV2GetActivityDashboardTopByGroupTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardTopByGroupsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByGroupTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardTopByGroupsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByGroupsResponse>): ActivityDashboardTopByGroupsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByAgentTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByAgentTotalsPath = '/api/reports-v2/activity-dashboard-top-agents';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByAgentTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardTopByAgentsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ActivityDashboardService.ActivityDashboardV2GetActivityDashboardTopByAgentTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardTopByAgentsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByAgentTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardTopByAgentsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByAgentsResponse>): ActivityDashboardTopByAgentsResponse => r.body)
    );
  }

}
