export enum RolesEnum {
  DBAdmin = 'dbAdmin',
  Any = 'any',
  Support = 'support',

  AccountProfile_View = 'AccountProfile.View',
  AccountProfile_Edit = 'AccountProfile.Edit',

  AccountUsers_View = 'AccountUsers.View',
  AccountUsers_Edit = 'AccountUsers.Edit',
  AccountUsers_Delete = 'AccountUsers.Delete',

  AccountRoles_View = 'AccountRoles.View',
  AccountRoles_Edit = 'AccountRoles.Edit',
  AccountRoles_Delete = 'AccountRoles.Delete',

  AccountSettings_View = 'AccountSettings.View',
  AccountSettings_Edit = 'AccountSettings.Edit',
  AccountSettings_Delete = 'AccountSettings.Delete',

  AccountStorage_View = 'AccountStorage.View',
  AccountStorage_Edit = 'AccountStorage.Edit',
  AccountStorage_Delete = 'AccountStorage.Delete',

  Reports_View = 'Reports.View',
  Reports_Edit = 'Reports.Edit',
  Reports_Delete = 'Reports.Delete',
  Reports_Extensions = 'Reports.Extensions',
  Reports_Groups = 'Reports.Groups',
  Reports_Departments = 'Reports.Departments',
  Reports_OtherAccounts = 'Reports.OtherAccounts',
  Reports_Exclude_Extensions = 'Reports.Exclude_Extensions',

  ActivityDashboard_View = 'ActivityDashboard.View',
  ActivityDashboard_Edit = 'ActivityDashboard.Edit',
  ActivityDashboard_Delete = 'ActivityDashboard.Delete',

  UsersDashboard_View = 'UsersDashboard.View',
  UsersDashboard_Edit = 'UsersDashboard.Edit',
  UsersDashboard_Delete = 'UsersDashboard.Delete',

  UsersSummary_View = 'UsersSummary.View',
  UsersSummary_Edit = 'UsersSummary.Edit',
  UsersSummary_Delete = 'UsersSummary.Delete',

  GroupsDashboard_View = 'GroupsDashboard.View',
  GroupsDashboard_Edit = 'GroupsDashboard.Edit',
  GroupsDashboard_Delete = 'GroupsDashboard.Delete',

  GroupsSummary_View = 'GroupsSummary.View',
  GroupsSummary_Edit = 'GroupsSummary.Edit',
  GroupsSummary_Delete = 'GroupsSummary.Delete',

  HuntGroupsDashboardView = 'HuntGroupsDashboard.View',
  HuntGroupsDashboardEdit = 'HuntGroupsDashboard.Edit',
  HuntGroupsDashboardDelete = 'HuntGroupsDashboard.Delete',

  CustomReportsView = 'CustomReports.View',
  CustomReportsEdit = 'CustomReports.Edit',
  CustomReportsDelete = 'CustomReports.Delete',

  CallLogs_View = 'CallLogs.View',
  CallLogs_Edit = 'CallLogs.Edit',
  CallLogs_Delete = 'CallLogs.Delete',

  BasicCallLogs_View = 'BasicCallLogs.View',
  BasicCallLogs_Edit = 'BasicCallLogs.Edit',
  BasicCallLogs_Delete = 'BasicCallLogs.Delete',

  Recordings_View = 'Recordings.View',
  Recordings_Edit = 'Recordings.Edit',
  Recordings_Delete = 'Recordings.Delete',
  Recordings_Extensions = 'Recordings.Extensions',
  Recordings_Groups = 'Recordings.Groups',
  Recordings_Departments = 'Recordings.Departments',
  Recordings_OtherAccounts = 'Recordings.OtherAccounts',
  Recordings_Exclude_Extensions = 'Recordings.Exclude_Extensions',

  Surveys_View = 'Surveys.View',
  Surveys_Edit = 'Surveys.Edit',
  Surveys_Delete = 'Surveys.Delete',

  PhoneNumbers_View = 'PhoneNumbers.View',
  PhoneNumbers_Edit = 'PhoneNumbers.Edit',
  PhoneNumbers_Delete = 'PhoneNumbers.Delete',
  PhoneNumbers_AssignUser = 'PhoneNumbers.AssignUser',
  PhoneNumbers_Export = 'PhoneNumbers.Export',

  Firewall_View = 'Firewall.View',
  Firewall_Edit = 'Firewall.Edit',
  Firewall_Delete = 'Firewall.Delete',

  Messaging_View = 'Messaging.View',
  Messaging_Edit = 'Messaging.Edit',
  Messaging_Delete = 'Messaging.Delete',

  MessageBroadcasting_View = 'MessageBroadcasting.View',
  MessageBroadcasting_Edit = 'MessageBroadcasting.Edit',
  MessageBroadcasting_Delete = 'MessageBroadcasting.Delete',

  ProMessaging_View = 'ProMessaging.View',
  ProMessaging_Edit = 'ProMessaging.Edit',
  ProMessaging_Delete = 'ProMessaging.Delete',

  MessagingAutomation_View = 'MessagingAutomation.View',
  MessagingAutomation_Edit = 'MessagingAutomation.Edit',
  MessagingAutomation_Delete = 'MessagingAutomation.Delete',

  MessagingTemplateView = 'MessagingTemplate.View',
  MessagingTemplateEdit = 'MessagingTemplate.Edit',
  MessagingTemplateDelete = 'MessagingTemplate.Delete',

  MessagingPrivateTemplateView = 'MessagingPrivateTemplate.View',
  MessagingPrivateTemplateEdit = 'MessagingPrivateTemplate.Edit',
  MessagingPrivateTemplateDelete = 'MessagingPrivateTemplate.Delete',

  MessagingWhatsAppTemplateView = 'MessagingWhatsAppTemplate.View',
  MessagingWhatsAppTemplateEdit = 'MessagingWhatsAppTemplate.Edit',
  MessagingWhatsAppTemplateDelete = 'MessagingWhatsAppTemplate.Delete',

  ProMessagingReports_View = 'ProMessagingReports.View',
  ProMessagingReports_Edit = 'ProMessagingReports.Edit',
  ProMessagingReports_Delete = 'ProMessagingReports.Delete',

  Fax_View = 'Fax.View',
  Fax_Edit = 'Fax.Edit',
  Fax_Delete = 'Fax.Delete',

  FaxReportView = 'FaxReport.View',
  FaxReportEdit = 'FaxReport.Edit',
  FaxReportDelete = 'FaxReport.Delete',

  Pbx_View = 'Pbx.View',
  Pbx_Edit = 'Pbx.Edit',
  Pbx_Delete = 'Pbx.Delete',

  PbxHolidayGreetings_View = 'PbxHolidayGreetings.View',
  PbxHolidayGreetings_Edit = 'PbxHolidayGreetings.Edit',
  PbxHolidayGreetings_Delete = 'PbxHolidayGreetings.Delete',

  PbxAccounts_View = 'PbxAccounts.View',
  PbxAccounts_Edit = 'PbxAccounts.Edit',
  PbxAccounts_EditOwn = 'PbxAccounts.EditOwn',
  PbxAccounts_Delete = 'PbxAccounts.Delete',

  PrivateContact_View = 'PrivateContact.View',
  PrivateContact_Edit = 'PrivateContact.Edit',
  PrivateContact_Delete = 'PrivateContact.Delete',
  CompanyContact_View = 'CompanyContact.View',
  CompanyContact_Edit = 'CompanyContact.Edit',
  CompanyContact_Delete = 'CompanyContact.Delete',

  Tags_View = 'Tags.View',
  Tags_Edit = 'Tags.Edit',
  Tags_Delete = 'Tags.Delete',

  Audit_View = 'Audit.View',
  Audit_Edit = 'Audit.Edit',
  Audit_Delete = 'Audit.Delete',

  SoftPhoneView = 'SoftPhone.View',
  SoftPhoneEdit = 'SoftPhone.Edit',
  SoftPhoneDelete = 'SoftPhone.Delete',

  BasicSoftPhoneView = 'BasicSoftPhone.View',
  BasicSoftPhoneEdit = 'BasicSoftPhone.Edit',
  BasicSoftPhoneDelete = 'BasicSoftPhone.Delete',

  HhaView = 'Hha.View',
  HhaEdit = 'Hha.Edit',
  HhaDelete = 'Hha.Delete',

  CustomFieldsView = 'CustomFields.View',
  CustomFieldsEdit = 'CustomFields.Edit',
  CustomFieldsDelete = 'CustomFields.Delete',

  VideoConferenceView = 'VideoConference.View',
  VideoConferenceEdit = 'VideoConference.Edit',
  VideoConferenceDelete = 'VideoConference.Delete',
}
