/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { LookupGridResponse } from '../models/lookup-grid-response';

@Injectable({ providedIn: 'root' })
export class LookupService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lookupGetRoles()` */
  static readonly LookupGetRolesPath = '/api/lookup/roles';

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetRoles$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupGetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetRoles(
    params?: {
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.lookupGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerUsers()` */
  static readonly LookupGetCustomerUsersPath = '/api/lookup/customer-users';

  /**
   * Get available customer users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUsers$Response(
    params?: {
      onlySms?: boolean;
      onlyFax?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupGetCustomerUsersPath, 'get');
    if (params) {
      rb.query('onlySms', params.onlySms, {});
      rb.query('onlyFax', params.onlyFax, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get available customer users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUsers(
    params?: {
      onlySms?: boolean;
      onlyFax?: boolean;
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.lookupGetCustomerUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerUserEmails()` */
  static readonly LookupGetCustomerUserEmailsPath = '/api/lookup/customer-user-emails';

  /**
   * Get available customer users emails.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerUserEmails()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmails$Response(
    params?: {
      onlySms?: boolean;
      onlyFax?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupGetCustomerUserEmailsPath, 'get');
    if (params) {
      rb.query('onlySms', params.onlySms, {});
      rb.query('onlyFax', params.onlyFax, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get available customer users emails.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerUserEmails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmails(
    params?: {
      onlySms?: boolean;
      onlyFax?: boolean;
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.lookupGetCustomerUserEmails$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerUserEmailDomains()` */
  static readonly LookupGetCustomerUserEmailDomainsPath = '/api/lookup/customer-user-email-domains';

  /**
   * Get available customer users email domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerUserEmailDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmailDomains$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupGetCustomerUserEmailDomainsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get available customer users email domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerUserEmailDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerUserEmailDomains(
    params?: {
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.lookupGetCustomerUserEmailDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetCustomerExtensions()` */
  static readonly LookupGetCustomerExtensionsPath = '/api/lookup/pbx-accounts';

  /**
   * Get pbx accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetCustomerExtensions()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerExtensions$Response(
    params?: {
      types?: Array<string>;
      includeOnlyPrimary?: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupGetCustomerExtensionsPath, 'get');
    if (params) {
      rb.query('types', params.types, {});
      rb.query('includeOnlyPrimary', params.includeOnlyPrimary, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get pbx accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetCustomerExtensions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetCustomerExtensions(
    params?: {
      types?: Array<string>;
      includeOnlyPrimary?: boolean;
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.lookupGetCustomerExtensions$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupGetSmsNumbers()` */
  static readonly LookupGetSmsNumbersPath = '/api/lookup/extension-sms-numbers';

  /**
   * Get sms numbers for pbx extension.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupGetSmsNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetSmsNumbers$Response(
    params: {

    /**
     * User pbx extension
     */
      extension: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupGetSmsNumbersPath, 'get');
    if (params) {
      rb.query('extension', params.extension, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get sms numbers for pbx extension.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupGetSmsNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupGetSmsNumbers(
    params: {

    /**
     * User pbx extension
     */
      extension: string;
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.lookupGetSmsNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `lookupLookupContactsAndTags()` */
  static readonly LookupLookupContactsAndTagsPath = '/api/lookup/lookup-contacts';

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupLookupContactsAndTags$Response(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupLookupContactsAndTagsPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactTagLookupModel>>;
      })
    );
  }

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupLookupContactsAndTags(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<Array<ContactTagLookupModel>> {
    return this.lookupLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

  /** Path part for operation `lookupCustomerDomains()` */
  static readonly LookupCustomerDomainsPath = '/api/lookup/lookup-domains';

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lookupCustomerDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCustomerDomains$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, LookupService.LookupCustomerDomainsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lookupCustomerDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lookupCustomerDomains(
    params?: {
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.lookupCustomerDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

}
