/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AutomationModel } from '../models/automation-model';
import { AutomationSettingsRequest } from '../models/automation-settings-request';
import { AutomationSettingsResponse } from '../models/automation-settings-response';
import { LookupModel } from '../models/lookup-model';

@Injectable({ providedIn: 'root' })
export class MessagingAutomationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingAutomationLookupPhoneNumbers()` */
  static readonly MessagingAutomationLookupPhoneNumbersPath = '/api/messaging-automation/lookup-phone-numbers';

  /**
   * Get all pro sms active phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationLookupPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationLookupPhoneNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<LookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingAutomationService.MessagingAutomationLookupPhoneNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LookupModel>>;
      })
    );
  }

  /**
   * Get all pro sms active phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationLookupPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationLookupPhoneNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<LookupModel>> {
    return this.messagingAutomationLookupPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `messagingAutomationGetAutomationList()` */
  static readonly MessagingAutomationGetAutomationListPath = '/api/messaging-automation/list';

  /**
   * List of all messaging automations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationGetAutomationList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationGetAutomationList$Response(
    params: {
      body: AutomationSettingsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AutomationSettingsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingAutomationService.MessagingAutomationGetAutomationListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AutomationSettingsResponse>;
      })
    );
  }

  /**
   * List of all messaging automations.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationGetAutomationList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationGetAutomationList(
    params: {
      body: AutomationSettingsRequest
    },
    context?: HttpContext
  ): Observable<AutomationSettingsResponse> {
    return this.messagingAutomationGetAutomationList$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutomationSettingsResponse>): AutomationSettingsResponse => r.body)
    );
  }

  /** Path part for operation `messagingAutomationGetAutomationSettingsById()` */
  static readonly MessagingAutomationGetAutomationSettingsByIdPath = '/api/messaging-automation/{id}';

  /**
   * Get automation settings details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationGetAutomationSettingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationGetAutomationSettingsById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AutomationModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingAutomationService.MessagingAutomationGetAutomationSettingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AutomationModel>;
      })
    );
  }

  /**
   * Get automation settings details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationGetAutomationSettingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationGetAutomationSettingsById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AutomationModel> {
    return this.messagingAutomationGetAutomationSettingsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutomationModel>): AutomationModel => r.body)
    );
  }

  /** Path part for operation `messagingAutomationDeleteAutomationSettingsById()` */
  static readonly MessagingAutomationDeleteAutomationSettingsByIdPath = '/api/messaging-automation/{id}';

  /**
   * Delete automation settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationDeleteAutomationSettingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationDeleteAutomationSettingsById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingAutomationService.MessagingAutomationDeleteAutomationSettingsByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete automation settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationDeleteAutomationSettingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingAutomationDeleteAutomationSettingsById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.messagingAutomationDeleteAutomationSettingsById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingAutomationSaveAutomationSetting()` */
  static readonly MessagingAutomationSaveAutomationSettingPath = '/api/messaging-automation/save';

  /**
   * Save automation settings details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingAutomationSaveAutomationSetting()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationSaveAutomationSetting$Response(
    params: {
      body: AutomationModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AutomationModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingAutomationService.MessagingAutomationSaveAutomationSettingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AutomationModel>;
      })
    );
  }

  /**
   * Save automation settings details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingAutomationSaveAutomationSetting$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingAutomationSaveAutomationSetting(
    params: {
      body: AutomationModel
    },
    context?: HttpContext
  ): Observable<AutomationModel> {
    return this.messagingAutomationSaveAutomationSetting$Response(params, context).pipe(
      map((r: StrictHttpResponse<AutomationModel>): AutomationModel => r.body)
    );
  }

}
