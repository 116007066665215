import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {NotificationService} from '../../notification/notification.service';
import {AuthService} from '../security/auth.service';

@Injectable({providedIn: 'root'})
export class ApiInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private notificationService: NotificationService, private cookieService: CookieService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log('api interceptor: ' + req.url);
    const canaryCookieBefore = this.cookieService.get('x-beta-customer');
    // Apply the headers
    /*req = req.clone({
      setHeaders: {
        'ApiToken': '234567890'
      }
    });*/

    // Also handle errors globally
    return next.handle(req).pipe(
      tap(x => {
        const canaryCookieAfter = this.cookieService.get('x-beta-customer');

        if (canaryCookieBefore !== canaryCookieAfter) {
          console.log('canary cookie is not equal');
          if (location.href.indexOf('/auth/login') === -1) {
            console.log('canary cookie is not equal - reload page');
            location.reload();
          } /*else {
            const queryString = window.location.hash.split('?')[1] || '';
            const urlParams = new URLSearchParams(queryString);
            const redirectUrl = decodeURIComponent(urlParams.get('redirectUrl') || '/');
            location.replace(`/#${redirectUrl}`);
          }*/
        }

        return x;
      }, err => {
        // Handle this err
        if (err.status === 401) {
          // do redirect to login
          console.log(`Request Status 401: do logout ${location.href}`);
          this.authService.logout().catch((_err) => {
            console.error(_err);
          });
        } else {
          try {
            let body: any = null;
            if (err instanceof HttpErrorResponse) {
              body = typeof err.error === 'string' || err.error instanceof String ? JSON.parse(err.error.toString()) : err.error;
            } else {
              body = err.json();
            }

            err.error = body;

            if (body && body.status !== 404.109 && body.message) {
              if (body.message instanceof Object) {
                this.notificationService.notifyDanger(JSON.stringify(body.message));
              } else {
                this.notificationService.notifyDanger(body.message);
              }
            }
          } catch (e) {
            console.error(err);
            this.notificationService.notifyDanger('Unknown server error occurred');
          }
        }
      })
    );
  }
}
