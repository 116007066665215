/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CustomReportListRequest } from '../models/custom-report-list-request';
import { CustomReportListResponse } from '../models/custom-report-list-response';
import { CustomReportModel } from '../models/custom-report-model';
import { CustomerDomainModel } from '../models/customer-domain-model';
import { UnsubscribeReportRequest } from '../models/unsubscribe-report-request';

@Injectable({ providedIn: 'root' })
export class CustomReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `customReportsGetList()` */
  static readonly CustomReportsGetListPath = '/api/custom-reports/list';

  /**
   * Get list of custom reports.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsGetList$Response(
    params: {
      body: CustomReportListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomReportListResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsGetListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomReportListResponse>;
      })
    );
  }

  /**
   * Get list of custom reports.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsGetList(
    params: {
      body: CustomReportListRequest
    },
    context?: HttpContext
  ): Observable<CustomReportListResponse> {
    return this.customReportsGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportListResponse>): CustomReportListResponse => r.body)
    );
  }

  /** Path part for operation `customReportsSave()` */
  static readonly CustomReportsSavePath = '/api/custom-reports/report';

  /**
   * Save custom report details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsSave()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsSave$Response(
    params: {
      body: CustomReportModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomReportModel>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsSavePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomReportModel>;
      })
    );
  }

  /**
   * Save custom report details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsSave$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsSave(
    params: {
      body: CustomReportModel
    },
    context?: HttpContext
  ): Observable<CustomReportModel> {
    return this.customReportsSave$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportModel>): CustomReportModel => r.body)
    );
  }

  /** Path part for operation `customReportsGetById()` */
  static readonly CustomReportsGetByIdPath = '/api/custom-reports/report/{id}';

  /**
   * Get custom report details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsGetById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomReportModel>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsGetByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomReportModel>;
      })
    );
  }

  /**
   * Get custom report details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsGetById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<CustomReportModel> {
    return this.customReportsGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportModel>): CustomReportModel => r.body)
    );
  }

  /** Path part for operation `customReportsDeleteById()` */
  static readonly CustomReportsDeleteByIdPath = '/api/custom-reports/report/{id}';

  /**
   * Delete custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDeleteById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsDeleteByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDeleteById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.customReportsDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `customReportsDownloadById()` */
  static readonly CustomReportsDownloadByIdPath = '/api/custom-reports/download/{id}';

  /**
   * Download custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsDownloadById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDownloadById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsDownloadByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Download custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsDownloadById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsDownloadById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.customReportsDownloadById$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsPreviewById()` */
  static readonly CustomReportsPreviewByIdPath = '/api/custom-reports/preview/{id}';

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsPreviewById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsPreviewById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsPreviewByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsPreviewById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsPreviewById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<string> {
    return this.customReportsPreviewById$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsUnsubscribe()` */
  static readonly CustomReportsUnsubscribePath = '/api/custom-reports/unsubscribe';

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsUnsubscribe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsUnsubscribe$Response(
    params: {
      body: UnsubscribeReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsUnsubscribePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Send email custom report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsUnsubscribe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customReportsUnsubscribe(
    params: {
      body: UnsubscribeReportRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.customReportsUnsubscribe$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsCustomerDomains()` */
  static readonly CustomReportsCustomerDomainsPath = '/api/custom-reports/lookup-domains';

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsCustomerDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsCustomerDomains$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CustomerDomainModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsCustomerDomainsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerDomainModel>>;
      })
    );
  }

  /**
   * Get a list of customer domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsCustomerDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsCustomerDomains(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CustomerDomainModel>> {
    return this.customReportsCustomerDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomerDomainModel>>): Array<CustomerDomainModel> => r.body)
    );
  }

  /** Path part for operation `customReportsTriggerScheduler()` */
  static readonly CustomReportsTriggerSchedulerPath = '/api/custom-reports/trigger-scheduler';

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsTriggerScheduler()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsTriggerScheduler$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsTriggerSchedulerPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsTriggerScheduler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsTriggerScheduler(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.customReportsTriggerScheduler$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `customReportsFixCustomReports()` */
  static readonly CustomReportsFixCustomReportsPath = '/api/custom-reports/update-custom-reports';

  /**
   * Update custom reports with additional data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customReportsFixCustomReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsFixCustomReports$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomReportModel>> {
    const rb = new RequestBuilder(this.rootUrl, CustomReportsService.CustomReportsFixCustomReportsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomReportModel>;
      })
    );
  }

  /**
   * Update custom reports with additional data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customReportsFixCustomReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customReportsFixCustomReports(
    params?: {
    },
    context?: HttpContext
  ): Observable<CustomReportModel> {
    return this.customReportsFixCustomReports$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomReportModel>): CustomReportModel => r.body)
    );
  }

}
