import {Injectable} from '@angular/core';
import {PreloadingStrategy, Route} from '@angular/router';
import {Observable, of, timer} from 'rxjs';
import {AuthService} from './core/security/auth.service';
import {HeaderService} from './core/services/header.service';
import {switchMap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class SelectivePreloadingStrategyService implements PreloadingStrategy {

  constructor(/*private authService: AuthService, private headerService: HeaderService*/) {
  }

  preload(route: Route, load: () => Observable<any>): Observable<any> {
    return of(null);

    /*console.log('preload', route.path);
    const userMenu = this.headerService.getUserApps();
    const menuItem = userMenu.find(x => x.url.startsWith(`/${route.path}/`));

    if ((route.data && route.data['preload']) || menuItem) {
      // add the route path to the preloaded module array
      return timer(1000).pipe(
        switchMap(() => load())
      );
    } else {
      return of(null);
    }*/
  }
}
