/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AcrobitsContactsResponse } from '../models/acrobits-contacts-response';
import { AcrobitsRegisterDeviceRequest } from '../models/acrobits-register-device-request';
import { AcrobitsSendMessageRequest } from '../models/acrobits-send-message-request';

@Injectable({ providedIn: 'root' })
export class PublicBasicSoftphoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `publicBasicSoftphoneSoftPhoneGetMessages()` */
  static readonly PublicBasicSoftphoneSoftPhoneGetMessagesPath = '/api/public-basic-softphone/soft-phone-get-messages';

  /**
   * Get messages for softphone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneSoftPhoneGetMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneSoftPhoneGetMessages$Response(
    params: {
      username: string;
      password: string;
      last_id: string;
      last_sent_id: string;
      device: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicBasicSoftphoneService.PublicBasicSoftphoneSoftPhoneGetMessagesPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('password', params.password, {});
      rb.query('last_id', params.last_id, {});
      rb.query('last_sent_id', params.last_sent_id, {});
      rb.query('device', params.device, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Get messages for softphone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneSoftPhoneGetMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneSoftPhoneGetMessages(
    params: {
      username: string;
      password: string;
      last_id: string;
      last_sent_id: string;
      device: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicBasicSoftphoneSoftPhoneGetMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneSoftPhoneSendMessage()` */
  static readonly PublicBasicSoftphoneSoftPhoneSendMessagePath = '/api/public-basic-softphone/soft-phone-send-message';

  /**
   * Send message from softphone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneSoftPhoneSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneSoftPhoneSendMessage$Response(
    params: {
      username: string;
      password: string;
      body: AcrobitsSendMessageRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicBasicSoftphoneService.PublicBasicSoftphoneSoftPhoneSendMessagePath, 'post');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('password', params.password, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Send message from softphone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneSoftPhoneSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneSoftPhoneSendMessage(
    params: {
      username: string;
      password: string;
      body: AcrobitsSendMessageRequest
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicBasicSoftphoneSoftPhoneSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneRegisterDevice()` */
  static readonly PublicBasicSoftphoneRegisterDevicePath = '/api/public-basic-softphone/register-device';

  /**
   * Register basic softphone device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneRegisterDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneRegisterDevice$Response(
    params: {
      username: string;
      password: string;
      body: AcrobitsRegisterDeviceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicBasicSoftphoneService.PublicBasicSoftphoneRegisterDevicePath, 'post');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('password', params.password, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Register basic softphone device.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneRegisterDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicBasicSoftphoneRegisterDevice(
    params: {
      username: string;
      password: string;
      body: AcrobitsRegisterDeviceRequest
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicBasicSoftphoneRegisterDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneUnregisterDevice()` */
  static readonly PublicBasicSoftphoneUnregisterDevicePath = '/api/public-basic-softphone/unregister-device';

  /**
   * Unregister basic softphone device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneUnregisterDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneUnregisterDevice$Response(
    params: {
      username: string;
      password: string;

    /**
     * This is the application ID to use along with the pushToken
     */
      pushAppId: string;

    /**
     * This is the identifier needed by Apple and Google push notification services to deliver the push to the specific device and app
     */
      pushToken: string;

    /**
     * This parameter uniquely identifies the account
     */
      selector: string;

    /**
     * Acrobits device name
     */
      userAgent: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicBasicSoftphoneService.PublicBasicSoftphoneUnregisterDevicePath, 'get');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('password', params.password, {});
      rb.query('pushAppId', params.pushAppId, {});
      rb.query('pushToken', params.pushToken, {});
      rb.query('selector', params.selector, {});
      rb.query('userAgent', params.userAgent, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Unregister basic softphone device.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneUnregisterDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneUnregisterDevice(
    params: {
      username: string;
      password: string;

    /**
     * This is the application ID to use along with the pushToken
     */
      pushAppId: string;

    /**
     * This is the identifier needed by Apple and Google push notification services to deliver the push to the specific device and app
     */
      pushToken: string;

    /**
     * This parameter uniquely identifies the account
     */
      selector: string;

    /**
     * Acrobits device name
     */
      userAgent: string;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicBasicSoftphoneUnregisterDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicBasicSoftphoneContacts()` */
  static readonly PublicBasicSoftphoneContactsPath = '/api/public-basic-softphone/contacts';

  /**
   * Returns customer contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicBasicSoftphoneContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneContacts$Response(
    params: {
      username: string;
      password: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<AcrobitsContactsResponse>>> {
    const rb = new RequestBuilder(this.rootUrl, PublicBasicSoftphoneService.PublicBasicSoftphoneContactsPath, 'get');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AcrobitsContactsResponse>>;
      })
    );
  }

  /**
   * Returns customer contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicBasicSoftphoneContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicBasicSoftphoneContacts(
    params: {
      username: string;
      password: string;
    },
    context?: HttpContext
  ): Observable<Array<AcrobitsContactsResponse>> {
    return this.publicBasicSoftphoneContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AcrobitsContactsResponse>>): Array<AcrobitsContactsResponse> => r.body)
    );
  }

}
