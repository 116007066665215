/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CustomFieldGridResponse } from '../models/custom-field-grid-response';
import { CustomFieldListRequest } from '../models/custom-field-list-request';
import { CustomFieldModel } from '../models/custom-field-model';

@Injectable({ providedIn: 'root' })
export class CustomFieldsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `customFieldsGetCustomFieldList()` */
  static readonly CustomFieldsGetCustomFieldListPath = '/api/custom-fields/list';

  /**
   * Get list of custom fields.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsGetCustomFieldList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsGetCustomFieldList$Response(
    params: {
      body: CustomFieldListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomFieldGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CustomFieldsService.CustomFieldsGetCustomFieldListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomFieldGridResponse>;
      })
    );
  }

  /**
   * Get list of custom fields.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsGetCustomFieldList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsGetCustomFieldList(
    params: {
      body: CustomFieldListRequest
    },
    context?: HttpContext
  ): Observable<CustomFieldGridResponse> {
    return this.customFieldsGetCustomFieldList$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomFieldGridResponse>): CustomFieldGridResponse => r.body)
    );
  }

  /** Path part for operation `customFieldsGetCustomFieldById()` */
  static readonly CustomFieldsGetCustomFieldByIdPath = '/api/custom-fields/{id}';

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsGetCustomFieldById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsGetCustomFieldById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CustomFieldModel>> {
    const rb = new RequestBuilder(this.rootUrl, CustomFieldsService.CustomFieldsGetCustomFieldByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomFieldModel>;
      })
    );
  }

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsGetCustomFieldById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsGetCustomFieldById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<CustomFieldModel> {
    return this.customFieldsGetCustomFieldById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CustomFieldModel>): CustomFieldModel => r.body)
    );
  }

  /** Path part for operation `customFieldsDeleteCustomFieldById()` */
  static readonly CustomFieldsDeleteCustomFieldByIdPath = '/api/custom-fields/{id}';

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsDeleteCustomFieldById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsDeleteCustomFieldById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CustomFieldsService.CustomFieldsDeleteCustomFieldByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsDeleteCustomFieldById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldsDeleteCustomFieldById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.customFieldsDeleteCustomFieldById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `customFieldsSaveCustomField()` */
  static readonly CustomFieldsSaveCustomFieldPath = '/api/custom-fields/save';

  /**
   * Save custom field.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldsSaveCustomField()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsSaveCustomField$Response(
    params: {
      body: CustomFieldModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CustomFieldsService.CustomFieldsSaveCustomFieldPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save custom field.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldsSaveCustomField$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldsSaveCustomField(
    params: {
      body: CustomFieldModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.customFieldsSaveCustomField$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
