/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BusinessReferralModel } from '../models/business-referral-model';

@Injectable({ providedIn: 'root' })
export class PublicService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `publicGetCheckRecordings()` */
  static readonly PublicGetCheckRecordingsPath = '/api/public/check-temp';

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicGetCheckRecordings()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetCheckRecordings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicService.PublicGetCheckRecordingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicGetCheckRecordings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetCheckRecordings(
    params?: {
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicGetCheckRecordings$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicGetSimpleStatus()` */
  static readonly PublicGetSimpleStatusPath = '/api/public/status';

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicGetSimpleStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetSimpleStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicService.PublicGetSimpleStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicGetSimpleStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetSimpleStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicGetSimpleStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicGetStatus()` */
  static readonly PublicGetStatusPath = '/api/public/health';

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicGetStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicService.PublicGetStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Returns API status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicGetStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicGetStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicGetStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicSendBusinessReferral()` */
  static readonly PublicSendBusinessReferralPath = '/api/public/business-referral';

  /**
   * Send business referral email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicSendBusinessReferral()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicSendBusinessReferral$Response(
    params: {
      body: BusinessReferralModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PublicService.PublicSendBusinessReferralPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Send business referral email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicSendBusinessReferral$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  publicSendBusinessReferral(
    params: {
      body: BusinessReferralModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.publicSendBusinessReferral$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `publicPbxProvisioning()` */
  static readonly PublicPbxProvisioningPath = '/api/public/prov';

  /**
   * Soft Phone provisioning.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicPbxProvisioning()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicPbxProvisioning$Response(
    params: {
      cloud_username: string;
      cloud_password: string;
      cloud_id: string;
      initialScreen: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicService.PublicPbxProvisioningPath, 'get');
    if (params) {
      rb.query('cloud_username', params.cloud_username, {});
      rb.query('cloud_password', params.cloud_password, {});
      rb.query('cloud_id', params.cloud_id, {});
      rb.query('initialScreen', params.initialScreen, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Soft Phone provisioning.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicPbxProvisioning$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicPbxProvisioning(
    params: {
      cloud_username: string;
      cloud_password: string;
      cloud_id: string;
      initialScreen: number;
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicPbxProvisioning$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `publicTriggerScheduler()` */
  static readonly PublicTriggerSchedulerPath = '/api/public/trigger-reports-scheduler';

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `publicTriggerScheduler()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicTriggerScheduler$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, PublicService.PublicTriggerSchedulerPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `publicTriggerScheduler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  publicTriggerScheduler(
    params?: {
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.publicTriggerScheduler$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
