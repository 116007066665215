import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';
import {NotificationsService as NotificationsApiService} from '../../api/services/notifications.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {NotificationModel} from '../../api/models/notification-model';
import {EventsService} from '../../events/events.service';
import {AuthService} from '../security/auth.service';
import {PhoneNumbersUtils} from '../utilities/phone-numbers-helpers';
import {HeaderService} from './header.service';
import * as _ from 'lodash';

@Injectable()
export class LocalNotificationsService {

  public latestNotifications = new BehaviorSubject<NotificationModel[]>([]);
  public hasNotifications = new BehaviorSubject<boolean>(false);
  public notificationsCount = 0;
  public appIconPath = '/assets/icons/telego-icon.png';
  readonly VALID_PUBLIC_KEY = 'BF7wihSt3jXx4idQjAIm6vZT02HY5mZMGnW0qp_O1kA1S7juzncVjA6iQO7-L0QaB2vCBjbGYbuQdCx1DVGN5ds';
  private eventConnectSubscription: Subscription;
  private notificationSubscription: Subscription;

  constructor(
    private location: Location, private router: Router,
    private notificationsApiService: NotificationsApiService,
    private headerService: HeaderService,
    private eventService: EventsService,
    private authService: AuthService,
    // private swPush: SwPush
  ) {

    console.log('init LocalNotificationsService');

    this.notificationSubscription = this.eventService.notificationEmitter.subscribe(async (data: NotificationModel) => {
      await this.getLatestNotifications();

      this.notifyUser(data);
    });

    this.eventConnectSubscription = this.eventService.connectEmitter.subscribe(async () => {
      await this.getLatestNotifications();
    });

    if (this.eventService.isConnected()) {
      this.getLatestNotifications();
    }

    this.authService.currentUser.subscribe((user) => {
      /*if (user && this.swPush.isEnabled) {
        this.subscribeToNotifications();
      }*/
    });
  }

  public subscribeToNotifications() {

    /*this.swPush.requestSubscription({
      serverPublicKey: this.VALID_PUBLIC_KEY
    })
      .then(sub => this.notificationsApiService.postNotificationsSubscribe(sub as any))
      .catch(err => console.error('Could not subscribe to notifications', err));*/
  }

  public async unsubscribeNotifications(): Promise<boolean> {
    await this.notificationsApiService.notificationUnsubscribe().toPromise();

    return true;
  }

  public async getLatestNotifications(): Promise<void> {
    const list = await this.notificationsApiService.notificationGetLatestNotifications().toPromise();
    this.notificationsCount = list.length;
    if (list.findIndex(x => x.badge > 0) > -1) {
      this.hasNotifications.next(true);
    } else {
      this.hasNotifications.next(false);
    }

    let totalSms = 0;
    let totalProSms = 0;
    let totalFax = 0;
    let totalDownloads = 0;

    list.sort((left, right) => {
      let leftTotal, rightTotal;
      leftTotal = new Date(left.time);
      rightTotal = new Date(right.time);

      return rightTotal - leftTotal;
    });

    list.filter(x => x.eventType === 'sms' && x.badge > 0)
      .forEach(x => totalSms = totalSms + x.badge);
    const userApps = this.headerService.getUserApps();
    const smsApp = userApps.find(x => x.key === 'sms');
    if (smsApp) {
      smsApp.notificationBadge = totalSms;
    }

    list.filter(x => x.eventType === 'pro-sms' && x.badge > 0)
      .forEach(x => totalProSms = totalProSms + x.badge);
    const proSmsApp = userApps.find(x => x.key === 'prosms');
    if (proSmsApp) {
      proSmsApp.notificationBadge = totalProSms;
    }

    list.filter(x => x.eventType === 'fax-in' && x.badge > 0)
      .forEach(x => totalFax = totalFax + x.badge);
    const faxApp = userApps.find(x => x.key === 'fax');
    if (faxApp) {
      faxApp.notificationBadge = totalFax;
    }

    list.filter(x => x.eventType === 'export' && x.badge > 0)
      .forEach(x => totalDownloads = totalDownloads + x.badge);
    const downloadApp = userApps.find(x => x.key === 'downloads');
    if (downloadApp) {
      downloadApp.notificationBadge = totalDownloads;
    }

    this.latestNotifications.next(list);
  }

  public async markMessageAsUnread(badge: number, nKey?: string): Promise<void> {

    const request = {
      nKey: nKey,
      badge: badge
    };

    const response = await this.notificationsApiService.notificationMarkAsUnread({body: request}).toPromise();

    if (window['AppChannel']) {
      console.log('AppChannel postMessage');
      window['AppChannel'].postMessage('RefreshBadges');
    }

    await this.getLatestNotifications();
  }

  public async markUnreadNotifications(badge: number, nKey?: string): Promise<void> {

    const request = {
      nKey: nKey,
      badge: badge
    };

    const response = await this.notificationsApiService.notificationMarkAsRead({body: request}).toPromise();

    if (window['AppChannel']) {
      console.log('AppChannel postMessage');
      window['AppChannel'].postMessage('RefreshBadges');
    }

    await this.getLatestNotifications();
  }

  public async markAllUnreadNotifications(): Promise<void> {
    const response = await this.notificationsApiService.notificationMarkAllAsRead().toPromise();

    if (window['AppChannel']) {
      console.log('AppChannel postMessage');
      window['AppChannel'].postMessage('RefreshBadges');
    }

    await this.getLatestNotifications();
  }

  public notifyUser(data: NotificationModel) {
    if (!data.messageText) {
      console.log('Got empty notification, skip it');
      return;
    }

    console.log('Notify user');
    // todo enable push services
    /*if (this.swPush.isEnabled && environment.production) {
      return;
    }*/

    if (!('Notification' in window)) {
      console.log('This browser does not support system notifications');
    } else if (Notification['permission'] === 'granted') {
      // If it's okay let's create a notification
      this.composeNotification(data);
    } else if (Notification['permission'] !== 'denied') {
      Notification.requestPermission((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          this.composeNotification(data);
        }
      });
    }
  }

  public composeNotification(data: NotificationModel, autoClose: boolean = true) {
    let notification, title, config: NotificationOptions;
    const currentTime = new Date();
    console.log(`Notification key is ${data.nKey}`);

    if (data.eventType === 'sms' || data.eventType === 'pro-sms') {
      title = 'New Incoming Message';
      config = {
        body: `${PhoneNumbersUtils.format(data.tnTo)}\n${data.badge} New SMS from ${PhoneNumbersUtils.format(data.tnFrom)}`,
        tag: data.nKey + _.uniqueId(),
        icon: this.appIconPath,
        timestamp: currentTime.getTime()
      };
    } else if (data.eventType === 'fax') {
      title = 'New Incoming Fax';
      config = {
        body: `${PhoneNumbersUtils.format(data.tnTo)}\n${data.badge} New Fax from ${PhoneNumbersUtils.format(data.tnFrom)}`,
        tag: data.nKey + _.uniqueId(),
        icon: this.appIconPath,
        timestamp: currentTime.getTime()
      };
    } else if (data.eventType === 'fax-out') {
      title = data.messageText;
      const isFailed = data.messageText.toLowerCase().indexOf('failed') > -1
      config = {
        body: isFailed
            ? `${PhoneNumbersUtils.format(data.tnFrom)}\nFailed to send fax to ${PhoneNumbersUtils.format(data.tnTo)}`
          : `${PhoneNumbersUtils.format(data.tnFrom)}\n${data.badge} Fax sent to ${PhoneNumbersUtils.format(data.tnTo)}`,
        tag: data.nKey + _.uniqueId(),
        icon: this.appIconPath,
        timestamp: currentTime.getTime()
      };
    } else if (data.eventType === 'broadcast') {
      title = 'Broadcast Sent Successfully';
      config = {
        body: `${data.messageText}`,
        tag: data.nKey + _.uniqueId(),
        icon: this.appIconPath,
        timestamp: currentTime.getTime()
      };
    } else if (data.eventType === 'export') {
      title = 'Report is ready for download';
      config = {
        body: `${data.messageText}`,
        tag: data.nKey + _.uniqueId(),
        icon: this.appIconPath,
        timestamp: currentTime.getTime()
      };
    } else {
      return;

      /*title = 'New Notification';
      config = {
        body: `${data.messageText}`,
        tag: data.nKey + _.uniqueId(),
        icon: this.appIconPath,
        timestamp: currentTime.getTime()
      };*/
    }

    notification = new Notification(title, config);

    notification.onclick = (event) => {
      event.preventDefault(); // prevent the browser from focusing the Notification's tab
      if (data.url) {

        let finalUrl = `/#${this.headerService.themePrefix}${data.url}`;

        if (data.eventType === 'export') {
          finalUrl = data.url;
        }

        console.log(finalUrl);
        window.open(finalUrl, '_blank');
        notification.close();
      }
    };

    // setTimeout(notification.close.bind(notification), 10000);
  }
}
