import {Component, OnInit} from '@angular/core';
import {ActivationStart, NavigationEnd, Router} from '@angular/router';
import {AuthService} from './core/security/auth.service';
import {GoogleAnalyticsService} from './analytics/google-analytics.service';
import {MatIconRegistry} from '@angular/material/icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    public matIconRegistry: MatIconRegistry,
    private router: Router,
    private authService: AuthService,
    private gaService: GoogleAnalyticsService,
  ) {
    //this.matIconRegistry.registerFontClassAlias('fas');
    this.router.events.subscribe(event => {
      if (event.constructor.name === 'ActivationStart') {
        const e = event as ActivationStart;

        if (e.snapshot.data) {
          this.authService.currentMenuGroup.next(e.snapshot.data['menuGroup']);
        }
      } else if (event instanceof NavigationEnd) {
        gaService.navigateTo(event);
      }
    });
  }

  ngOnInit() {
  }
}
