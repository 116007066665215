/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccountUser } from '../models/account-user';
import { AccountUsersGridResponse } from '../models/account-users-grid-response';
import { LookupGridResponse } from '../models/lookup-grid-response';
import { UsersGridRequest } from '../models/users-grid-request';

@Injectable({ providedIn: 'root' })
export class UsersManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountUsersGetRoles()` */
  static readonly AccountUsersGetRolesPath = '/api/account/roles';

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersManagementService.AccountUsersGetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles(
    params?: {
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.accountUsersGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUsers()` */
  static readonly AccountUsersGetUsersPath = '/api/account/users';

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers$Response(
    params: {
      body: UsersGridRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountUsersGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersManagementService.AccountUsersGetUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountUsersGridResponse>;
      })
    );
  }

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers(
    params: {
      body: UsersGridRequest
    },
    context?: HttpContext
  ): Observable<AccountUsersGridResponse> {
    return this.accountUsersGetUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUsersGridResponse>): AccountUsersGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUserById()` */
  static readonly AccountUsersGetUserByIdPath = '/api/account/user/{id}';

  /**
   * Get account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountUser>> {
    const rb = new RequestBuilder(this.rootUrl, UsersManagementService.AccountUsersGetUserByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountUser>;
      })
    );
  }

  /**
   * Get account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AccountUser> {
    return this.accountUsersGetUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUser>): AccountUser => r.body)
    );
  }

  /** Path part for operation `accountUsersDeleteUserById()` */
  static readonly AccountUsersDeleteUserByIdPath = '/api/account/user/{id}';

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersDeleteUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersManagementService.AccountUsersDeleteUserByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersDeleteUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountUsersDeleteUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersSaveUser()` */
  static readonly AccountUsersSaveUserPath = '/api/account/user';

  /**
   * Save user details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersSaveUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser$Response(
    params: {
      body: AccountUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersManagementService.AccountUsersSaveUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save user details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersSaveUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser(
    params: {
      body: AccountUser
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountUsersSaveUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersResendInvitation()` */
  static readonly AccountUsersResendInvitationPath = '/api/account/resend-invitation/{email}';

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersResendInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation$Response(
    params: {
      email: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, UsersManagementService.AccountUsersResendInvitationPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersResendInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation(
    params: {
      email: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.accountUsersResendInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `accountUsersGetAccountDomains()` */
  static readonly AccountUsersGetAccountDomainsPath = '/api/account/domains';

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetAccountDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, UsersManagementService.AccountUsersGetAccountDomainsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetAccountDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.accountUsersGetAccountDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
