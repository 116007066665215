/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ContactModel } from '../models/contact-model';
import { ContactsLookupRequest } from '../models/contacts-lookup-request';
import { ContactsRequest } from '../models/contacts-request';
import { ContactsResponse } from '../models/contacts-response';
import { CustomFieldDefinitionsRequest } from '../models/custom-field-definitions-request';
import { CustomFieldModel } from '../models/custom-field-model';
import { DeleteContactResponse } from '../models/delete-contact-response';
import { DuplicateContactGridResponse } from '../models/duplicate-contact-grid-response';
import { DuplicateContactsRequest } from '../models/duplicate-contacts-request';
import { DuplicateContactsSummaryResponse } from '../models/duplicate-contacts-summary-response';
import { LookupContactGridResponse } from '../models/lookup-contact-grid-response';
import { SaveContactsRequest } from '../models/save-contacts-request';

@Injectable({ providedIn: 'root' })
export class ContactsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactGetUserLookupContacts()` */
  static readonly ContactGetUserLookupContactsPath = '/api/contact/lookup';

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserLookupContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts$Response(
    params: {
      body: ContactsLookupRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupContactGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactGetUserLookupContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupContactGridResponse>;
      })
    );
  }

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserLookupContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts(
    params: {
      body: ContactsLookupRequest
    },
    context?: HttpContext
  ): Observable<LookupContactGridResponse> {
    return this.contactGetUserLookupContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupContactGridResponse>): LookupContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetUserContacts()` */
  static readonly ContactGetUserContactsPath = '/api/contact/list';

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts$Response(
    params: {
      body: ContactsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactGetUserContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactsResponse>;
      })
    );
  }

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts(
    params: {
      body: ContactsRequest
    },
    context?: HttpContext
  ): Observable<ContactsResponse> {
    return this.contactGetUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactsResponse>): ContactsResponse => r.body)
    );
  }

  /** Path part for operation `contactGetContactById()` */
  static readonly ContactGetContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactModel>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactGetContactByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactModel>;
      })
    );
  }

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<ContactModel> {
    return this.contactGetContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactDeleteContactById()` */
  static readonly ContactDeleteContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactDeleteContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeleteContactResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactDeleteContactByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeleteContactResponse>;
      })
    );
  }

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactDeleteContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<DeleteContactResponse> {
    return this.contactDeleteContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteContactResponse>): DeleteContactResponse => r.body)
    );
  }

  /** Path part for operation `contactSaveContact()` */
  static readonly ContactSaveContactPath = '/api/contact/save';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact$Response(
    params: {
      body: ContactModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactModel>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactSaveContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactModel>;
      })
    );
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact(
    params: {
      body: ContactModel
    },
    context?: HttpContext
  ): Observable<ContactModel> {
    return this.contactSaveContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactSaveContacts()` */
  static readonly ContactSaveContactsPath = '/api/contact/save-contacts';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts$Response(
    params: {
      body: SaveContactsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactSaveContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts(
    params: {
      body: SaveContactsRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.contactSaveContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContactsSummary()` */
  static readonly ContactGetDuplicateUserContactsSummaryPath = '/api/contact/duplicate-summary';

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContactsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DuplicateContactsSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactGetDuplicateUserContactsSummaryPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateContactsSummaryResponse>;
      })
    );
  }

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContactsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary(
    params?: {
    },
    context?: HttpContext
  ): Observable<DuplicateContactsSummaryResponse> {
    return this.contactGetDuplicateUserContactsSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactsSummaryResponse>): DuplicateContactsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContacts()` */
  static readonly ContactGetDuplicateUserContactsPath = '/api/contact/duplicate-list';

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts$Response(
    params: {
      body: DuplicateContactsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DuplicateContactGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactGetDuplicateUserContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateContactGridResponse>;
      })
    );
  }

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts(
    params: {
      body: DuplicateContactsRequest
    },
    context?: HttpContext
  ): Observable<DuplicateContactGridResponse> {
    return this.contactGetDuplicateUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactGridResponse>): DuplicateContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetCustomFieldsDefinitions()` */
  static readonly ContactGetCustomFieldsDefinitionsPath = '/api/contact/custom-fields';

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetCustomFieldsDefinitions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions$Response(
    params: {
      body: CustomFieldDefinitionsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CustomFieldModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ContactsService.ContactGetCustomFieldsDefinitionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomFieldModel>>;
      })
    );
  }

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetCustomFieldsDefinitions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions(
    params: {
      body: CustomFieldDefinitionsRequest
    },
    context?: HttpContext
  ): Observable<Array<CustomFieldModel>> {
    return this.contactGetCustomFieldsDefinitions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomFieldModel>>): Array<CustomFieldModel> => r.body)
    );
  }

}
