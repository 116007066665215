/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { FirewallGridResponse } from '../models/firewall-grid-response';
import { FirewallListRequest } from '../models/firewall-list-request';
import { FirewallModel } from '../models/firewall-model';

@Injectable({ providedIn: 'root' })
export class FirewallService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `firewallGetWhiteList()` */
  static readonly FirewallGetWhiteListPath = '/api/firewall/list';

  /**
   * Get all white-list IP.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetWhiteList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallGetWhiteList$Response(
    params: {
      body: FirewallListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FirewallGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, FirewallService.FirewallGetWhiteListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FirewallGridResponse>;
      })
    );
  }

  /**
   * Get all white-list IP.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetWhiteList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallGetWhiteList(
    params: {
      body: FirewallListRequest
    },
    context?: HttpContext
  ): Observable<FirewallGridResponse> {
    return this.firewallGetWhiteList$Response(params, context).pipe(
      map((r: StrictHttpResponse<FirewallGridResponse>): FirewallGridResponse => r.body)
    );
  }

  /** Path part for operation `firewallGetIpAddressById()` */
  static readonly FirewallGetIpAddressByIdPath = '/api/firewall/ip-address/{id}';

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetIpAddressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetIpAddressById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FirewallModel>> {
    const rb = new RequestBuilder(this.rootUrl, FirewallService.FirewallGetIpAddressByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FirewallModel>;
      })
    );
  }

  /**
   * Get account ip address details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetIpAddressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetIpAddressById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<FirewallModel> {
    return this.firewallGetIpAddressById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FirewallModel>): FirewallModel => r.body)
    );
  }

  /** Path part for operation `firewallDeleteIpAddressById()` */
  static readonly FirewallDeleteIpAddressByIdPath = '/api/firewall/ip-address/{id}';

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallDeleteIpAddressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallDeleteIpAddressById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FirewallService.FirewallDeleteIpAddressByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete account ip address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallDeleteIpAddressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallDeleteIpAddressById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.firewallDeleteIpAddressById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `firewallGetFirewallLimits()` */
  static readonly FirewallGetFirewallLimitsPath = '/api/firewall/firewall-limits';

  /**
   * Get account firewall limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetFirewallLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetFirewallLimits$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, FirewallService.FirewallGetFirewallLimitsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * Get account firewall limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetFirewallLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetFirewallLimits(
    params?: {
    },
    context?: HttpContext
  ): Observable<number> {
    return this.firewallGetFirewallLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `firewallSaveIpAddress()` */
  static readonly FirewallSaveIpAddressPath = '/api/firewall/save-ip-address';

  /**
   * Save account ip address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallSaveIpAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallSaveIpAddress$Response(
    params: {
      body: FirewallModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FirewallService.FirewallSaveIpAddressPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save account ip address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallSaveIpAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallSaveIpAddress(
    params: {
      body: FirewallModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.firewallSaveIpAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `firewallGetClientIpAddress()` */
  static readonly FirewallGetClientIpAddressPath = '/api/firewall/my-ip-address';

  /**
   * Get client IP address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallGetClientIpAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetClientIpAddress$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, FirewallService.FirewallGetClientIpAddressPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get client IP address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallGetClientIpAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  firewallGetClientIpAddress(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.firewallGetClientIpAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `firewallRequestExtendLimits()` */
  static readonly FirewallRequestExtendLimitsPath = '/api/firewall/request-extend-limits';

  /**
   * Request support to extend firewall limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `firewallRequestExtendLimits()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallRequestExtendLimits$Response(
    params: {
      body: FirewallModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FirewallService.FirewallRequestExtendLimitsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Request support to extend firewall limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `firewallRequestExtendLimits$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  firewallRequestExtendLimits(
    params: {
      body: FirewallModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.firewallRequestExtendLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
