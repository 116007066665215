/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class RecordingsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `recordingsV2StreamRecording()` */
  static readonly RecordingsV2StreamRecordingPath = '/api/recordings-v2/recording/stream/{recordingId}/{callId}';

  /**
   * Stream Recording.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recordingsV2StreamRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2StreamRecording$Response(
    params: {
      recordingId: string;
      callId: string;
      domain: string;
      cdrDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecordingsV2Service.RecordingsV2StreamRecordingPath, 'get');
    if (params) {
      rb.path('recordingId', params.recordingId, {});
      rb.path('callId', params.callId, {});
      rb.query('domain', params.domain, {});
      rb.query('cdrDate', params.cdrDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Stream Recording.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recordingsV2StreamRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2StreamRecording(
    params: {
      recordingId: string;
      callId: string;
      domain: string;
      cdrDate: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.recordingsV2StreamRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `recordingsV2DownloadRecording()` */
  static readonly RecordingsV2DownloadRecordingPath = '/api/recordings-v2/recording/download/{recordingId}/{callId}';

  /**
   * Download Recording.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recordingsV2DownloadRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DownloadRecording$Response(
    params: {
      recordingId: string;
      callId: string;
      fileName: string;
      domain: string;
      cdrDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecordingsV2Service.RecordingsV2DownloadRecordingPath, 'get');
    if (params) {
      rb.path('recordingId', params.recordingId, {});
      rb.path('callId', params.callId, {});
      rb.query('fileName', params.fileName, {});
      rb.query('domain', params.domain, {});
      rb.query('cdrDate', params.cdrDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download Recording.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recordingsV2DownloadRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DownloadRecording(
    params: {
      recordingId: string;
      callId: string;
      fileName: string;
      domain: string;
      cdrDate: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.recordingsV2DownloadRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `recordingsV2DeleteRecording()` */
  static readonly RecordingsV2DeleteRecordingPath = '/api/recordings-v2/recording/delete/{recordingId}/{callId}';

  /**
   * Delete Recording.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recordingsV2DeleteRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DeleteRecording$Response(
    params: {
      recordingId: string;
      callId: string;
      domain: string;
      cdrDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RecordingsV2Service.RecordingsV2DeleteRecordingPath, 'delete');
    if (params) {
      rb.path('recordingId', params.recordingId, {});
      rb.path('callId', params.callId, {});
      rb.query('domain', params.domain, {});
      rb.query('cdrDate', params.cdrDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Recording.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recordingsV2DeleteRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recordingsV2DeleteRecording(
    params: {
      recordingId: string;
      callId: string;
      domain: string;
      cdrDate: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.recordingsV2DeleteRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
