/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BillingInfoResponse } from '../models/billing-info-response';
import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { ExportMessageLogsRequest } from '../models/export-message-logs-request';
import { FileUploadModel } from '../models/file-upload-model';
import { IsRecipientInBlackListResponse } from '../models/is-recipient-in-black-list-response';
import { MediaModel } from '../models/media-model';
import { MessageLogsRequest } from '../models/message-logs-request';
import { MessageLogsResponse } from '../models/message-logs-response';
import { MessageModel } from '../models/message-model';
import { MessagingGroupsGridResponse } from '../models/messaging-groups-grid-response';
import { MessagingGroupsRequest } from '../models/messaging-groups-request';
import { ProMessagesRequest } from '../models/pro-messages-request';
import { ProMessagesResponse } from '../models/pro-messages-response';
import { RemoveRecipientFromBlackListResponse } from '../models/remove-recipient-from-black-list-response';
import { ReportRequest } from '../models/report-request';
import { SendProMessageModel } from '../models/send-pro-message-model';
import { SmsPhoneNumberModel } from '../models/sms-phone-number-model';
import { SummaryByDayReportModel } from '../models/summary-by-day-report-model';
import { SummaryKeywordsReportModel } from '../models/summary-keywords-report-model';
import { SummaryReportModel } from '../models/summary-report-model';
import { TopNumbersReportModel } from '../models/top-numbers-report-model';

@Injectable({ providedIn: 'root' })
export class AdvancedMessagingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `proMessagingSendMessage()` */
  static readonly ProMessagingSendMessagePath = '/api/advanced-messaging/send-message';

  /**
   * Send Message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingSendMessage$Response(
    params: {
      body: SendProMessageModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessageModel>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingSendMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageModel>;
      })
    );
  }

  /**
   * Send Message.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingSendMessage(
    params: {
      body: SendProMessageModel
    },
    context?: HttpContext
  ): Observable<MessageModel> {
    return this.proMessagingSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `proMessagingUploadMedia()` */
  static readonly ProMessagingUploadMediaPath = '/api/advanced-messaging/upload-media';

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingUploadMedia()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  proMessagingUploadMedia$Response(
    params: {
  
    /**
     * MMS Attachment
     */
    body: FileUploadModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MediaModel>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingUploadMediaPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaModel>;
      })
    );
  }

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingUploadMedia$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  proMessagingUploadMedia(
    params: {
  
    /**
     * MMS Attachment
     */
    body: FileUploadModel
    },
    context?: HttpContext
  ): Observable<MediaModel> {
    return this.proMessagingUploadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaModel>): MediaModel => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessagingLimits()` */
  static readonly ProMessagingGetMessagingLimitsPath = '/api/advanced-messaging/messaging-limits';

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessagingLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMessagingLimits$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BillingInfoResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingGetMessagingLimitsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BillingInfoResponse>;
      })
    );
  }

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessagingLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMessagingLimits(
    params?: {
    },
    context?: HttpContext
  ): Observable<BillingInfoResponse> {
    return this.proMessagingGetMessagingLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingInfoResponse>): BillingInfoResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMyMessagingPhoneNumbers()` */
  static readonly ProMessagingGetMyMessagingPhoneNumbersPath = '/api/advanced-messaging/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMyMessagingPhoneNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingGetMyMessagingPhoneNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingGetMyMessagingPhoneNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SmsPhoneNumberModel>> {
    return this.proMessagingGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessagingGroups()` */
  static readonly ProMessagingGetMessagingGroupsPath = '/api/advanced-messaging/messaging-groups/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessagingGroups()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroups$Response(
    params: {
      phoneNumber: string;
      body: MessagingGroupsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessagingGroupsGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingGetMessagingGroupsPath, 'post');
    if (params) {
      rb.path('phoneNumber', params.phoneNumber, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagingGroupsGridResponse>;
      })
    );
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessagingGroups$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroups(
    params: {
      phoneNumber: string;
      body: MessagingGroupsRequest
    },
    context?: HttpContext
  ): Observable<MessagingGroupsGridResponse> {
    return this.proMessagingGetMessagingGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingGroupsGridResponse>): MessagingGroupsGridResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessagingGroupsV2()` */
  static readonly ProMessagingGetMessagingGroupsV2Path = '/api/advanced-messaging/messaging-groups-v2/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessagingGroupsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroupsV2$Response(
    params: {
      phoneNumber: string;
      body: MessagingGroupsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessagingGroupsGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingGetMessagingGroupsV2Path, 'post');
    if (params) {
      rb.path('phoneNumber', params.phoneNumber, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagingGroupsGridResponse>;
      })
    );
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessagingGroupsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessagingGroupsV2(
    params: {
      phoneNumber: string;
      body: MessagingGroupsRequest
    },
    context?: HttpContext
  ): Observable<MessagingGroupsGridResponse> {
    return this.proMessagingGetMessagingGroupsV2$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingGroupsGridResponse>): MessagingGroupsGridResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingGetMessages()` */
  static readonly ProMessagingGetMessagesPath = '/api/advanced-messaging/get-messages';

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingGetMessages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessages$Response(
    params: {
      body: ProMessagesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProMessagesResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingGetMessagesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProMessagesResponse>;
      })
    );
  }

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingGetMessages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingGetMessages(
    params: {
      body: ProMessagesRequest
    },
    context?: HttpContext
  ): Observable<ProMessagesResponse> {
    return this.proMessagingGetMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProMessagesResponse>): ProMessagesResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingDeleteMessageBody()` */
  static readonly ProMessagingDeleteMessageBodyPath = '/api/advanced-messaging/message/{messageId}';

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDeleteMessageBody()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteMessageBody$Response(
    params: {
      messageId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessageModel>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingDeleteMessageBodyPath, 'delete');
    if (params) {
      rb.path('messageId', params.messageId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageModel>;
      })
    );
  }

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDeleteMessageBody$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteMessageBody(
    params: {
      messageId: string;
    },
    context?: HttpContext
  ): Observable<MessageModel> {
    return this.proMessagingDeleteMessageBody$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `proMessagingPinConversationByGroup()` */
  static readonly ProMessagingPinConversationByGroupPath = '/api/advanced-messaging/pin-conversation/{groupKey}';

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingPinConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingPinConversationByGroup$Response(
    params: {
      groupKey: string;
      pin: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingPinConversationByGroupPath, 'get');
    if (params) {
      rb.path('groupKey', params.groupKey, {});
      rb.query('pin', params.pin, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingPinConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingPinConversationByGroup(
    params: {
      groupKey: string;
      pin: boolean;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.proMessagingPinConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `proMessagingDeleteFullConversationByGroup()` */
  static readonly ProMessagingDeleteFullConversationByGroupPath = '/api/advanced-messaging/conversation-by-group/{groupKey}';

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDeleteFullConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByGroup$Response(
    params: {
      groupKey: string;
      block: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingDeleteFullConversationByGroupPath, 'delete');
    if (params) {
      rb.path('groupKey', params.groupKey, {});
      rb.query('block', params.block, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDeleteFullConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByGroup(
    params: {
      groupKey: string;
      block: boolean;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.proMessagingDeleteFullConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `proMessagingDeleteFullConversationByBroadcastId()` */
  static readonly ProMessagingDeleteFullConversationByBroadcastIdPath = '/api/advanced-messaging/conversation-by-broadcast/{broadcastId}';

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDeleteFullConversationByBroadcastId()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByBroadcastId$Response(
    params: {
      broadcastId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingDeleteFullConversationByBroadcastIdPath, 'delete');
    if (params) {
      rb.path('broadcastId', params.broadcastId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDeleteFullConversationByBroadcastId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDeleteFullConversationByBroadcastId(
    params: {
      broadcastId: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.proMessagingDeleteFullConversationByBroadcastId$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `proMessagingIsInBlackList()` */
  static readonly ProMessagingIsInBlackListPath = '/api/advanced-messaging/is-in-black-list';

  /**
   * Check if recipient is in black list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingIsInBlackList()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingIsInBlackList$Response(
    params: {
      senderNumber: string;
      recipientNumber: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<IsRecipientInBlackListResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingIsInBlackListPath, 'get');
    if (params) {
      rb.query('senderNumber', params.senderNumber, {});
      rb.query('recipientNumber', params.recipientNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IsRecipientInBlackListResponse>;
      })
    );
  }

  /**
   * Check if recipient is in black list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingIsInBlackList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingIsInBlackList(
    params: {
      senderNumber: string;
      recipientNumber: string;
    },
    context?: HttpContext
  ): Observable<IsRecipientInBlackListResponse> {
    return this.proMessagingIsInBlackList$Response(params, context).pipe(
      map((r: StrictHttpResponse<IsRecipientInBlackListResponse>): IsRecipientInBlackListResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingRemoveFromBlackList()` */
  static readonly ProMessagingRemoveFromBlackListPath = '/api/advanced-messaging/remove-from-black-list';

  /**
   * Remove recipient from black list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingRemoveFromBlackList()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingRemoveFromBlackList$Response(
    params: {
      senderNumber: string;
      recipientNumber: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RemoveRecipientFromBlackListResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingRemoveFromBlackListPath, 'get');
    if (params) {
      rb.query('senderNumber', params.senderNumber, {});
      rb.query('recipientNumber', params.recipientNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RemoveRecipientFromBlackListResponse>;
      })
    );
  }

  /**
   * Remove recipient from black list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingRemoveFromBlackList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingRemoveFromBlackList(
    params: {
      senderNumber: string;
      recipientNumber: string;
    },
    context?: HttpContext
  ): Observable<RemoveRecipientFromBlackListResponse> {
    return this.proMessagingRemoveFromBlackList$Response(params, context).pipe(
      map((r: StrictHttpResponse<RemoveRecipientFromBlackListResponse>): RemoveRecipientFromBlackListResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingLookupContactsAndTags()` */
  static readonly ProMessagingLookupContactsAndTagsPath = '/api/advanced-messaging/lookup-contacts';

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingLookupContactsAndTags$Response(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingLookupContactsAndTagsPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactTagLookupModel>>;
      })
    );
  }

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingLookupContactsAndTags(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<Array<ContactTagLookupModel>> {
    return this.proMessagingLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingStreamMedia()` */
  static readonly ProMessagingStreamMediaPath = '/api/advanced-messaging/stream-media/*';

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingStreamMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingStreamMedia$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingStreamMediaPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingStreamMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingStreamMedia(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.proMessagingStreamMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `proMessagingDownloadMedia()` */
  static readonly ProMessagingDownloadMediaPath = '/api/advanced-messaging/download-media/*';

  /**
   * Download Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingDownloadMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDownloadMedia$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingDownloadMediaPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingDownloadMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingDownloadMedia(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.proMessagingDownloadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsSummaryReport()` */
  static readonly ProMessagingReportsSummaryReportPath = '/api/advanced-messaging/reports/summary';

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReport$Response(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SummaryReportModel>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingReportsSummaryReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryReportModel>;
      })
    );
  }

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReport(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<SummaryReportModel> {
    return this.proMessagingReportsSummaryReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<SummaryReportModel>): SummaryReportModel => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsSummaryKeywordsReport()` */
  static readonly ProMessagingReportsSummaryKeywordsReportPath = '/api/advanced-messaging/reports/keywords-summary';

  /**
   * Keywords Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsSummaryKeywordsReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryKeywordsReport$Response(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SummaryKeywordsReportModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingReportsSummaryKeywordsReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SummaryKeywordsReportModel>>;
      })
    );
  }

  /**
   * Keywords Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsSummaryKeywordsReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryKeywordsReport(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<Array<SummaryKeywordsReportModel>> {
    return this.proMessagingReportsSummaryKeywordsReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SummaryKeywordsReportModel>>): Array<SummaryKeywordsReportModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsSummaryReportByDay()` */
  static readonly ProMessagingReportsSummaryReportByDayPath = '/api/advanced-messaging/reports/summary-by-day';

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsSummaryReportByDay()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReportByDay$Response(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SummaryByDayReportModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingReportsSummaryReportByDayPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SummaryByDayReportModel>>;
      })
    );
  }

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsSummaryReportByDay$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsSummaryReportByDay(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<Array<SummaryByDayReportModel>> {
    return this.proMessagingReportsSummaryReportByDay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SummaryByDayReportModel>>): Array<SummaryByDayReportModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsGetTopNumbersReport()` */
  static readonly ProMessagingReportsGetTopNumbersReportPath = '/api/advanced-messaging/reports/top-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsGetTopNumbersReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetTopNumbersReport$Response(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TopNumbersReportModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingReportsGetTopNumbersReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TopNumbersReportModel>>;
      })
    );
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsGetTopNumbersReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetTopNumbersReport(
    params: {
      body: ReportRequest
    },
    context?: HttpContext
  ): Observable<Array<TopNumbersReportModel>> {
    return this.proMessagingReportsGetTopNumbersReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TopNumbersReportModel>>): Array<TopNumbersReportModel> => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsGetLogs()` */
  static readonly ProMessagingReportsGetLogsPath = '/api/advanced-messaging/reports/logs';

  /**
   * Get messages logs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsGetLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetLogs$Response(
    params: {
      body: MessageLogsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessageLogsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingReportsGetLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageLogsResponse>;
      })
    );
  }

  /**
   * Get messages logs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsGetLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsGetLogs(
    params: {
      body: MessageLogsRequest
    },
    context?: HttpContext
  ): Observable<MessageLogsResponse> {
    return this.proMessagingReportsGetLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageLogsResponse>): MessageLogsResponse => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsExportLogs()` */
  static readonly ProMessagingReportsExportLogsPath = '/api/advanced-messaging/reports/logs/export';

  /**
   * Initiate messages logs export.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsExportLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsExportLogs$Response(
    params: {
      body: ExportMessageLogsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingReportsExportLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Initiate messages logs export.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsExportLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  proMessagingReportsExportLogs(
    params: {
      body: ExportMessageLogsRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.proMessagingReportsExportLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `proMessagingReportsGetMyMessagingPhoneNumbers()` */
  static readonly ProMessagingReportsGetMyMessagingPhoneNumbersPath = '/api/advanced-messaging/reports/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `proMessagingReportsGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingReportsGetMyMessagingPhoneNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AdvancedMessagingService.ProMessagingReportsGetMyMessagingPhoneNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `proMessagingReportsGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  proMessagingReportsGetMyMessagingPhoneNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SmsPhoneNumberModel>> {
    return this.proMessagingReportsGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

}
