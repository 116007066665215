/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { WhatsAppMessagingTemplateModel } from '../models/whats-app-messaging-template-model';
import { WhatsAppTemplateListRequest } from '../models/whats-app-template-list-request';
import { WhatsAppTemplateListResponse } from '../models/whats-app-template-list-response';

@Injectable({ providedIn: 'root' })
export class MessagingWhatsAppTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingWhatsAppTemplateGetList()` */
  static readonly MessagingWhatsAppTemplateGetListPath = '/api/messaging-whats-app-template/list';

  /**
   * List WhatsApp message templates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateGetList$Response(
    params: {
      body: WhatsAppTemplateListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WhatsAppTemplateListResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingWhatsAppTemplateService.MessagingWhatsAppTemplateGetListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsAppTemplateListResponse>;
      })
    );
  }

  /**
   * List WhatsApp message templates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateGetList(
    params: {
      body: WhatsAppTemplateListRequest
    },
    context?: HttpContext
  ): Observable<WhatsAppTemplateListResponse> {
    return this.messagingWhatsAppTemplateGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhatsAppTemplateListResponse>): WhatsAppTemplateListResponse => r.body)
    );
  }

  /** Path part for operation `messagingWhatsAppTemplateGetTemplateById()` */
  static readonly MessagingWhatsAppTemplateGetTemplateByIdPath = '/api/messaging-whats-app-template/item/{id}';

  /**
   * Get template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateGetTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateGetTemplateById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WhatsAppMessagingTemplateModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingWhatsAppTemplateService.MessagingWhatsAppTemplateGetTemplateByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsAppMessagingTemplateModel>;
      })
    );
  }

  /**
   * Get template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateGetTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateGetTemplateById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<WhatsAppMessagingTemplateModel> {
    return this.messagingWhatsAppTemplateGetTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhatsAppMessagingTemplateModel>): WhatsAppMessagingTemplateModel => r.body)
    );
  }

  /** Path part for operation `messagingWhatsAppTemplateDeleteTemplateById()` */
  static readonly MessagingWhatsAppTemplateDeleteTemplateByIdPath = '/api/messaging-whats-app-template/item/{id}';

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateDeleteTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateDeleteTemplateById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingWhatsAppTemplateService.MessagingWhatsAppTemplateDeleteTemplateByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateDeleteTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingWhatsAppTemplateDeleteTemplateById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.messagingWhatsAppTemplateDeleteTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingWhatsAppTemplateSaveTemplate()` */
  static readonly MessagingWhatsAppTemplateSaveTemplatePath = '/api/messaging-whats-app-template/item';

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingWhatsAppTemplateSaveTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateSaveTemplate$Response(
    params: {
      body: WhatsAppMessagingTemplateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WhatsAppMessagingTemplateModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingWhatsAppTemplateService.MessagingWhatsAppTemplateSaveTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsAppMessagingTemplateModel>;
      })
    );
  }

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingWhatsAppTemplateSaveTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingWhatsAppTemplateSaveTemplate(
    params: {
      body: WhatsAppMessagingTemplateModel
    },
    context?: HttpContext
  ): Observable<WhatsAppMessagingTemplateModel> {
    return this.messagingWhatsAppTemplateSaveTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<WhatsAppMessagingTemplateModel>): WhatsAppMessagingTemplateModel => r.body)
    );
  }

}
