/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ActivityDashboardGrandTotalsResponse } from '../models/activity-dashboard-grand-totals-response';
import { ActivityDashboardRequest } from '../models/activity-dashboard-request';
import { ActivityDashboardResponse } from '../models/activity-dashboard-response';
import { ActivityDashboardTimeSeriesTotalsResponse } from '../models/activity-dashboard-time-series-totals-response';
import { ActivityDashboardTopByAgentsResponse } from '../models/activity-dashboard-top-by-agents-response';
import { ActivityDashboardTopByGroupsResponse } from '../models/activity-dashboard-top-by-groups-response';
import { CallLogModel } from '../models/call-log-model';
import { CallLogRequest } from '../models/call-log-request';
import { CallLogsRequest } from '../models/call-logs-request';
import { CallLogsResponse } from '../models/call-logs-response';
import { ExportCallLogsRequest } from '../models/export-call-logs-request';
import { ExportGroupAgentsSummaryRequest } from '../models/export-group-agents-summary-request';
import { ExportGroupsSummaryRequest } from '../models/export-groups-summary-request';
import { ExportUsersSummaryRequest } from '../models/export-users-summary-request';
import { GroupAgentsSummaryRequest } from '../models/group-agents-summary-request';
import { GroupAgentsSummaryResponse } from '../models/group-agents-summary-response';
import { GroupsSummaryRequest } from '../models/groups-summary-request';
import { GroupsSummaryResponse } from '../models/groups-summary-response';
import { RawCallLogRequest } from '../models/raw-call-log-request';
import { RawCallModel } from '../models/raw-call-model';
import { UsersSummaryFilterRequest } from '../models/users-summary-filter-request';
import { UsersSummaryResponse } from '../models/users-summary-response';

@Injectable({ providedIn: 'root' })
export class ReportsV2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `callLogsV2GetCallLogs()` */
  static readonly CallLogsV2GetCallLogsPath = '/api/reports-v2/call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs$Response(
    params: {
      body: CallLogsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CallLogsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.CallLogsV2GetCallLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CallLogsResponse>;
      })
    );
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs(
    params: {
      body: CallLogsRequest
    },
    context?: HttpContext
  ): Observable<CallLogsResponse> {
    return this.callLogsV2GetCallLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogsResponse>): CallLogsResponse => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetExport()` */
  static readonly CallLogsV2GetExportPath = '/api/reports-v2/call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport$Response(
    params: {
      body: ExportCallLogsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.CallLogsV2GetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport(
    params: {
      body: ExportCallLogsRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.callLogsV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetRawCallDetails()` */
  static readonly CallLogsV2GetRawCallDetailsPath = '/api/reports-v2/raw-call-details';

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetRawCallDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails$Response(
    params: {
      body: RawCallLogRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RawCallModel>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.CallLogsV2GetRawCallDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RawCallModel>;
      })
    );
  }

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetRawCallDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails(
    params: {
      body: RawCallLogRequest
    },
    context?: HttpContext
  ): Observable<RawCallModel> {
    return this.callLogsV2GetRawCallDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<RawCallModel>): RawCallModel => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetCallFlowDetails()` */
  static readonly CallLogsV2GetCallFlowDetailsPath = '/api/reports-v2/call-flow-detail';

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallFlowDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails$Response(
    params: {
      body: CallLogRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CallLogModel>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.CallLogsV2GetCallFlowDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CallLogModel>;
      })
    );
  }

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallFlowDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails(
    params: {
      body: CallLogRequest
    },
    context?: HttpContext
  ): Observable<CallLogModel> {
    return this.callLogsV2GetCallFlowDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogModel>): CallLogModel => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetSummaryData()` */
  static readonly GroupsSummaryV2GetSummaryDataPath = '/api/reports-v2/groups-summary-data';

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData$Response(
    params: {
      body: GroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupsSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.GroupsSummaryV2GetSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupsSummaryResponse>;
      })
    );
  }

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData(
    params: {
      body: GroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<GroupsSummaryResponse> {
    return this.groupsSummaryV2GetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsSummaryResponse>): GroupsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetAgentsSummaryData()` */
  static readonly GroupsSummaryV2GetAgentsSummaryDataPath = '/api/reports-v2/groups-agent-summary-data';

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetAgentsSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData$Response(
    params: {
      body: GroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupAgentsSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.GroupsSummaryV2GetAgentsSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupAgentsSummaryResponse>;
      })
    );
  }

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetAgentsSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData(
    params: {
      body: GroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<GroupAgentsSummaryResponse> {
    return this.groupsSummaryV2GetAgentsSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupAgentsSummaryResponse>): GroupAgentsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExport()` */
  static readonly GroupsSummaryV2GetExportPath = '/api/reports-v2/groups-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport$Response(
    params: {
      body: ExportGroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.GroupsSummaryV2GetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport(
    params: {
      body: ExportGroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.groupsSummaryV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExportAgents()` */
  static readonly GroupsSummaryV2GetExportAgentsPath = '/api/reports-v2/groups-summary/export-agents';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExportAgents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents$Response(
    params: {
      body: ExportGroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.GroupsSummaryV2GetExportAgentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExportAgents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents(
    params: {
      body: ExportGroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.groupsSummaryV2GetExportAgents$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersSummaryV2GetSummaryData()` */
  static readonly UsersSummaryV2GetSummaryDataPath = '/api/reports-v2/users-summary';

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryV2GetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetSummaryData$Response(
    params: {
      body: UsersSummaryFilterRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UsersSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.UsersSummaryV2GetSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersSummaryResponse>;
      })
    );
  }

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryV2GetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetSummaryData(
    params: {
      body: UsersSummaryFilterRequest
    },
    context?: HttpContext
  ): Observable<UsersSummaryResponse> {
    return this.usersSummaryV2GetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UsersSummaryResponse>): UsersSummaryResponse => r.body)
    );
  }

  /** Path part for operation `usersSummaryV2GetExport()` */
  static readonly UsersSummaryV2GetExportPath = '/api/reports-v2/users-summary/export';

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetExport$Response(
    params: {
      body: ExportUsersSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.UsersSummaryV2GetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Trigger custom reports scheduler.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryV2GetExport(
    params: {
      body: ExportUsersSummaryRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.usersSummaryV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardData()` */
  static readonly ActivityDashboardV2GetActivityDashboardDataPath = '/api/reports-v2/activity-dashboard-data';

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.ActivityDashboardV2GetActivityDashboardDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardResponse>;
      })
    );
  }

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardData(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardResponse> {
    return this.activityDashboardV2GetActivityDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardResponse>): ActivityDashboardResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardGrandTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardGrandTotalsPath = '/api/reports-v2/activity-dashboard-grand-totals';

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardGrandTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardGrandTotalsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.ActivityDashboardV2GetActivityDashboardGrandTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardGrandTotalsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard grand totals.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardGrandTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardGrandTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardGrandTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardGrandTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardGrandTotalsResponse>): ActivityDashboardGrandTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTimeSeriesTotalsPath = '/api/reports-v2/activity-dashboard-time-series-totals';

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTimeSeriesTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.ActivityDashboardV2GetActivityDashboardTimeSeriesTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard time series.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTimeSeriesTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardTimeSeriesTotalsResponse> {
    return this.activityDashboardV2GetActivityDashboardTimeSeriesTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTimeSeriesTotalsResponse>): ActivityDashboardTimeSeriesTotalsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByGroupTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByGroupTotalsPath = '/api/reports-v2/activity-dashboard-top-groups';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByGroupTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardTopByGroupsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.ActivityDashboardV2GetActivityDashboardTopByGroupTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardTopByGroupsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByGroupTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByGroupTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardTopByGroupsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByGroupTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByGroupsResponse>): ActivityDashboardTopByGroupsResponse => r.body)
    );
  }

  /** Path part for operation `activityDashboardV2GetActivityDashboardTopByAgentTotals()` */
  static readonly ActivityDashboardV2GetActivityDashboardTopByAgentTotalsPath = '/api/reports-v2/activity-dashboard-top-agents';

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardV2GetActivityDashboardTopByAgentTotals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityDashboardTopByAgentsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsV2Service.ActivityDashboardV2GetActivityDashboardTopByAgentTotalsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityDashboardTopByAgentsResponse>;
      })
    );
  }

  /**
   * Get activity dashboard top groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardV2GetActivityDashboardTopByAgentTotals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardV2GetActivityDashboardTopByAgentTotals(
    params: {
      body: ActivityDashboardRequest
    },
    context?: HttpContext
  ): Observable<ActivityDashboardTopByAgentsResponse> {
    return this.activityDashboardV2GetActivityDashboardTopByAgentTotals$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityDashboardTopByAgentsResponse>): ActivityDashboardTopByAgentsResponse => r.body)
    );
  }

}
