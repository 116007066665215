/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CurrentUser } from '../models/current-user';
import { CurrentUserAccountModel } from '../models/current-user-account-model';
import { LocalLogin } from '../models/local-login';
import { ResetPasswordModel } from '../models/reset-password-model';
import { VerifyOtpModel } from '../models/verify-otp-model';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authenticationLoginWithToken()` */
  static readonly AuthenticationLoginWithTokenPath = '/api/auth/login';

  /**
   * Single click login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLoginWithToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithToken$Response(
    params: {
      token: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationLoginWithTokenPath, 'get');
    if (params) {
      rb.query('token', params.token, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Single click login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLoginWithToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithToken(
    params: {
      token: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationLoginWithToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationLogin()` */
  static readonly AuthenticationLoginPath = '/api/auth/login';

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationLogin$Response(
    params: {
      body: LocalLogin
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CurrentUser>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrentUser>;
      })
    );
  }

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationLogin(
    params: {
      body: LocalLogin
    },
    context?: HttpContext
  ): Observable<CurrentUser> {
    return this.authenticationLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `authenticationLoginWithSoftphone()` */
  static readonly AuthenticationLoginWithSoftphonePath = '/api/auth/softphone-login';

  /**
   * Login to portal from softphone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLoginWithSoftphone()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithSoftphone$Response(
    params: {
      username: string;
      password: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationLoginWithSoftphonePath, 'get');
    if (params) {
      rb.query('username', params.username, {});
      rb.query('password', params.password, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Login to portal from softphone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLoginWithSoftphone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLoginWithSoftphone(
    params: {
      username: string;
      password: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationLoginWithSoftphone$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationVerifyOtp()` */
  static readonly AuthenticationVerifyOtpPath = '/api/auth/verify-otp';

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationVerifyOtp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationVerifyOtp$Response(
    params: {
      body: VerifyOtpModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CurrentUser>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationVerifyOtpPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrentUser>;
      })
    );
  }

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationVerifyOtp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationVerifyOtp(
    params: {
      body: VerifyOtpModel
    },
    context?: HttpContext
  ): Observable<CurrentUser> {
    return this.authenticationVerifyOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `authenticationResendOtp()` */
  static readonly AuthenticationResendOtpPath = '/api/auth/resend-otp';

  /**
   * Resend OTP to phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationResendOtp()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationResendOtp$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationResendOtpPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Resend OTP to phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationResendOtp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationResendOtp(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.authenticationResendOtp$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `authenticationLogout()` */
  static readonly AuthenticationLogoutPath = '/api/auth/logout';

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLogout$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationLogoutPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Login using local authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationLogout(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationLogout$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationResetPasswordConfirm()` */
  static readonly AuthenticationResetPasswordConfirmPath = '/api/auth/reset-password';

  /**
   * Submit new password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationResetPasswordConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationResetPasswordConfirm$Response(
    params: {
      body: ResetPasswordModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationResetPasswordConfirmPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Submit new password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationResetPasswordConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationResetPasswordConfirm(
    params: {
      body: ResetPasswordModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationResetPasswordConfirm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationResetPassword()` */
  static readonly AuthenticationResetPasswordPath = '/api/auth/reset-password';

  /**
   * Send reset password email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationResetPassword()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  authenticationResetPassword$Response(
    params: {
      body: string
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'text/plain');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Send reset password email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationResetPassword$Response()` instead.
   *
   * This method sends `text/plain` and handles request body of type `text/plain`.
   */
  authenticationResetPassword(
    params: {
      body: string
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationResetPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationGoogleLogin()` */
  static readonly AuthenticationGoogleLoginPath = '/api/auth/google/login';

  /**
   * Login using google oauth2.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationGoogleLogin()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleLogin$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationGoogleLoginPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Login using google oauth2.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationGoogleLogin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleLogin(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.authenticationGoogleLogin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `authenticationGoogleCallback()` */
  static readonly AuthenticationGoogleCallbackPath = '/api/auth/google/callback';

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationGoogleCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleCallback$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CurrentUser>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationGoogleCallbackPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrentUser>;
      })
    );
  }

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationGoogleCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  authenticationGoogleCallback(
    params?: {
    },
    context?: HttpContext
  ): Observable<CurrentUser> {
    return this.authenticationGoogleCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `authenticationSwitchCompany()` */
  static readonly AuthenticationSwitchCompanyPath = '/api/auth/switch-company';

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authenticationSwitchCompany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationSwitchCompany$Response(
    params: {
      body: CurrentUserAccountModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CurrentUser>> {
    const rb = new RequestBuilder(this.rootUrl, AuthService.AuthenticationSwitchCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrentUser>;
      })
    );
  }

  /**
   * Login using google authentication strategy.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authenticationSwitchCompany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authenticationSwitchCompany(
    params: {
      body: CurrentUserAccountModel
    },
    context?: HttpContext
  ): Observable<CurrentUser> {
    return this.authenticationSwitchCompany$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

}
