/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ContactModel } from '../models/contact-model';
import { ContactSyncModel } from '../models/contact-sync-model';
import { ContactSyncResultsResponse } from '../models/contact-sync-results-response';
import { ContactsImportResponse } from '../models/contacts-import-response';
import { ContactsLookupRequest } from '../models/contacts-lookup-request';
import { ContactsRequest } from '../models/contacts-request';
import { ContactsResponse } from '../models/contacts-response';
import { CustomFieldDefinitionsRequest } from '../models/custom-field-definitions-request';
import { CustomFieldModel } from '../models/custom-field-model';
import { DeleteContactResponse } from '../models/delete-contact-response';
import { DuplicateContactGridResponse } from '../models/duplicate-contact-grid-response';
import { DuplicateContactsRequest } from '../models/duplicate-contacts-request';
import { DuplicateContactsSummaryResponse } from '../models/duplicate-contacts-summary-response';
import { FileUploadModel } from '../models/file-upload-model';
import { LookupContactGridResponse } from '../models/lookup-contact-grid-response';
import { SaveContactsRequest } from '../models/save-contacts-request';

@Injectable({ providedIn: 'root' })
export class ContactService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactGetUserLookupContacts()` */
  static readonly ContactGetUserLookupContactsPath = '/api/contact/lookup';

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserLookupContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts$Response(
    params: {
      body: ContactsLookupRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupContactGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactGetUserLookupContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupContactGridResponse>;
      })
    );
  }

  /**
   * Get List Of Lookup Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserLookupContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserLookupContacts(
    params: {
      body: ContactsLookupRequest
    },
    context?: HttpContext
  ): Observable<LookupContactGridResponse> {
    return this.contactGetUserLookupContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupContactGridResponse>): LookupContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetUserContacts()` */
  static readonly ContactGetUserContactsPath = '/api/contact/list';

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts$Response(
    params: {
      body: ContactsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactGetUserContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactsResponse>;
      })
    );
  }

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetUserContacts(
    params: {
      body: ContactsRequest
    },
    context?: HttpContext
  ): Observable<ContactsResponse> {
    return this.contactGetUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactsResponse>): ContactsResponse => r.body)
    );
  }

  /** Path part for operation `contactGetContactById()` */
  static readonly ContactGetContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactModel>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactGetContactByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactModel>;
      })
    );
  }

  /**
   * Get get contact by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetContactById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<ContactModel> {
    return this.contactGetContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactDeleteContactById()` */
  static readonly ContactDeleteContactByIdPath = '/api/contact/contact/{id}';

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactDeleteContactById()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DeleteContactResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactDeleteContactByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeleteContactResponse>;
      })
    );
  }

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactDeleteContactById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactDeleteContactById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<DeleteContactResponse> {
    return this.contactDeleteContactById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeleteContactResponse>): DeleteContactResponse => r.body)
    );
  }

  /** Path part for operation `contactSaveContact()` */
  static readonly ContactSaveContactPath = '/api/contact/save';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact$Response(
    params: {
      body: ContactModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactModel>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSaveContactPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactModel>;
      })
    );
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContact(
    params: {
      body: ContactModel
    },
    context?: HttpContext
  ): Observable<ContactModel> {
    return this.contactSaveContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactModel>): ContactModel => r.body)
    );
  }

  /** Path part for operation `contactSaveContacts()` */
  static readonly ContactSaveContactsPath = '/api/contact/save-contacts';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSaveContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts$Response(
    params: {
      body: SaveContactsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSaveContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSaveContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSaveContacts(
    params: {
      body: SaveContactsRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.contactSaveContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContactsSummary()` */
  static readonly ContactGetDuplicateUserContactsSummaryPath = '/api/contact/duplicate-summary';

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContactsSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DuplicateContactsSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactGetDuplicateUserContactsSummaryPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateContactsSummaryResponse>;
      })
    );
  }

  /**
   * Get Summary Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContactsSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactGetDuplicateUserContactsSummary(
    params?: {
    },
    context?: HttpContext
  ): Observable<DuplicateContactsSummaryResponse> {
    return this.contactGetDuplicateUserContactsSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactsSummaryResponse>): DuplicateContactsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `contactGetDuplicateUserContacts()` */
  static readonly ContactGetDuplicateUserContactsPath = '/api/contact/duplicate-list';

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetDuplicateUserContacts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts$Response(
    params: {
      body: DuplicateContactsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<DuplicateContactGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactGetDuplicateUserContactsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DuplicateContactGridResponse>;
      })
    );
  }

  /**
   * Get List Of Duplicate Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetDuplicateUserContacts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetDuplicateUserContacts(
    params: {
      body: DuplicateContactsRequest
    },
    context?: HttpContext
  ): Observable<DuplicateContactGridResponse> {
    return this.contactGetDuplicateUserContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<DuplicateContactGridResponse>): DuplicateContactGridResponse => r.body)
    );
  }

  /** Path part for operation `contactGetCustomFieldsDefinitions()` */
  static readonly ContactGetCustomFieldsDefinitionsPath = '/api/contact/custom-fields';

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactGetCustomFieldsDefinitions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions$Response(
    params: {
      body: CustomFieldDefinitionsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CustomFieldModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactGetCustomFieldsDefinitionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomFieldModel>>;
      })
    );
  }

  /**
   * Get List Custom Fields.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactGetCustomFieldsDefinitions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactGetCustomFieldsDefinitions(
    params: {
      body: CustomFieldDefinitionsRequest
    },
    context?: HttpContext
  ): Observable<Array<CustomFieldModel>> {
    return this.contactGetCustomFieldsDefinitions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CustomFieldModel>>): Array<CustomFieldModel> => r.body)
    );
  }

  /** Path part for operation `contactSyncGetSyncSettings()` */
  static readonly ContactSyncGetSyncSettingsPath = '/api/contact/sync-settings';

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncGetSyncSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGetSyncSettings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactSyncModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncGetSyncSettingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactSyncModel>>;
      })
    );
  }

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncGetSyncSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGetSyncSettings(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ContactSyncModel>> {
    return this.contactSyncGetSyncSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactSyncModel>>): Array<ContactSyncModel> => r.body)
    );
  }

  /** Path part for operation `contactSyncSaveSyncSettings()` */
  static readonly ContactSyncSaveSyncSettingsPath = '/api/contact/sync-settings';

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSaveSyncSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSyncSaveSyncSettings$Response(
    params: {
      body: ContactSyncModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncSaveSyncSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Get Sync Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSaveSyncSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  contactSyncSaveSyncSettings(
    params: {
      body: ContactSyncModel
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.contactSyncSaveSyncSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncRevokeGoogleToken()` */
  static readonly ContactSyncRevokeGoogleTokenPath = '/api/contact/google/revoke';

  /**
   * Revoke google auth token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncRevokeGoogleToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeGoogleToken$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncRevokeGoogleTokenPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Revoke google auth token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncRevokeGoogleToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeGoogleToken(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.contactSyncRevokeGoogleToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncGoogleCallback()` */
  static readonly ContactSyncGoogleCallbackPath = '/api/contact/google/callback';

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncGoogleCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGoogleCallback$Response(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncGoogleCallbackPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncGoogleCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncGoogleCallback(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.contactSyncGoogleCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactSyncSaveGoogleToken()` */
  static readonly ContactSyncSaveGoogleTokenPath = '/api/contact/google/callback-finish';

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSaveGoogleToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveGoogleToken$Response(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncSaveGoogleTokenPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSaveGoogleToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveGoogleToken(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.contactSyncSaveGoogleToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncRevokeMicrosoftToken()` */
  static readonly ContactSyncRevokeMicrosoftTokenPath = '/api/contact/microsoft/revoke';

  /**
   * Revoke microsoft auth token.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncRevokeMicrosoftToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeMicrosoftToken$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncRevokeMicrosoftTokenPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Revoke microsoft auth token.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncRevokeMicrosoftToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncRevokeMicrosoftToken(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.contactSyncRevokeMicrosoftToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncMicrosoftCallback()` */
  static readonly ContactSyncMicrosoftCallbackPath = '/api/contact/microsoft/callback';

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncMicrosoftCallback()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncMicrosoftCallback$Response(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncMicrosoftCallbackPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncMicrosoftCallback$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncMicrosoftCallback(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.contactSyncMicrosoftCallback$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `contactSyncSaveMicrosoftToken()` */
  static readonly ContactSyncSaveMicrosoftTokenPath = '/api/contact/microsoft/callback-finish';

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSaveMicrosoftToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveMicrosoftToken$Response(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncSaveMicrosoftTokenPath, 'get');
    if (params) {
      rb.query('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Save Callback after google login.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSaveMicrosoftToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSaveMicrosoftToken(
    params: {
      code: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.contactSyncSaveMicrosoftToken$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `contactSyncImportCsv()` */
  static readonly ContactSyncImportCsvPath = '/api/contact/import-csv';

  /**
   * Import Contacts from CSV file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncImportCsv()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactSyncImportCsv$Response(
    params: {
  
    /**
     * Csv Attachment
     */
    body: FileUploadModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactsImportResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncImportCsvPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactsImportResponse>;
      })
    );
  }

  /**
   * Import Contacts from CSV file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncImportCsv$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  contactSyncImportCsv(
    params: {
  
    /**
     * Csv Attachment
     */
    body: FileUploadModel
    },
    context?: HttpContext
  ): Observable<ContactsImportResponse> {
    return this.contactSyncImportCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactsImportResponse>): ContactsImportResponse => r.body)
    );
  }

  /** Path part for operation `contactSyncSyncPbxContactsByProvider()` */
  static readonly ContactSyncSyncPbxContactsByProviderPath = '/api/contact/pbx/sync-contacts/{provider}';

  /**
   * Sync Contacts Manually.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `contactSyncSyncPbxContactsByProvider()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSyncPbxContactsByProvider$Response(
    params: {
      provider: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ContactSyncResultsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ContactService.ContactSyncSyncPbxContactsByProviderPath, 'get');
    if (params) {
      rb.path('provider', params.provider, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ContactSyncResultsResponse>;
      })
    );
  }

  /**
   * Sync Contacts Manually.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `contactSyncSyncPbxContactsByProvider$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  contactSyncSyncPbxContactsByProvider(
    params: {
      provider: string;
    },
    context?: HttpContext
  ): Observable<ContactSyncResultsResponse> {
    return this.contactSyncSyncPbxContactsByProvider$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactSyncResultsResponse>): ContactSyncResultsResponse => r.body)
    );
  }

}
