/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ComposeNotificationModel } from '../models/compose-notification-model';
import { CounterPathLicenseAddedModel } from '../models/counter-path-license-added-model';
import { CustomerPackageChangeModel } from '../models/customer-package-change-model';
import { CustomerSessionModel } from '../models/customer-session-model';
import { FaxDomainSettingsRequest } from '../models/fax-domain-settings-request';
import { ModuleDefinitionModel } from '../models/module-definition-model';
import { PackageChangeModel } from '../models/package-change-model';
import { SyncCustomerPbxAccountRequest } from '../models/sync-customer-pbx-account-request';
import { SyncCustomerPbxAccountsRequest } from '../models/sync-customer-pbx-accounts-request';
import { SyncCustomerPbxRequest } from '../models/sync-customer-pbx-request';
import { UserDependenciesModel } from '../models/user-dependencies-model';
import { UserInvitationModel } from '../models/user-invitation-model';

@Injectable({ providedIn: 'root' })
export class SystemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `systemSendUserInvitation()` */
  static readonly SystemSendUserInvitationPath = '/api/system/send-user-invitation';

  /**
   * Send invitation email to user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSendUserInvitation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendUserInvitation$Response(
    params: {
      body: UserInvitationModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSendUserInvitationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Send invitation email to user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSendUserInvitation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendUserInvitation(
    params: {
      body: UserInvitationModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSendUserInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemGetModuleList()` */
  static readonly SystemGetModuleListPath = '/api/system/module-list';

  /**
   * Get list of all modules.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemGetModuleList()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemGetModuleList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ModuleDefinitionModel>>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemGetModuleListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ModuleDefinitionModel>>;
      })
    );
  }

  /**
   * Get list of all modules.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemGetModuleList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemGetModuleList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<ModuleDefinitionModel>> {
    return this.systemGetModuleList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ModuleDefinitionModel>>): Array<ModuleDefinitionModel> => r.body)
    );
  }

  /** Path part for operation `systemUpdatePrimaryRoles()` */
  static readonly SystemUpdatePrimaryRolesPath = '/api/system/update-roles';

  /**
   * Update system roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdatePrimaryRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdatePrimaryRoles$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemUpdatePrimaryRolesPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Update system roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdatePrimaryRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdatePrimaryRoles(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.systemUpdatePrimaryRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `systemRecoverFileById()` */
  static readonly SystemRecoverFileByIdPath = '/api/system/recover-recording/{id}';

  /**
   * Restore Recording By Id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemRecoverFileById()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemRecoverFileByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Restore Recording By Id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemRecoverFileById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemRecoverFileById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemRecoverFileByCustomerId()` */
  static readonly SystemRecoverFileByCustomerIdPath = '/api/system/recover-recording/customer/{id}';

  /**
   * Restore Recording By CustomerId.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemRecoverFileByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileByCustomerId$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemRecoverFileByCustomerIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Restore Recording By CustomerId.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemRecoverFileByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRecoverFileByCustomerId(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemRecoverFileByCustomerId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemDisableSmsPro()` */
  static readonly SystemDisableSmsProPath = '/api/system/disable-sms-pro/{id}';

  /**
   * Disable sms pro and remove sms users from phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemDisableSmsPro()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSmsPro$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemDisableSmsProPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable sms pro and remove sms users from phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemDisableSmsPro$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSmsPro(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemDisableSmsPro$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemDisableSms()` */
  static readonly SystemDisableSmsPath = '/api/system/disable-sms/{id}';

  /**
   * Disable sms and remove sms users from phone number.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemDisableSms()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSms$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemDisableSmsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Disable sms and remove sms users from phone number.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemDisableSms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemDisableSms(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemDisableSms$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateSms()` */
  static readonly SystemUpdateSmsPath = '/api/system/update-sms/{id}';

  /**
   * Update sms and Campaign ID on Bandwidth.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateSms()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdateSms$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemUpdateSmsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update sms and Campaign ID on Bandwidth.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateSms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemUpdateSms(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemUpdateSms$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemCustomerPackageChangeNotification()` */
  static readonly SystemCustomerPackageChangeNotificationPath = '/api/system/customer-package-change-notification';

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemCustomerPackageChangeNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCustomerPackageChangeNotification$Response(
    params: {
      body: CustomerPackageChangeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemCustomerPackageChangeNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemCustomerPackageChangeNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCustomerPackageChangeNotification(
    params: {
      body: CustomerPackageChangeModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemCustomerPackageChangeNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemPackageChangeNotification()` */
  static readonly SystemPackageChangeNotificationPath = '/api/system/package-change-notification';

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemPackageChangeNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemPackageChangeNotification$Response(
    params: {
      body: PackageChangeModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemPackageChangeNotificationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Notify Billing if CP customer package was changed.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemPackageChangeNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemPackageChangeNotification(
    params: {
      body: PackageChangeModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemPackageChangeNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateUsersSessions()` */
  static readonly SystemUpdateUsersSessionsPath = '/api/system/update-users-sessions';

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateUsersSessions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersSessions$Response(
    params: {
      body: CustomerSessionModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemUpdateUsersSessionsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateUsersSessions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersSessions(
    params: {
      body: CustomerSessionModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemUpdateUsersSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateUsersDependencies()` */
  static readonly SystemUpdateUsersDependenciesPath = '/api/system/update-user-dependencies';

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateUsersDependencies()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersDependencies$Response(
    params: {
      body: UserDependenciesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemUpdateUsersDependenciesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update active user sessions with new changes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateUsersDependencies$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateUsersDependencies(
    params: {
      body: UserDependenciesModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemUpdateUsersDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemUpdateFaxDomainSettings()` */
  static readonly SystemUpdateFaxDomainSettingsPath = '/api/system/update-fax-domain-settings';

  /**
   * Update fax domain settings if user was updated.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemUpdateFaxDomainSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateFaxDomainSettings$Response(
    params: {
      body: FaxDomainSettingsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemUpdateFaxDomainSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update fax domain settings if user was updated.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemUpdateFaxDomainSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemUpdateFaxDomainSettings(
    params: {
      body: FaxDomainSettingsRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemUpdateFaxDomainSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemRefreshPbxAccounts()` */
  static readonly SystemRefreshPbxAccountsPath = '/api/system/refresh-pbx-accounts';

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemRefreshPbxAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRefreshPbxAccounts$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemRefreshPbxAccountsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemRefreshPbxAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemRefreshPbxAccounts(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemRefreshPbxAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemReprovisionAcrobits()` */
  static readonly SystemReprovisionAcrobitsPath = '/api/system/reprovision-acrobits/{customerUserId}';

  /**
   * Notify basic softphone user to reprovision.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemReprovisionAcrobits()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemReprovisionAcrobits$Response(
    params: {
      customerUserId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemReprovisionAcrobitsPath, 'get');
    if (params) {
      rb.path('customerUserId', params.customerUserId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Notify basic softphone user to reprovision.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemReprovisionAcrobits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemReprovisionAcrobits(
    params: {
      customerUserId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemReprovisionAcrobits$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemCompose()` */
  static readonly SystemComposePath = '/api/system/compose-notification';

  /**
   * Compose notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemCompose()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCompose$Response(
    params: {
      body: ComposeNotificationModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemComposePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Compose notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemCompose$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemCompose(
    params: {
      body: ComposeNotificationModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemCompose$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSendCounterPathLicenseEmail()` */
  static readonly SystemSendCounterPathLicenseEmailPath = '/api/system/counter-path-license-email';

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSendCounterPathLicenseEmail()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendCounterPathLicenseEmail$Response(
    params: {
      body: CounterPathLicenseAddedModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSendCounterPathLicenseEmailPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Refresh Pbx Accounts for reporting.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSendCounterPathLicenseEmail$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSendCounterPathLicenseEmail(
    params: {
      body: CounterPathLicenseAddedModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSendCounterPathLicenseEmail$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncBrands()` */
  static readonly SystemSyncBrandsPath = '/api/system/sync-brands';

  /**
   * Sync 10DLC Brands.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncBrands()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncBrands$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncBrandsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync 10DLC Brands.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncBrands$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncBrands(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncBrands$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCampaigns()` */
  static readonly SystemSyncCampaignsPath = '/api/system/sync-campaigns';

  /**
   * Sync 10DLC Campaigns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaigns$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncCampaignsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync 10DLC Campaigns.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaigns(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCampaignsStatus()` */
  static readonly SystemSyncCampaignsStatusPath = '/api/system/sync-campaigns-status';

  /**
   * Sync 10DLC Campaigns Status from Bandwidth.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCampaignsStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaignsStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncCampaignsStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync 10DLC Campaigns Status from Bandwidth.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCampaignsStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncCampaignsStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncCampaignsStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncPhoneNumberCampaigns()` */
  static readonly SystemSyncPhoneNumberCampaignsPath = '/api/system/sync-phone-number-campaigns';

  /**
   * Sync 10DLC Phone Number Campaigns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncPhoneNumberCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPhoneNumberCampaigns$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncPhoneNumberCampaignsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync 10DLC Phone Number Campaigns.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncPhoneNumberCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPhoneNumberCampaigns(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncPhoneNumberCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncPendingPhoneNumberCampaigns()` */
  static readonly SystemSyncPendingPhoneNumberCampaignsPath = '/api/system/sync-pending-phone-number-campaigns';

  /**
   * Sync Pending Phone Number Campaigns.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncPendingPhoneNumberCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPendingPhoneNumberCampaigns$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncPendingPhoneNumberCampaignsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync Pending Phone Number Campaigns.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncPendingPhoneNumberCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncPendingPhoneNumberCampaigns(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncPendingPhoneNumberCampaigns$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncAllCustomersPbx()` */
  static readonly SystemSyncAllCustomersPbxPath = '/api/system/sync-all-customers-pbx';

  /**
   * Sync All Customers Pbx Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncAllCustomersPbx()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncAllCustomersPbx$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncAllCustomersPbxPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync All Customers Pbx Data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncAllCustomersPbx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  systemSyncAllCustomersPbx(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncAllCustomersPbx$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCustomerPbx()` */
  static readonly SystemSyncCustomerPbxPath = '/api/system/sync-customer-pbx';

  /**
   * Sync One Customer All Pbx Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCustomerPbx()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbx$Response(
    params: {
      body: SyncCustomerPbxRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncCustomerPbxPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync One Customer All Pbx Data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCustomerPbx$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbx(
    params: {
      body: SyncCustomerPbxRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncCustomerPbx$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCustomerPbxAccounts()` */
  static readonly SystemSyncCustomerPbxAccountsPath = '/api/system/sync-customer-pbx-accounts';

  /**
   * Sync One Customer Pbx Accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCustomerPbxAccounts()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccounts$Response(
    params: {
      body: SyncCustomerPbxAccountsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncCustomerPbxAccountsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync One Customer Pbx Accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCustomerPbxAccounts$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccounts(
    params: {
      body: SyncCustomerPbxAccountsRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncCustomerPbxAccounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `systemSyncCustomerPbxAccount()` */
  static readonly SystemSyncCustomerPbxAccountPath = '/api/system/sync-customer-pbx-account';

  /**
   * Sync One Customer One Account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `systemSyncCustomerPbxAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccount$Response(
    params: {
      body: SyncCustomerPbxAccountRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemService.SystemSyncCustomerPbxAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync One Customer One Account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `systemSyncCustomerPbxAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  systemSyncCustomerPbxAccount(
    params: {
      body: SyncCustomerPbxAccountRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.systemSyncCustomerPbxAccount$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
