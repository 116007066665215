/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BillingInfoResponse } from '../models/billing-info-response';
import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { GetMessagesModel } from '../models/get-messages-model';
import { MessageModel } from '../models/message-model';
import { MessagingGroupModel } from '../models/messaging-group-model';
import { MessagingGroupsGridResponse } from '../models/messaging-groups-grid-response';
import { MessagingGroupsRequest } from '../models/messaging-groups-request';
import { SendMessageModel } from '../models/send-message-model';
import { SmsPhoneNumberModel } from '../models/sms-phone-number-model';

@Injectable({ providedIn: 'root' })
export class MessagingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingSendMessage()` */
  static readonly MessagingSendMessagePath = '/api/messaging/send-message';

  /**
   * Send Message.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingSendMessage$Response(
    params: {
      body: SendMessageModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessageModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingSendMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageModel>;
      })
    );
  }

  /**
   * Send Message.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingSendMessage(
    params: {
      body: SendMessageModel
    },
    context?: HttpContext
  ): Observable<MessageModel> {
    return this.messagingSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `messagingGetMyMessagingPhoneNumbers()` */
  static readonly MessagingGetMyMessagingPhoneNumbersPath = '/api/messaging/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMyMessagingPhoneNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingGetMyMessagingPhoneNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMyMessagingPhoneNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SmsPhoneNumberModel>> {
    return this.messagingGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `messagingGetMessagingLimits()` */
  static readonly MessagingGetMessagingLimitsPath = '/api/messaging/messaging-limits';

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessagingLimits()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingLimits$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BillingInfoResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingGetMessagingLimitsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BillingInfoResponse>;
      })
    );
  }

  /**
   * Get messaging limits and current usage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessagingLimits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingLimits(
    params?: {
    },
    context?: HttpContext
  ): Observable<BillingInfoResponse> {
    return this.messagingGetMessagingLimits$Response(params, context).pipe(
      map((r: StrictHttpResponse<BillingInfoResponse>): BillingInfoResponse => r.body)
    );
  }

  /** Path part for operation `messagingGetMessagingGroups()` */
  static readonly MessagingGetMessagingGroupsPath = '/api/messaging/messaging-groups/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessagingGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingGroups$Response(
    params: {
      phoneNumber: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MessagingGroupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingGetMessagingGroupsPath, 'get');
    if (params) {
      rb.path('phoneNumber', params.phoneNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MessagingGroupModel>>;
      })
    );
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessagingGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingGetMessagingGroups(
    params: {
      phoneNumber: string;
    },
    context?: HttpContext
  ): Observable<Array<MessagingGroupModel>> {
    return this.messagingGetMessagingGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessagingGroupModel>>): Array<MessagingGroupModel> => r.body)
    );
  }

  /** Path part for operation `messagingGetMessagingGroupsV2()` */
  static readonly MessagingGetMessagingGroupsV2Path = '/api/messaging/messaging-groups-v2/{phoneNumber}';

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessagingGroupsV2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessagingGroupsV2$Response(
    params: {
      phoneNumber: string;
      body: MessagingGroupsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessagingGroupsGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingGetMessagingGroupsV2Path, 'post');
    if (params) {
      rb.path('phoneNumber', params.phoneNumber, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagingGroupsGridResponse>;
      })
    );
  }

  /**
   * Get messaging phone number groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessagingGroupsV2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessagingGroupsV2(
    params: {
      phoneNumber: string;
      body: MessagingGroupsRequest
    },
    context?: HttpContext
  ): Observable<MessagingGroupsGridResponse> {
    return this.messagingGetMessagingGroupsV2$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingGroupsGridResponse>): MessagingGroupsGridResponse => r.body)
    );
  }

  /** Path part for operation `messagingGetMessages()` */
  static readonly MessagingGetMessagesPath = '/api/messaging/get-messages';

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingGetMessages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessages$Response(
    params: {
      body: GetMessagesModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<MessageModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingGetMessagesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MessageModel>>;
      })
    );
  }

  /**
   * Get messages from/to specific sender.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingGetMessages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingGetMessages(
    params: {
      body: GetMessagesModel
    },
    context?: HttpContext
  ): Observable<Array<MessageModel>> {
    return this.messagingGetMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MessageModel>>): Array<MessageModel> => r.body)
    );
  }

  /** Path part for operation `messagingDeleteMessageBody()` */
  static readonly MessagingDeleteMessageBodyPath = '/api/messaging/message/{messageId}';

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingDeleteMessageBody()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteMessageBody$Response(
    params: {
      messageId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessageModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingDeleteMessageBodyPath, 'delete');
    if (params) {
      rb.path('messageId', params.messageId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageModel>;
      })
    );
  }

  /**
   * Delete Message body.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingDeleteMessageBody$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteMessageBody(
    params: {
      messageId: string;
    },
    context?: HttpContext
  ): Observable<MessageModel> {
    return this.messagingDeleteMessageBody$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageModel>): MessageModel => r.body)
    );
  }

  /** Path part for operation `messagingDeleteFullConversationByGroup()` */
  static readonly MessagingDeleteFullConversationByGroupPath = '/api/messaging/conversation-by-group/{groupKey}';

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingDeleteFullConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteFullConversationByGroup$Response(
    params: {
      groupKey: string;
      block: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingDeleteFullConversationByGroupPath, 'delete');
    if (params) {
      rb.path('groupKey', params.groupKey, {});
      rb.query('block', params.block, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete Full Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingDeleteFullConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingDeleteFullConversationByGroup(
    params: {
      groupKey: string;
      block: boolean;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.messagingDeleteFullConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingLookupContactsAndTags()` */
  static readonly MessagingLookupContactsAndTagsPath = '/api/messaging/lookup-contacts';

  /**
   * Lookup contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingLookupContactsAndTags$Response(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingService.MessagingLookupContactsAndTagsPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactTagLookupModel>>;
      })
    );
  }

  /**
   * Lookup contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingLookupContactsAndTags(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<Array<ContactTagLookupModel>> {
    return this.messagingLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

}
