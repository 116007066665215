import {
  ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router,
  RouterStateSnapshot
} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authService: AuthService, private router: Router) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const url: string = state.url;
    console.log('canActivate: ' + url);

    return await this.checkLogin(url, route);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('canActivateChild');
    return this.canActivate(route, state);
  }

  async canLoad(route: Route): Promise<boolean> {

    const url = `/${route.path}`;
    console.log('canLoad: ' + url);

    return await this.checkLogin(url, undefined);
  }

  async checkLogin(url: string, route?: ActivatedRouteSnapshot): Promise<boolean> {

    await this.authService.alive();

    if (!this.authService.isLoggedIn) {
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = url;
      console.log('checkLogin', url);

      // Navigate to the login page with extras
      await this.router.navigate(['/auth', 'login'], {queryParams: {redirectUrl: url}});
      return false;
    }

    if (route && route.data && route.data['package']) {
      // check if user has access to that package
      if (!this.authService.userHasPackage(route.data['package'])) {
        await this.router.navigate(['/home']);
        return false;
      }
    }

    if (route && route.data && route.data['permissions']) {
      // check if user has access permissions
      if (!this.authService.userHasPermission(route.data['permissions'])) {
        await this.router.navigate(['/home']);
        return false;
      }
    }

    return true;
  }
}
