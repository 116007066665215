/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { NotificationMarkAsReadModel } from '../models/notification-mark-as-read-model';
import { NotificationMarkAsUnreadModel } from '../models/notification-mark-as-unread-model';
import { NotificationModel } from '../models/notification-model';
import { PushSubscription } from '../models/push-subscription';

@Injectable({ providedIn: 'root' })
export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationGetLatestNotifications()` */
  static readonly NotificationGetLatestNotificationsPath = '/api/notifications/list';

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetLatestNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetLatestNotifications$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<NotificationModel>>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationGetLatestNotificationsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationModel>>;
      })
    );
  }

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetLatestNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetLatestNotifications(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<NotificationModel>> {
    return this.notificationGetLatestNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationModel>>): Array<NotificationModel> => r.body)
    );
  }

  /** Path part for operation `notificationGetExportNotifications()` */
  static readonly NotificationGetExportNotificationsPath = '/api/notifications/export-list';

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetExportNotifications()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportNotifications$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<NotificationModel>>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationGetExportNotificationsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NotificationModel>>;
      })
    );
  }

  /**
   * Get latest notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetExportNotifications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportNotifications(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<NotificationModel>> {
    return this.notificationGetExportNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationModel>>): Array<NotificationModel> => r.body)
    );
  }

  /** Path part for operation `notificationGetExportFile()` */
  static readonly NotificationGetExportFilePath = '/api/notifications/export/{filename}';

  /**
   * Download generated file.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationGetExportFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportFile$Response(
    params: {
      filename: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationGetExportFilePath, 'get');
    if (params) {
      rb.path('filename', params.filename, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * Download generated file.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationGetExportFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationGetExportFile(
    params: {
      filename: string;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.notificationGetExportFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `notificationMarkAsUnread()` */
  static readonly NotificationMarkAsUnreadPath = '/api/notifications/mark-as-unread';

  /**
   * Mark notification as unread.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationMarkAsUnread()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsUnread$Response(
    params: {
      body: NotificationMarkAsUnreadModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationMarkAsUnreadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mark notification as unread.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationMarkAsUnread$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsUnread(
    params: {
      body: NotificationMarkAsUnreadModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.notificationMarkAsUnread$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationMarkAsRead()` */
  static readonly NotificationMarkAsReadPath = '/api/notifications/mark-as-read';

  /**
   * Mark notification as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationMarkAsRead()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsRead$Response(
    params: {
      body: NotificationMarkAsReadModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationMarkAsReadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mark notification as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationMarkAsRead$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationMarkAsRead(
    params: {
      body: NotificationMarkAsReadModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.notificationMarkAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationMarkAllAsRead()` */
  static readonly NotificationMarkAllAsReadPath = '/api/notifications/mark-all-as-read';

  /**
   * Mark all notification as read.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationMarkAllAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationMarkAllAsRead$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationMarkAllAsReadPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Mark all notification as read.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationMarkAllAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationMarkAllAsRead(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.notificationMarkAllAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationSubscribe()` */
  static readonly NotificationSubscribePath = '/api/notifications/subscribe';

  /**
   * Subscribe for push notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationSubscribe()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationSubscribe$Response(
    params: {
      body: PushSubscription
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationSubscribePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Subscribe for push notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationSubscribe$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  notificationSubscribe(
    params: {
      body: PushSubscription
    },
    context?: HttpContext
  ): Observable<void> {
    return this.notificationSubscribe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationUnsubscribe()` */
  static readonly NotificationUnsubscribePath = '/api/notifications/unsubscribe';

  /**
   * Unsubscribe for push notifications.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationUnsubscribe()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationUnsubscribe$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, NotificationsService.NotificationUnsubscribePath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Unsubscribe for push notifications.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationUnsubscribe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationUnsubscribe(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.notificationUnsubscribe$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
