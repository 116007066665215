/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ActivityDashboardDataRequest } from '../models/activity-dashboard-data-request';
import { ActivityTotalModel } from '../models/activity-total-model';
import { BasicCallLogsGridResponse } from '../models/basic-call-logs-grid-response';
import { CallLogsExportRequest } from '../models/call-logs-export-request';
import { CallLogsFilterRequest } from '../models/call-logs-filter-request';
import { CallLogsGridResponse } from '../models/call-logs-grid-response';
import { FixDuplicateRecordsRequest } from '../models/fix-duplicate-records-request';
import { GroupAgentsSummaryExportModel } from '../models/group-agents-summary-export-model';
import { GroupAgentsSummaryFilterModel } from '../models/group-agents-summary-filter-model';
import { GroupsAgentSummaryGridResponse } from '../models/groups-agent-summary-grid-response';
import { GroupsDashboardDataRequest } from '../models/groups-dashboard-data-request';
import { GroupsSummaryExportRequest } from '../models/groups-summary-export-request';
import { GroupsSummaryFilterRequest } from '../models/groups-summary-filter-request';
import { GroupsSummaryResponse } from '../models/groups-summary-response';
import { GroupsTotalModel } from '../models/groups-total-model';
import { PbxAccountLookupModel } from '../models/pbx-account-lookup-model';
import { RawCallModel } from '../models/raw-call-model';
import { RecoverFixedRecordsRequest } from '../models/recover-fixed-records-request';
import { UsersDashboardDataRequest } from '../models/users-dashboard-data-request';
import { UsersSummaryExportRequest } from '../models/users-summary-export-request';
import { UsersSummaryFilterRequest } from '../models/users-summary-filter-request';
import { UsersSummaryGridResponse } from '../models/users-summary-grid-response';
import { UsersTotalModel } from '../models/users-total-model';

@Injectable({ providedIn: 'root' })
export class ReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `activityDashboardGetActivityDashboardData()` */
  static readonly ActivityDashboardGetActivityDashboardDataPath = '/api/reports/activity-dashboard-data';

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activityDashboardGetActivityDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardGetActivityDashboardData$Response(
    params: {
      body: ActivityDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ActivityTotalModel>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ActivityDashboardGetActivityDashboardDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ActivityTotalModel>;
      })
    );
  }

  /**
   * Get activity dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activityDashboardGetActivityDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activityDashboardGetActivityDashboardData(
    params: {
      body: ActivityDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<ActivityTotalModel> {
    return this.activityDashboardGetActivityDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<ActivityTotalModel>): ActivityTotalModel => r.body)
    );
  }

  /** Path part for operation `groupsDashboardGetGroupsDashboardData()` */
  static readonly GroupsDashboardGetGroupsDashboardDataPath = '/api/reports/groups-dashboard-data';

  /**
   * Get groups dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsDashboardGetGroupsDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsDashboardGetGroupsDashboardData$Response(
    params: {
      body: GroupsDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupsTotalModel>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.GroupsDashboardGetGroupsDashboardDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupsTotalModel>;
      })
    );
  }

  /**
   * Get groups dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsDashboardGetGroupsDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsDashboardGetGroupsDashboardData(
    params: {
      body: GroupsDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<GroupsTotalModel> {
    return this.groupsDashboardGetGroupsDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsTotalModel>): GroupsTotalModel => r.body)
    );
  }

  /** Path part for operation `basicCallLogsGetBasicCallLogsData()` */
  static readonly BasicCallLogsGetBasicCallLogsDataPath = '/api/reports/basic-call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicCallLogsGetBasicCallLogsData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetBasicCallLogsData$Response(
    params: {
      body: CallLogsFilterRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BasicCallLogsGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.BasicCallLogsGetBasicCallLogsDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BasicCallLogsGridResponse>;
      })
    );
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicCallLogsGetBasicCallLogsData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetBasicCallLogsData(
    params: {
      body: CallLogsFilterRequest
    },
    context?: HttpContext
  ): Observable<BasicCallLogsGridResponse> {
    return this.basicCallLogsGetBasicCallLogsData$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicCallLogsGridResponse>): BasicCallLogsGridResponse => r.body)
    );
  }

  /** Path part for operation `basicCallLogsGetExport()` */
  static readonly BasicCallLogsGetExportPath = '/api/reports/basic-call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicCallLogsGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetExport$Response(
    params: {
      body: CallLogsExportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.BasicCallLogsGetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicCallLogsGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicCallLogsGetExport(
    params: {
      body: CallLogsExportRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.basicCallLogsGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsGetCallLogsData()` */
  static readonly CallLogsGetCallLogsDataPath = '/api/reports/call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsGetCallLogsData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetCallLogsData$Response(
    params: {
      body: CallLogsFilterRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CallLogsGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.CallLogsGetCallLogsDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CallLogsGridResponse>;
      })
    );
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsGetCallLogsData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetCallLogsData(
    params: {
      body: CallLogsFilterRequest
    },
    context?: HttpContext
  ): Observable<CallLogsGridResponse> {
    return this.callLogsGetCallLogsData$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogsGridResponse>): CallLogsGridResponse => r.body)
    );
  }

  /** Path part for operation `callLogsGetExport()` */
  static readonly CallLogsGetExportPath = '/api/reports/call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetExport$Response(
    params: {
      body: CallLogsExportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.CallLogsGetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsGetExport(
    params: {
      body: CallLogsExportRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.callLogsGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsFixCdrRecords()` */
  static readonly CallLogsFixCdrRecordsPath = '/api/reports/fix-duplicate-records';

  /**
   * Fix transferred duplicate records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsFixCdrRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsFixCdrRecords$Response(
    params: {
      body: FixDuplicateRecordsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.CallLogsFixCdrRecordsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Fix transferred duplicate records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsFixCdrRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsFixCdrRecords(
    params: {
      body: FixDuplicateRecordsRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.callLogsFixCdrRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsRecoverMergedRecords()` */
  static readonly CallLogsRecoverMergedRecordsPath = '/api/reports/recover-duplicate-records';

  /**
   * Restore deleted/merged records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsRecoverMergedRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsRecoverMergedRecords$Response(
    params: {
      body: RecoverFixedRecordsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.CallLogsRecoverMergedRecordsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Restore deleted/merged records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsRecoverMergedRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsRecoverMergedRecords(
    params: {
      body: RecoverFixedRecordsRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.callLogsRecoverMergedRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `reportsGetCallDetails()` */
  static readonly ReportsGetCallDetailsPath = '/api/reports/call-record/{id}';

  /**
   * Get Call Record.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsGetCallDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetCallDetails$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RawCallModel>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportsGetCallDetailsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RawCallModel>;
      })
    );
  }

  /**
   * Get Call Record.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsGetCallDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetCallDetails(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<RawCallModel> {
    return this.reportsGetCallDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<RawCallModel>): RawCallModel => r.body)
    );
  }

  /** Path part for operation `reportsGetPbxAccountData()` */
  static readonly ReportsGetPbxAccountDataPath = '/api/reports/account-lookup/{domain}';

  /**
   * Get Pbx Extensions Lookup list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reportsGetPbxAccountData()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetPbxAccountData$Response(
    params: {
      domain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PbxAccountLookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ReportsGetPbxAccountDataPath, 'get');
    if (params) {
      rb.path('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PbxAccountLookupModel>>;
      })
    );
  }

  /**
   * Get Pbx Extensions Lookup list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `reportsGetPbxAccountData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reportsGetPbxAccountData(
    params: {
      domain: string;
    },
    context?: HttpContext
  ): Observable<Array<PbxAccountLookupModel>> {
    return this.reportsGetPbxAccountData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PbxAccountLookupModel>>): Array<PbxAccountLookupModel> => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetSummaryData()` */
  static readonly GroupsSummaryGetSummaryDataPath = '/api/reports/groups-summary-data';

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetSummaryData$Response(
    params: {
      body: GroupsSummaryFilterRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupsSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.GroupsSummaryGetSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupsSummaryResponse>;
      })
    );
  }

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetSummaryData(
    params: {
      body: GroupsSummaryFilterRequest
    },
    context?: HttpContext
  ): Observable<GroupsSummaryResponse> {
    return this.groupsSummaryGetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsSummaryResponse>): GroupsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetAgentsSummaryData()` */
  static readonly GroupsSummaryGetAgentsSummaryDataPath = '/api/reports/groups-agent-summary-data';

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetAgentsSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetAgentsSummaryData$Response(
    params: {
      body: GroupAgentsSummaryFilterModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupsAgentSummaryGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.GroupsSummaryGetAgentsSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupsAgentSummaryGridResponse>;
      })
    );
  }

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetAgentsSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetAgentsSummaryData(
    params: {
      body: GroupAgentsSummaryFilterModel
    },
    context?: HttpContext
  ): Observable<GroupsAgentSummaryGridResponse> {
    return this.groupsSummaryGetAgentsSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsAgentSummaryGridResponse>): GroupsAgentSummaryGridResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetExport()` */
  static readonly GroupsSummaryGetExportPath = '/api/reports/groups-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExport$Response(
    params: {
      body: GroupsSummaryExportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.GroupsSummaryGetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExport(
    params: {
      body: GroupsSummaryExportRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.groupsSummaryGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `groupsSummaryGetExportAgents()` */
  static readonly GroupsSummaryGetExportAgentsPath = '/api/reports/groups-summary/export-agents';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryGetExportAgents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExportAgents$Response(
    params: {
      body: GroupAgentsSummaryExportModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.GroupsSummaryGetExportAgentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryGetExportAgents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryGetExportAgents(
    params: {
      body: GroupAgentsSummaryExportModel
    },
    context?: HttpContext
  ): Observable<string> {
    return this.groupsSummaryGetExportAgents$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `usersSummaryGetUsersDashboardData()` */
  static readonly UsersSummaryGetUsersDashboardDataPath = '/api/reports/users-dashboard-data';

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryGetUsersDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersDashboardData$Response(
    params: {
      body: UsersDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UsersTotalModel>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.UsersSummaryGetUsersDashboardDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersTotalModel>;
      })
    );
  }

  /**
   * Get users dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryGetUsersDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersDashboardData(
    params: {
      body: UsersDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<UsersTotalModel> {
    return this.usersSummaryGetUsersDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UsersTotalModel>): UsersTotalModel => r.body)
    );
  }

  /** Path part for operation `usersSummaryGetUsersSummaryData()` */
  static readonly UsersSummaryGetUsersSummaryDataPath = '/api/reports/users-summary-data';

  /**
   * Get agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryGetUsersSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersSummaryData$Response(
    params: {
      body: UsersSummaryFilterRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UsersSummaryGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.UsersSummaryGetUsersSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UsersSummaryGridResponse>;
      })
    );
  }

  /**
   * Get agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryGetUsersSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetUsersSummaryData(
    params: {
      body: UsersSummaryFilterRequest
    },
    context?: HttpContext
  ): Observable<UsersSummaryGridResponse> {
    return this.usersSummaryGetUsersSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<UsersSummaryGridResponse>): UsersSummaryGridResponse => r.body)
    );
  }

  /** Path part for operation `usersSummaryGetExport()` */
  static readonly UsersSummaryGetExportPath = '/api/reports/users-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSummaryGetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetExport$Response(
    params: {
      body: UsersSummaryExportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.UsersSummaryGetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSummaryGetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSummaryGetExport(
    params: {
      body: UsersSummaryExportRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.usersSummaryGetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `huntGroupsDashboardGetGroupsDashboardData()` */
  static readonly HuntGroupsDashboardGetGroupsDashboardDataPath = '/api/reports/hunt-groups-dashboard-data';

  /**
   * Get hunt groups dashboard data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `huntGroupsDashboardGetGroupsDashboardData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  huntGroupsDashboardGetGroupsDashboardData$Response(
    params: {
      body: GroupsDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupsTotalModel>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.HuntGroupsDashboardGetGroupsDashboardDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupsTotalModel>;
      })
    );
  }

  /**
   * Get hunt groups dashboard data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `huntGroupsDashboardGetGroupsDashboardData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  huntGroupsDashboardGetGroupsDashboardData(
    params: {
      body: GroupsDashboardDataRequest
    },
    context?: HttpContext
  ): Observable<GroupsTotalModel> {
    return this.huntGroupsDashboardGetGroupsDashboardData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsTotalModel>): GroupsTotalModel => r.body)
    );
  }

}
