/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { EnableSmsPhoneNumberRequest } from '../models/enable-sms-phone-number-request';
import { PhoneNumberModel } from '../models/phone-number-model';
import { PhoneNumbersExportRequest } from '../models/phone-numbers-export-request';
import { PhoneNumbersGridResponse } from '../models/phone-numbers-grid-response';
import { PhoneNumbersRequest } from '../models/phone-numbers-request';
import { SavePhoneNumbersRequest } from '../models/save-phone-numbers-request';
import { SmsAvailablePhoneNumbersRequest } from '../models/sms-available-phone-numbers-request';
import { SmsAvailablePhoneNumbersResponse } from '../models/sms-available-phone-numbers-response';
import { SmsProcessingPhoneNumbersRequest } from '../models/sms-processing-phone-numbers-request';
import { SmsProcessingPhoneNumbersResponse } from '../models/sms-processing-phone-numbers-response';

@Injectable({ providedIn: 'root' })
export class PhoneNumbersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `phoneNumbersGetPhoneNumberList()` */
  static readonly PhoneNumbersGetPhoneNumberListPath = '/api/phone-numbers/list';

  /**
   * Get all account phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetPhoneNumberList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetPhoneNumberList$Response(
    params: {
      body: PhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PhoneNumbersGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PhoneNumbersService.PhoneNumbersGetPhoneNumberListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PhoneNumbersGridResponse>;
      })
    );
  }

  /**
   * Get all account phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetPhoneNumberList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetPhoneNumberList(
    params: {
      body: PhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<PhoneNumbersGridResponse> {
    return this.phoneNumbersGetPhoneNumberList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PhoneNumbersGridResponse>): PhoneNumbersGridResponse => r.body)
    );
  }

  /** Path part for operation `phoneNumbersGetSmsAvailablePhoneNumbers()` */
  static readonly PhoneNumbersGetSmsAvailablePhoneNumbersPath = '/api/phone-numbers/sms-available-numbers';

  /**
   * Get all sms available phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetSmsAvailablePhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsAvailablePhoneNumbers$Response(
    params: {
      body: SmsAvailablePhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SmsAvailablePhoneNumbersResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PhoneNumbersService.PhoneNumbersGetSmsAvailablePhoneNumbersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsAvailablePhoneNumbersResponse>;
      })
    );
  }

  /**
   * Get all sms available phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetSmsAvailablePhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsAvailablePhoneNumbers(
    params: {
      body: SmsAvailablePhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<SmsAvailablePhoneNumbersResponse> {
    return this.phoneNumbersGetSmsAvailablePhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<SmsAvailablePhoneNumbersResponse>): SmsAvailablePhoneNumbersResponse => r.body)
    );
  }

  /** Path part for operation `phoneNumbersGetPhoneNumberById()` */
  static readonly PhoneNumbersGetPhoneNumberByIdPath = '/api/phone-numbers/phone-number/{id}';

  /**
   * Get account phone number details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetPhoneNumberById()` instead.
   *
   * This method doesn't expect any request body.
   */
  phoneNumbersGetPhoneNumberById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PhoneNumberModel>> {
    const rb = new RequestBuilder(this.rootUrl, PhoneNumbersService.PhoneNumbersGetPhoneNumberByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PhoneNumberModel>;
      })
    );
  }

  /**
   * Get account phone number details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetPhoneNumberById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  phoneNumbersGetPhoneNumberById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<PhoneNumberModel> {
    return this.phoneNumbersGetPhoneNumberById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PhoneNumberModel>): PhoneNumberModel => r.body)
    );
  }

  /** Path part for operation `phoneNumbersSavePhoneNumbers()` */
  static readonly PhoneNumbersSavePhoneNumbersPath = '/api/phone-numbers/save-phone-numbers';

  /**
   * Save account phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersSavePhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersSavePhoneNumbers$Response(
    params: {
      body: SavePhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PhoneNumbersService.PhoneNumbersSavePhoneNumbersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save account phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersSavePhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersSavePhoneNumbers(
    params: {
      body: SavePhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.phoneNumbersSavePhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `phoneNumbersEnableSmsNumberForUser()` */
  static readonly PhoneNumbersEnableSmsNumberForUserPath = '/api/phone-numbers/enable-sms-number';

  /**
   * Enable sms number for user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersEnableSmsNumberForUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersEnableSmsNumberForUser$Response(
    params: {
      body: EnableSmsPhoneNumberRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, PhoneNumbersService.PhoneNumbersEnableSmsNumberForUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Enable sms number for user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersEnableSmsNumberForUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersEnableSmsNumberForUser(
    params: {
      body: EnableSmsPhoneNumberRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.phoneNumbersEnableSmsNumberForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `phoneNumbersGetSmsProcessingPhoneNumbers()` */
  static readonly PhoneNumbersGetSmsProcessingPhoneNumbersPath = '/api/phone-numbers/sms-processing-numbers';

  /**
   * Get all sms processing phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersGetSmsProcessingPhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsProcessingPhoneNumbers$Response(
    params: {
      body: SmsProcessingPhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SmsProcessingPhoneNumbersResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PhoneNumbersService.PhoneNumbersGetSmsProcessingPhoneNumbersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsProcessingPhoneNumbersResponse>;
      })
    );
  }

  /**
   * Get all sms processing phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersGetSmsProcessingPhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersGetSmsProcessingPhoneNumbers(
    params: {
      body: SmsProcessingPhoneNumbersRequest
    },
    context?: HttpContext
  ): Observable<SmsProcessingPhoneNumbersResponse> {
    return this.phoneNumbersGetSmsProcessingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<SmsProcessingPhoneNumbersResponse>): SmsProcessingPhoneNumbersResponse => r.body)
    );
  }

  /** Path part for operation `phoneNumbersExportExportPhoneNumbers()` */
  static readonly PhoneNumbersExportExportPhoneNumbersPath = '/api/phone-numbers-export/export';

  /**
   * Export all account phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `phoneNumbersExportExportPhoneNumbers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersExportExportPhoneNumbers$Response(
    params: {
      body: PhoneNumbersExportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, PhoneNumbersService.PhoneNumbersExportExportPhoneNumbersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export all account phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `phoneNumbersExportExportPhoneNumbers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  phoneNumbersExportExportPhoneNumbers(
    params: {
      body: PhoneNumbersExportRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.phoneNumbersExportExportPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
