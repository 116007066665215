/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `healthControllerHealthCheck()` */
  static readonly HealthControllerHealthCheckPath = '/api/health';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthControllerHealthCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthControllerHealthCheck$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
'status'?: string;
'info'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'error'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'details'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
};
}>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.HealthControllerHealthCheckPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        'status'?: string;
        'info'?: {
        [key: string]: {
        'status': string;
        [key: string]: {
        };
        };
        } | null;
        'error'?: {
        [key: string]: {
        'status': string;
        [key: string]: {
        };
        };
        } | null;
        'details'?: {
        [key: string]: {
        'status': string;
        [key: string]: {
        };
        };
        };
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthControllerHealthCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthControllerHealthCheck(
    params?: {
    },
    context?: HttpContext
  ): Observable<{
'status'?: string;
'info'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'error'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'details'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
};
}> {
    return this.healthControllerHealthCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
'status'?: string;
'info'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'error'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'details'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
};
}>): {
'status'?: string;
'info'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'error'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
} | null;
'details'?: {
[key: string]: {
'status': string;
[key: string]: {
};
};
};
} => r.body)
    );
  }

}
