/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BooleanResponse } from '../models/boolean-response';
import { E911DetailsModel } from '../models/e-911-details-model';
import { E911DetailsRequest } from '../models/e-911-details-request';
import { PbxAccountGridResponse } from '../models/pbx-account-grid-response';
import { PbxAccountListRequest } from '../models/pbx-account-list-request';
import { PbxAccountModel } from '../models/pbx-account-model';

@Injectable({ providedIn: 'root' })
export class PbxAccountsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxAccountsGetList()` */
  static readonly PbxAccountsGetListPath = '/api/pbx-accounts/list';

  /**
   * Get List Of Accounts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsGetList$Response(
    params: {
      body: PbxAccountListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PbxAccountGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PbxAccountsService.PbxAccountsGetListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PbxAccountGridResponse>;
      })
    );
  }

  /**
   * Get List Of Accounts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsGetList(
    params: {
      body: PbxAccountListRequest
    },
    context?: HttpContext
  ): Observable<PbxAccountGridResponse> {
    return this.pbxAccountsGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxAccountGridResponse>): PbxAccountGridResponse => r.body)
    );
  }

  /** Path part for operation `pbxAccountsGetAccountDetails()` */
  static readonly PbxAccountsGetAccountDetailsPath = '/api/pbx-accounts/get/{id}';

  /**
   * Get Account Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsGetAccountDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetAccountDetails$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PbxAccountModel>> {
    const rb = new RequestBuilder(this.rootUrl, PbxAccountsService.PbxAccountsGetAccountDetailsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PbxAccountModel>;
      })
    );
  }

  /**
   * Get Account Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsGetAccountDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetAccountDetails(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<PbxAccountModel> {
    return this.pbxAccountsGetAccountDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxAccountModel>): PbxAccountModel => r.body)
    );
  }

  /** Path part for operation `pbxAccountsSaveAccountDetails()` */
  static readonly PbxAccountsSaveAccountDetailsPath = '/api/pbx-accounts/save';

  /**
   * Save Account Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsSaveAccountDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsSaveAccountDetails$Response(
    params: {
  
    /**
     * Save Account Details
     */
    body: PbxAccountModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PbxAccountModel>> {
    const rb = new RequestBuilder(this.rootUrl, PbxAccountsService.PbxAccountsSaveAccountDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PbxAccountModel>;
      })
    );
  }

  /**
   * Save Account Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsSaveAccountDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsSaveAccountDetails(
    params: {
  
    /**
     * Save Account Details
     */
    body: PbxAccountModel
    },
    context?: HttpContext
  ): Observable<PbxAccountModel> {
    return this.pbxAccountsSaveAccountDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PbxAccountModel>): PbxAccountModel => r.body)
    );
  }

  /** Path part for operation `pbxAccountsGetE911Addresses()` */
  static readonly PbxAccountsGetE911AddressesPath = '/api/pbx-accounts/get-e911-addresses';

  /**
   * Get E911 addresses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsGetE911Addresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetE911Addresses$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<E911DetailsModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PbxAccountsService.PbxAccountsGetE911AddressesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<E911DetailsModel>>;
      })
    );
  }

  /**
   * Get E911 addresses.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsGetE911Addresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxAccountsGetE911Addresses(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<E911DetailsModel>> {
    return this.pbxAccountsGetE911Addresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<E911DetailsModel>>): Array<E911DetailsModel> => r.body)
    );
  }

  /** Path part for operation `pbxAccountsRequestE911Addresses()` */
  static readonly PbxAccountsRequestE911AddressesPath = '/api/pbx-accounts/request-e911-address';

  /**
   * Request E911 address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxAccountsRequestE911Addresses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsRequestE911Addresses$Response(
    params: {
      body: E911DetailsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanResponse>> {
    const rb = new RequestBuilder(this.rootUrl, PbxAccountsService.PbxAccountsRequestE911AddressesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponse>;
      })
    );
  }

  /**
   * Request E911 address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxAccountsRequestE911Addresses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxAccountsRequestE911Addresses(
    params: {
      body: E911DetailsRequest
    },
    context?: HttpContext
  ): Observable<BooleanResponse> {
    return this.pbxAccountsRequestE911Addresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

}
