import {RouterModule} from '@angular/router';
import {appRoutes} from './app.routes';
import {NgModule} from '@angular/core';
import {SelectivePreloadingStrategyService} from './selective-preloading-strategy.service';

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        enableTracing: false,
        useHash: true,
        // preloadingStrategy: QuicklinkStrategy,
        // preloadingStrategy: PreloadAllModules,
        preloadingStrategy: SelectivePreloadingStrategyService,
        errorHandler: error => {
          console.error(error);
          const chunkFailedMessage = /Loading chunk [\d]+ failed/;
          if (chunkFailedMessage.test(error.message)) {
            window.location.reload();
          }
        }
      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    // CanDeactivateGuard,
    // SelectivePreloadingStrategy
  ]
})
export class AppRoutingModule {
}


