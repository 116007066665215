/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { FaxLogsRequest } from '../models/fax-logs-request';
import { FaxLogsResponse } from '../models/fax-logs-response';
import { FaxPhoneNumberModel } from '../models/fax-phone-number-model';
import { FaxReportRequest } from '../models/fax-report-request';
import { FaxSummaryReportByDayModel } from '../models/fax-summary-report-by-day-model';
import { FaxSummaryReportModel } from '../models/fax-summary-report-model';
import { FaxTopNumbersReportModel } from '../models/fax-top-numbers-report-model';

@Injectable({ providedIn: 'root' })
export class FaxReportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faxReportSummaryReport()` */
  static readonly FaxReportSummaryReportPath = '/api/fax-report/reports/summary';

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReport$Response(
    params: {
      body: FaxReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FaxSummaryReportModel>> {
    const rb = new RequestBuilder(this.rootUrl, FaxReportService.FaxReportSummaryReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FaxSummaryReportModel>;
      })
    );
  }

  /**
   * Summary Report.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReport(
    params: {
      body: FaxReportRequest
    },
    context?: HttpContext
  ): Observable<FaxSummaryReportModel> {
    return this.faxReportSummaryReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaxSummaryReportModel>): FaxSummaryReportModel => r.body)
    );
  }

  /** Path part for operation `faxReportSummaryReportByDay()` */
  static readonly FaxReportSummaryReportByDayPath = '/api/fax-report/reports/summary-by-day';

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportSummaryReportByDay()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReportByDay$Response(
    params: {
      body: FaxReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FaxSummaryReportByDayModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FaxReportService.FaxReportSummaryReportByDayPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FaxSummaryReportByDayModel>>;
      })
    );
  }

  /**
   * Summary Report By Day.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportSummaryReportByDay$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportSummaryReportByDay(
    params: {
      body: FaxReportRequest
    },
    context?: HttpContext
  ): Observable<Array<FaxSummaryReportByDayModel>> {
    return this.faxReportSummaryReportByDay$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxSummaryReportByDayModel>>): Array<FaxSummaryReportByDayModel> => r.body)
    );
  }

  /** Path part for operation `faxReportGetTopNumbersReport()` */
  static readonly FaxReportGetTopNumbersReportPath = '/api/fax-report/reports/top-numbers';

  /**
   * Get top fax phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportGetTopNumbersReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetTopNumbersReport$Response(
    params: {
      body: FaxReportRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FaxTopNumbersReportModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FaxReportService.FaxReportGetTopNumbersReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FaxTopNumbersReportModel>>;
      })
    );
  }

  /**
   * Get top fax phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportGetTopNumbersReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetTopNumbersReport(
    params: {
      body: FaxReportRequest
    },
    context?: HttpContext
  ): Observable<Array<FaxTopNumbersReportModel>> {
    return this.faxReportGetTopNumbersReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxTopNumbersReportModel>>): Array<FaxTopNumbersReportModel> => r.body)
    );
  }

  /** Path part for operation `faxReportGetLogs()` */
  static readonly FaxReportGetLogsPath = '/api/fax-report/reports/logs';

  /**
   * Get logs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportGetLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetLogs$Response(
    params: {
      body: FaxLogsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FaxLogsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, FaxReportService.FaxReportGetLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FaxLogsResponse>;
      })
    );
  }

  /**
   * Get logs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportGetLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxReportGetLogs(
    params: {
      body: FaxLogsRequest
    },
    context?: HttpContext
  ): Observable<FaxLogsResponse> {
    return this.faxReportGetLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaxLogsResponse>): FaxLogsResponse => r.body)
    );
  }

  /** Path part for operation `faxReportGetFaxPhoneNumbers()` */
  static readonly FaxReportGetFaxPhoneNumbersPath = '/api/fax-report/reports/fax-phone-numbers';

  /**
   * Get fax phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxReportGetFaxPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxReportGetFaxPhoneNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FaxReportService.FaxReportGetFaxPhoneNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FaxPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get fax phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxReportGetFaxPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxReportGetFaxPhoneNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxReportGetFaxPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

}
