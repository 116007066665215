/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CallLogModel } from '../models/call-log-model';
import { CallLogRequest } from '../models/call-log-request';
import { CallLogsRequest } from '../models/call-logs-request';
import { CallLogsResponse } from '../models/call-logs-response';
import { ExportCallLogsRequest } from '../models/export-call-logs-request';
import { RawCallLogRequest } from '../models/raw-call-log-request';
import { RawCallModel } from '../models/raw-call-model';

@Injectable({ providedIn: 'root' })
export class CallLogsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `callLogsV2GetCallLogs()` */
  static readonly CallLogsV2GetCallLogsPath = '/api/reports-v2/call-logs';

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallLogs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs$Response(
    params: {
      body: CallLogsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CallLogsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, CallLogsService.CallLogsV2GetCallLogsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CallLogsResponse>;
      })
    );
  }

  /**
   * Get Call Logs data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallLogs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallLogs(
    params: {
      body: CallLogsRequest
    },
    context?: HttpContext
  ): Observable<CallLogsResponse> {
    return this.callLogsV2GetCallLogs$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogsResponse>): CallLogsResponse => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetExport()` */
  static readonly CallLogsV2GetExportPath = '/api/reports-v2/call-logs/export';

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport$Response(
    params: {
      body: ExportCallLogsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CallLogsService.CallLogsV2GetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Add Export Job to queue.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetExport(
    params: {
      body: ExportCallLogsRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.callLogsV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetRawCallDetails()` */
  static readonly CallLogsV2GetRawCallDetailsPath = '/api/reports-v2/raw-call-details';

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetRawCallDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails$Response(
    params: {
      body: RawCallLogRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RawCallModel>> {
    const rb = new RequestBuilder(this.rootUrl, CallLogsService.CallLogsV2GetRawCallDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RawCallModel>;
      })
    );
  }

  /**
   * Get Raw Call Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetRawCallDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetRawCallDetails(
    params: {
      body: RawCallLogRequest
    },
    context?: HttpContext
  ): Observable<RawCallModel> {
    return this.callLogsV2GetRawCallDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<RawCallModel>): RawCallModel => r.body)
    );
  }

  /** Path part for operation `callLogsV2GetCallFlowDetails()` */
  static readonly CallLogsV2GetCallFlowDetailsPath = '/api/reports-v2/call-flow-detail';

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `callLogsV2GetCallFlowDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails$Response(
    params: {
      body: CallLogRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CallLogModel>> {
    const rb = new RequestBuilder(this.rootUrl, CallLogsService.CallLogsV2GetCallFlowDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CallLogModel>;
      })
    );
  }

  /**
   * Get Call Flow Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `callLogsV2GetCallFlowDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  callLogsV2GetCallFlowDetails(
    params: {
      body: CallLogRequest
    },
    context?: HttpContext
  ): Observable<CallLogModel> {
    return this.callLogsV2GetCallFlowDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<CallLogModel>): CallLogModel => r.body)
    );
  }

}
