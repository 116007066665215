/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccountRoleModel } from '../models/account-role-model';
import { AccountRolesGridResponse } from '../models/account-roles-grid-response';
import { RolesGridRequest } from '../models/roles-grid-request';

@Injectable({ providedIn: 'root' })
export class RolesManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountRolesGetRoles()` */
  static readonly AccountRolesGetRolesPath = '/api/account/roles';

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles$Response(
    params: {
      body: RolesGridRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountRolesGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, RolesManagementService.AccountRolesGetRolesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRolesGridResponse>;
      })
    );
  }

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles(
    params: {
      body: RolesGridRequest
    },
    context?: HttpContext
  ): Observable<AccountRolesGridResponse> {
    return this.accountRolesGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRolesGridResponse>): AccountRolesGridResponse => r.body)
    );
  }

  /** Path part for operation `accountRolesGetRoleById()` */
  static readonly AccountRolesGetRoleByIdPath = '/api/account/role/{id}';

  /**
   * Get account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountRoleModel>> {
    const rb = new RequestBuilder(this.rootUrl, RolesManagementService.AccountRolesGetRoleByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRoleModel>;
      })
    );
  }

  /**
   * Get account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AccountRoleModel> {
    return this.accountRolesGetRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRoleModel>): AccountRoleModel => r.body)
    );
  }

  /** Path part for operation `accountRolesDeleteRoleById()` */
  static readonly AccountRolesDeleteRoleByIdPath = '/api/account/role/{id}';

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesDeleteRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RolesManagementService.AccountRolesDeleteRoleByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesDeleteRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountRolesDeleteRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountRolesSaveRole()` */
  static readonly AccountRolesSaveRolePath = '/api/account/role';

  /**
   * Save role details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesSaveRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole$Response(
    params: {
      body: AccountRoleModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, RolesManagementService.AccountRolesSaveRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save role details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesSaveRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole(
    params: {
      body: AccountRoleModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountRolesSaveRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
