/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BooleanResponse } from '../models/boolean-response';
import { BroadcastMessagesRequest } from '../models/broadcast-messages-request';
import { BroadcastMessagesResponse } from '../models/broadcast-messages-response';
import { BroadcastThreadContactsCountResponse } from '../models/broadcast-thread-contacts-count-response';
import { BroadcastThreadResponse } from '../models/broadcast-thread-response';
import { BroadcastThreadsRequest } from '../models/broadcast-threads-request';
import { BroadcastThreadsResponse } from '../models/broadcast-threads-response';
import { ContactTagLookupModel } from '../models/contact-tag-lookup-model';
import { FileUploadModel } from '../models/file-upload-model';
import { MediaModel } from '../models/media-model';
import { QueueBroadcastMessageV2Model } from '../models/queue-broadcast-message-v-2-model';
import { SaveBroadcastThreadRequest } from '../models/save-broadcast-thread-request';
import { SmsPhoneNumberModel } from '../models/sms-phone-number-model';
import { UnsubscribedListRequest } from '../models/unsubscribed-list-request';
import { UnsubscribedListResponse } from '../models/unsubscribed-list-response';

@Injectable({ providedIn: 'root' })
export class MessageBroadcastingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messageBroadcastingSendMessage()` */
  static readonly MessageBroadcastingSendMessagePath = '/api/message-broadcasting/send-broadcast';

  /**
   * Send Broadcast.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingSendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSendMessage$Response(
    params: {
      body: QueueBroadcastMessageV2Model
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingSendMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponse>;
      })
    );
  }

  /**
   * Send Broadcast.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingSendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSendMessage(
    params: {
      body: QueueBroadcastMessageV2Model
    },
    context?: HttpContext
  ): Observable<BooleanResponse> {
    return this.messageBroadcastingSendMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingUploadMedia()` */
  static readonly MessageBroadcastingUploadMediaPath = '/api/message-broadcasting/upload-media';

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingUploadMedia()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  messageBroadcastingUploadMedia$Response(
    params: {
  
    /**
     * MMS Attachment
     */
    body: FileUploadModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MediaModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingUploadMediaPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaModel>;
      })
    );
  }

  /**
   * Upload Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingUploadMedia$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  messageBroadcastingUploadMedia(
    params: {
  
    /**
     * MMS Attachment
     */
    body: FileUploadModel
    },
    context?: HttpContext
  ): Observable<MediaModel> {
    return this.messageBroadcastingUploadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediaModel>): MediaModel => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetMyMessagingPhoneNumbers()` */
  static readonly MessageBroadcastingGetMyMessagingPhoneNumbersPath = '/api/message-broadcasting/my-messaging-phone-numbers';

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetMyMessagingPhoneNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetMyMessagingPhoneNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<SmsPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingGetMyMessagingPhoneNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get my messaging phone numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetMyMessagingPhoneNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetMyMessagingPhoneNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<SmsPhoneNumberModel>> {
    return this.messageBroadcastingGetMyMessagingPhoneNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SmsPhoneNumberModel>>): Array<SmsPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingSaveBroadcastThread()` */
  static readonly MessageBroadcastingSaveBroadcastThreadPath = '/api/message-broadcasting/save-broadcast';

  /**
   * Save Broadcast Thread.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingSaveBroadcastThread()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSaveBroadcastThread$Response(
    params: {
      body: SaveBroadcastThreadRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingSaveBroadcastThreadPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponse>;
      })
    );
  }

  /**
   * Save Broadcast Thread.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingSaveBroadcastThread$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingSaveBroadcastThread(
    params: {
      body: SaveBroadcastThreadRequest
    },
    context?: HttpContext
  ): Observable<BooleanResponse> {
    return this.messageBroadcastingSaveBroadcastThread$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThreads()` */
  static readonly MessageBroadcastingGetBroadcastThreadsPath = '/api/message-broadcasting/threads/{phoneNumber}';

  /**
   * Get broadcasting groups.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThreads()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreads$Response(
    params: {
      phoneNumber: string;
      body: BroadcastThreadsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BroadcastThreadsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingGetBroadcastThreadsPath, 'post');
    if (params) {
      rb.path('phoneNumber', params.phoneNumber, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BroadcastThreadsResponse>;
      })
    );
  }

  /**
   * Get broadcasting groups.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThreads$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreads(
    params: {
      phoneNumber: string;
      body: BroadcastThreadsRequest
    },
    context?: HttpContext
  ): Observable<BroadcastThreadsResponse> {
    return this.messageBroadcastingGetBroadcastThreads$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastThreadsResponse>): BroadcastThreadsResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThread()` */
  static readonly MessageBroadcastingGetBroadcastThreadPath = '/api/message-broadcasting/broadcast/{id}';

  /**
   * Get broadcasting group.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThread()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThread$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BroadcastThreadResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingGetBroadcastThreadPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BroadcastThreadResponse>;
      })
    );
  }

  /**
   * Get broadcasting group.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThread$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThread(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<BroadcastThreadResponse> {
    return this.messageBroadcastingGetBroadcastThread$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastThreadResponse>): BroadcastThreadResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThreadContactsCount()` */
  static readonly MessageBroadcastingGetBroadcastThreadContactsCountPath = '/api/message-broadcasting/broadcast-contacts-count/{id}';

  /**
   * Get broadcasting contacts count.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThreadContactsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThreadContactsCount$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BroadcastThreadContactsCountResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingGetBroadcastThreadContactsCountPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BroadcastThreadContactsCountResponse>;
      })
    );
  }

  /**
   * Get broadcasting contacts count.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThreadContactsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingGetBroadcastThreadContactsCount(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<BroadcastThreadContactsCountResponse> {
    return this.messageBroadcastingGetBroadcastThreadContactsCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastThreadContactsCountResponse>): BroadcastThreadContactsCountResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetBroadcastThreadMessages()` */
  static readonly MessageBroadcastingGetBroadcastThreadMessagesPath = '/api/message-broadcasting/get-messages';

  /**
   * Get broadcast summary messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetBroadcastThreadMessages()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreadMessages$Response(
    params: {
      body: BroadcastMessagesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BroadcastMessagesResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingGetBroadcastThreadMessagesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BroadcastMessagesResponse>;
      })
    );
  }

  /**
   * Get broadcast summary messages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetBroadcastThreadMessages$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetBroadcastThreadMessages(
    params: {
      body: BroadcastMessagesRequest
    },
    context?: HttpContext
  ): Observable<BroadcastMessagesResponse> {
    return this.messageBroadcastingGetBroadcastThreadMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<BroadcastMessagesResponse>): BroadcastMessagesResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingDeleteBroadcastById()` */
  static readonly MessageBroadcastingDeleteBroadcastByIdPath = '/api/message-broadcasting/broadcast/{broadcastId}';

  /**
   * Delete Broadcast.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingDeleteBroadcastById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDeleteBroadcastById$Response(
    params: {
      broadcastId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingDeleteBroadcastByIdPath, 'delete');
    if (params) {
      rb.path('broadcastId', params.broadcastId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete Broadcast.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingDeleteBroadcastById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDeleteBroadcastById(
    params: {
      broadcastId: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.messageBroadcastingDeleteBroadcastById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingGetUnsubscribedNumbersList()` */
  static readonly MessageBroadcastingGetUnsubscribedNumbersListPath = '/api/message-broadcasting/unsubscribe-list';

  /**
   * Get unsbscribed numbers list.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingGetUnsubscribedNumbersList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetUnsubscribedNumbersList$Response(
    params: {
      body: UnsubscribedListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UnsubscribedListResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingGetUnsubscribedNumbersListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UnsubscribedListResponse>;
      })
    );
  }

  /**
   * Get unsbscribed numbers list.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingGetUnsubscribedNumbersList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messageBroadcastingGetUnsubscribedNumbersList(
    params: {
      body: UnsubscribedListRequest
    },
    context?: HttpContext
  ): Observable<UnsubscribedListResponse> {
    return this.messageBroadcastingGetUnsubscribedNumbersList$Response(params, context).pipe(
      map((r: StrictHttpResponse<UnsubscribedListResponse>): UnsubscribedListResponse => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingPinConversationByGroup()` */
  static readonly MessageBroadcastingPinConversationByGroupPath = '/api/message-broadcasting/pin-conversation/{groupKey}';

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingPinConversationByGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingPinConversationByGroup$Response(
    params: {
      groupKey: string;
      pin: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingPinConversationByGroupPath, 'get');
    if (params) {
      rb.path('groupKey', params.groupKey, {});
      rb.query('pin', params.pin, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Pin Conversation.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingPinConversationByGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingPinConversationByGroup(
    params: {
      groupKey: string;
      pin: boolean;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.messageBroadcastingPinConversationByGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingLookupContactsAndTags()` */
  static readonly MessageBroadcastingLookupContactsAndTagsPath = '/api/message-broadcasting/lookup-contacts';

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingLookupContactsAndTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingLookupContactsAndTags$Response(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ContactTagLookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingLookupContactsAndTagsPath, 'get');
    if (params) {
      rb.query('q', params.q, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ContactTagLookupModel>>;
      })
    );
  }

  /**
   * Lookup contacts and tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingLookupContactsAndTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingLookupContactsAndTags(
    params: {
      q: string;
    },
    context?: HttpContext
  ): Observable<Array<ContactTagLookupModel>> {
    return this.messageBroadcastingLookupContactsAndTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTagLookupModel>>): Array<ContactTagLookupModel> => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingStreamMedia()` */
  static readonly MessageBroadcastingStreamMediaPath = '/api/message-broadcasting/stream-media/*';

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingStreamMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingStreamMedia$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingStreamMediaPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Stream Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingStreamMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingStreamMedia(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.messageBroadcastingStreamMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `messageBroadcastingDownloadMedia()` */
  static readonly MessageBroadcastingDownloadMediaPath = '/api/message-broadcasting/download-media/*';

  /**
   * Download Media.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messageBroadcastingDownloadMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDownloadMedia$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, MessageBroadcastingService.MessageBroadcastingDownloadMediaPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download Media.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messageBroadcastingDownloadMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messageBroadcastingDownloadMedia(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.messageBroadcastingDownloadMedia$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
