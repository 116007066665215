import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReadOnlyInputComponent} from './read-only-input/read-only-input.component';
import {Angular2PromiseButtonModule} from 'angular2-promise-buttons';
import {CountToDirective} from './count-to-directive/count-to.directive';
import {DurationInputComponent} from './duration-input/duration-input.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CoreModule} from '../core/core.module';
import {PhoneNumberInputComponent} from './phone-number-input/phone-number-input.component';
import {ExtensionsSelectService} from './extensions-select.service';
import {EmailListInputComponent} from './email-list-input/email-list-input.component';
import {DynamicFormComponent} from './dynamic-form/dynamic-form.component';
import {ColorInputComponent} from './color-input/color-input.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTextInputComponent} from './mat-text-input/mat-text-input.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteComponent} from './mat-autocomplete-input/mat-autocomplete.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatCheckboxInputComponent} from './mat-checkbox-input/mat-checkbox-input.component';
import {MatMultiselectComponent} from './mat-multiselect-input/mat-multiselect.component';
import {MatPhoneNumberInputComponent} from './mat-phone-number-input/mat-phone-number-input.component';
import {MatSimpleSelectComponent} from './mat-simple-select/mat-simple-select.component';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatBaseInputComponent} from './mat-base-input.component';
import {MatNumberInputComponent} from './mat-number-input/mat-number-input.component';
import {MatPasswordInputComponent} from './mat-password-input/mat-password-input.component';
import {MatDateInputComponent} from './mat-date-input/mat-date-input.component';
import {MatTextareaInputComponent} from './mat-textarea-input/mat-textarea-input.component';
import {MatTagsMultiselectComponent} from './mat-tags-multiselect/mat-tags-multiselect.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDatetimeInputComponent} from './mat-datetime-input/mat-datetime-input.component';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {MatRruleFormGroupComponent} from './mat-rrule-form-group/mat-rrule-form-group.component';
import {MatRadioModule} from '@angular/material/radio';
import {FlexModule} from '@angular/flex-layout';
import {MatRecipientsMultiselectComponent} from './mat-recipients-multiselect/mat-recipients-multiselect.component';
import {MatTimeInputComponent} from './mat-time-input/mat-time-input.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPbxAccountsSelectDialogComponent} from './mat-pbx-account-select-dialogs/mat-pbx-accounts-select-dialog.component';
import {UploadFaxComponent} from './upload-fax/upload-fax.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {MtxDatetimepickerModule} from '@ng-matero/extensions/datetimepicker';
import {MtxMomentDatetimeModule} from '@ng-matero/extensions-moment-adapter';
import {MatSlideToggleInputComponent} from './mat-slide-toggle-input/mat-slide-toggle-input.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSelectFix} from './mat-select/mat-select-fix';
import {MatFormFieldFix} from './mat-form-field/mat-form-field-fix';

@NgModule({
  imports: [
    ColorPickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    Angular2PromiseButtonModule.forRoot({
      // your custom config goes here
      spinnerTpl: '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>',
      // disable buttons when promise is pending
      disableBtn: true,
      // the class used to indicate a pending promise
      btnLoadingClass: 'is-loading',
      // even when they share the same promise
      handleCurrentBtnOnly: false,
    }),
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatRadioModule,
    FlexModule,
    MtxMomentDatetimeModule,
    MtxDatetimepickerModule,
    MatDialogModule,
    PdfViewerModule,
    MatSlideToggleModule,
  ],
  providers: [
    ExtensionsSelectService,
  ],
  declarations: [
    DurationInputComponent,
    CountToDirective,
    ReadOnlyInputComponent,
    PhoneNumberInputComponent,
    EmailListInputComponent,
    DynamicFormComponent,
    ColorInputComponent,
    MatBaseInputComponent,
    MatAutocompleteComponent,
    MatMultiselectComponent,
    MatTextInputComponent,
    MatTextareaInputComponent,
    MatTagsMultiselectComponent,
    MatDateInputComponent,
    MatDatetimeInputComponent,
    MatNumberInputComponent,
    MatPasswordInputComponent,
    MatCheckboxInputComponent,
    MatPhoneNumberInputComponent,
    MatSimpleSelectComponent,
    MatRruleFormGroupComponent,
    MatRecipientsMultiselectComponent,
    MatTimeInputComponent,
    MatPbxAccountsSelectDialogComponent,
    UploadFaxComponent,
    MatSlideToggleInputComponent,
    MatSelectFix,
    MatFormFieldFix,
  ],
  exports: [
    DurationInputComponent,
    Angular2PromiseButtonModule,
    CountToDirective,
    ReadOnlyInputComponent,
    PhoneNumberInputComponent,
    EmailListInputComponent,
    DynamicFormComponent,
    ColorInputComponent,
    MatBaseInputComponent,
    MatMultiselectComponent,
    MatAutocompleteComponent,
    MatTextInputComponent,
    MatTextareaInputComponent,
    MatTagsMultiselectComponent,
    MatDateInputComponent,
    MatDatetimeInputComponent,
    MatNumberInputComponent,
    MatPasswordInputComponent,
    MatCheckboxInputComponent,
    MatPhoneNumberInputComponent,
    MatSimpleSelectComponent,
    MatRruleFormGroupComponent,
    MatRecipientsMultiselectComponent,
    MatTimeInputComponent,
    MatPbxAccountsSelectDialogComponent,
    UploadFaxComponent,
    MatSlideToggleInputComponent,
    MatSelectFix,
    MatFormFieldFix,
  ]
})
export class EditorsModule {
}
