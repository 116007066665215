/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ExportGroupAgentsSummaryRequest } from '../models/export-group-agents-summary-request';
import { ExportGroupsSummaryRequest } from '../models/export-groups-summary-request';
import { GroupAgentsSummaryRequest } from '../models/group-agents-summary-request';
import { GroupAgentsSummaryResponse } from '../models/group-agents-summary-response';
import { GroupsSummaryRequest } from '../models/groups-summary-request';
import { GroupsSummaryResponse } from '../models/groups-summary-response';

@Injectable({ providedIn: 'root' })
export class GroupsReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `groupsSummaryV2GetSummaryData()` */
  static readonly GroupsSummaryV2GetSummaryDataPath = '/api/reports-v2/groups-summary-data';

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData$Response(
    params: {
      body: GroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupsSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, GroupsReportsService.GroupsSummaryV2GetSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupsSummaryResponse>;
      })
    );
  }

  /**
   * Get groups summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetSummaryData(
    params: {
      body: GroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<GroupsSummaryResponse> {
    return this.groupsSummaryV2GetSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupsSummaryResponse>): GroupsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetAgentsSummaryData()` */
  static readonly GroupsSummaryV2GetAgentsSummaryDataPath = '/api/reports-v2/groups-agent-summary-data';

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetAgentsSummaryData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData$Response(
    params: {
      body: GroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GroupAgentsSummaryResponse>> {
    const rb = new RequestBuilder(this.rootUrl, GroupsReportsService.GroupsSummaryV2GetAgentsSummaryDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GroupAgentsSummaryResponse>;
      })
    );
  }

  /**
   * Get groups agents summary data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetAgentsSummaryData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetAgentsSummaryData(
    params: {
      body: GroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<GroupAgentsSummaryResponse> {
    return this.groupsSummaryV2GetAgentsSummaryData$Response(params, context).pipe(
      map((r: StrictHttpResponse<GroupAgentsSummaryResponse>): GroupAgentsSummaryResponse => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExport()` */
  static readonly GroupsSummaryV2GetExportPath = '/api/reports-v2/groups-summary/export';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport$Response(
    params: {
      body: ExportGroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GroupsReportsService.GroupsSummaryV2GetExportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExport(
    params: {
      body: ExportGroupsSummaryRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.groupsSummaryV2GetExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `groupsSummaryV2GetExportAgents()` */
  static readonly GroupsSummaryV2GetExportAgentsPath = '/api/reports-v2/groups-summary/export-agents';

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `groupsSummaryV2GetExportAgents()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents$Response(
    params: {
      body: ExportGroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, GroupsReportsService.GroupsSummaryV2GetExportAgentsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Export to excel file base64 format.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `groupsSummaryV2GetExportAgents$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  groupsSummaryV2GetExportAgents(
    params: {
      body: ExportGroupAgentsSummaryRequest
    },
    context?: HttpContext
  ): Observable<string> {
    return this.groupsSummaryV2GetExportAgents$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
