/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CloudFileModel } from '../models/cloud-file-model';
import { HolidayGreetingsGridResponse } from '../models/holiday-greetings-grid-response';
import { HolidayGreetingsListRequest } from '../models/holiday-greetings-list-request';
import { HolidayGreetingsModel } from '../models/holiday-greetings-model';
import { LookupModel } from '../models/lookup-model';
import { TextToSpeechRequest } from '../models/text-to-speech-request';
import { TextToSpeechResponse } from '../models/text-to-speech-response';

@Injectable({ providedIn: 'root' })
export class HolidayGreetingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pbxHolidayGreetingsGetList()` */
  static readonly PbxHolidayGreetingsGetListPath = '/api/holiday-greetings/list';

  /**
   * Get List Of Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetList$Response(
    params: {
      body: HolidayGreetingsListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HolidayGreetingsGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsGetListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayGreetingsGridResponse>;
      })
    );
  }

  /**
   * Get List Of Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetList(
    params: {
      body: HolidayGreetingsListRequest
    },
    context?: HttpContext
  ): Observable<HolidayGreetingsGridResponse> {
    return this.pbxHolidayGreetingsGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<HolidayGreetingsGridResponse>): HolidayGreetingsGridResponse => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetHolidayGreetingsDetails()` */
  static readonly PbxHolidayGreetingsGetHolidayGreetingsDetailsPath = '/api/holiday-greetings/get/{account}';

  /**
   * Get Holiday Greetings Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetHolidayGreetingsDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetHolidayGreetingsDetails$Response(
    params: {
      account: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<HolidayGreetingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsGetHolidayGreetingsDetailsPath, 'get');
    if (params) {
      rb.path('account', params.account, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<HolidayGreetingsModel>;
      })
    );
  }

  /**
   * Get Holiday Greetings Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetHolidayGreetingsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetHolidayGreetingsDetails(
    params: {
      account: string;
    },
    context?: HttpContext
  ): Observable<HolidayGreetingsModel> {
    return this.pbxHolidayGreetingsGetHolidayGreetingsDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<HolidayGreetingsModel>): HolidayGreetingsModel => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsSaveHolidayGreetingDetails()` */
  static readonly PbxHolidayGreetingsSaveHolidayGreetingDetailsPath = '/api/holiday-greetings/save';

  /**
   * Save Holiday Greetings Details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsSaveHolidayGreetingDetails()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  pbxHolidayGreetingsSaveHolidayGreetingDetails$Response(
    params: {
  
    /**
     * Save Holiday Greetings Details
     */
    body: HolidayGreetingsModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsSaveHolidayGreetingDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save Holiday Greetings Details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsSaveHolidayGreetingDetails$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  pbxHolidayGreetingsSaveHolidayGreetingDetails(
    params: {
  
    /**
     * Save Holiday Greetings Details
     */
    body: HolidayGreetingsModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.pbxHolidayGreetingsSaveHolidayGreetingDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsStreamRecording()` */
  static readonly PbxHolidayGreetingsStreamRecordingPath = '/api/holiday-greetings/audio/stream/{account}';

  /**
   * Stream Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsStreamRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamRecording$Response(
    params: {
      account: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsStreamRecordingPath, 'get');
    if (params) {
      rb.path('account', params.account, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Stream Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsStreamRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamRecording(
    params: {
      account: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.pbxHolidayGreetingsStreamRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsDownloadRecording()` */
  static readonly PbxHolidayGreetingsDownloadRecordingPath = '/api/holiday-greetings/audio/download/{account}';

  /**
   * Download Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsDownloadRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsDownloadRecording$Response(
    params: {
      account: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsDownloadRecordingPath, 'get');
    if (params) {
      rb.path('account', params.account, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsDownloadRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsDownloadRecording(
    params: {
      account: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.pbxHolidayGreetingsDownloadRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetAvailableHolidayGreetings()` */
  static readonly PbxHolidayGreetingsGetAvailableHolidayGreetingsPath = '/api/holiday-greetings/available-holiday-greetings';

  /**
   * Get list of available Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetAvailableHolidayGreetings()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetAvailableHolidayGreetings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CloudFileModel>>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsGetAvailableHolidayGreetingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CloudFileModel>>;
      })
    );
  }

  /**
   * Get list of available Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetAvailableHolidayGreetings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetAvailableHolidayGreetings(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CloudFileModel>> {
    return this.pbxHolidayGreetingsGetAvailableHolidayGreetings$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CloudFileModel>>): Array<CloudFileModel> => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsStreamPublicRecording()` */
  static readonly PbxHolidayGreetingsStreamPublicRecordingPath = '/api/holiday-greetings/pre-audio/stream/{id}';

  /**
   * Stream Predefined Holiday Greetings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsStreamPublicRecording()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamPublicRecording$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsStreamPublicRecordingPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Stream Predefined Holiday Greetings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsStreamPublicRecording$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsStreamPublicRecording(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.pbxHolidayGreetingsStreamPublicRecording$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetTextToSpeechOptions()` */
  static readonly PbxHolidayGreetingsGetTextToSpeechOptionsPath = '/api/holiday-greetings/text-to-speech-options';

  /**
   * Get Text To Speech Options.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetTextToSpeechOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetTextToSpeechOptions$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<LookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsGetTextToSpeechOptionsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LookupModel>>;
      })
    );
  }

  /**
   * Get Text To Speech Options.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetTextToSpeechOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pbxHolidayGreetingsGetTextToSpeechOptions(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<LookupModel>> {
    return this.pbxHolidayGreetingsGetTextToSpeechOptions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `pbxHolidayGreetingsGetTextToSpeechAudioStream()` */
  static readonly PbxHolidayGreetingsGetTextToSpeechAudioStreamPath = '/api/holiday-greetings/text-to-speech';

  /**
   * Get Text To Speech Audio Data.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pbxHolidayGreetingsGetTextToSpeechAudioStream()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetTextToSpeechAudioStream$Response(
    params: {
      body: TextToSpeechRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TextToSpeechResponse>> {
    const rb = new RequestBuilder(this.rootUrl, HolidayGreetingsService.PbxHolidayGreetingsGetTextToSpeechAudioStreamPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TextToSpeechResponse>;
      })
    );
  }

  /**
   * Get Text To Speech Audio Data.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pbxHolidayGreetingsGetTextToSpeechAudioStream$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  pbxHolidayGreetingsGetTextToSpeechAudioStream(
    params: {
      body: TextToSpeechRequest
    },
    context?: HttpContext
  ): Observable<TextToSpeechResponse> {
    return this.pbxHolidayGreetingsGetTextToSpeechAudioStream$Response(params, context).pipe(
      map((r: StrictHttpResponse<TextToSpeechResponse>): TextToSpeechResponse => r.body)
    );
  }

}
