/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { FaxGridResponse } from '../models/fax-grid-response';
import { FaxPhoneNumberModel } from '../models/fax-phone-number-model';
import { FaxesRequest } from '../models/faxes-request';
import { RetryFaxModel } from '../models/retry-fax-model';
import { TwoFilesUploadModel } from '../models/two-files-upload-model';
import { UpdateFaxNotesRequest } from '../models/update-fax-notes-request';

@Injectable({ providedIn: 'root' })
export class FaxService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faxGetMyIncomingFaxNumbers()` */
  static readonly FaxGetMyIncomingFaxNumbersPath = '/api/fax/my-incoming-fax-numbers';

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetMyIncomingFaxNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyIncomingFaxNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxGetMyIncomingFaxNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FaxPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetMyIncomingFaxNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyIncomingFaxNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxGetMyIncomingFaxNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `faxGetMyOutgoingFaxNumbers()` */
  static readonly FaxGetMyOutgoingFaxNumbersPath = '/api/fax/my-outgoing-fax-numbers';

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetMyOutgoingFaxNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyOutgoingFaxNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxGetMyOutgoingFaxNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FaxPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetMyOutgoingFaxNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyOutgoingFaxNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxGetMyOutgoingFaxNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `faxGetMyAllFaxNumbers()` */
  static readonly FaxGetMyAllFaxNumbersPath = '/api/fax/my-all-fax-numbers';

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetMyAllFaxNumbers()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyAllFaxNumbers$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<FaxPhoneNumberModel>>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxGetMyAllFaxNumbersPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FaxPhoneNumberModel>>;
      })
    );
  }

  /**
   * Get my incoming fax numbers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetMyAllFaxNumbers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxGetMyAllFaxNumbers(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<FaxPhoneNumberModel>> {
    return this.faxGetMyAllFaxNumbers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FaxPhoneNumberModel>>): Array<FaxPhoneNumberModel> => r.body)
    );
  }

  /** Path part for operation `faxGetGetIncomingFaxes()` */
  static readonly FaxGetGetIncomingFaxesPath = '/api/fax/get-faxes';

  /**
   * Get incoming/outgoing faxes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxGetGetIncomingFaxes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxGetGetIncomingFaxes$Response(
    params: {
      body: FaxesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<FaxGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxGetGetIncomingFaxesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FaxGridResponse>;
      })
    );
  }

  /**
   * Get incoming/outgoing faxes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxGetGetIncomingFaxes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxGetGetIncomingFaxes(
    params: {
      body: FaxesRequest
    },
    context?: HttpContext
  ): Observable<FaxGridResponse> {
    return this.faxGetGetIncomingFaxes$Response(params, context).pipe(
      map((r: StrictHttpResponse<FaxGridResponse>): FaxGridResponse => r.body)
    );
  }

  /** Path part for operation `faxPreviewFax()` */
  static readonly FaxPreviewFaxPath = '/api/fax/preview-fax/{callerId}/{id}';

  /**
   * Preview Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxPreviewFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxPreviewFax$Response(
    params: {
      id: string;
      callerId: string;
      second: boolean;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxPreviewFaxPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('callerId', params.callerId, {});
      rb.query('second', params.second, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Preview Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxPreviewFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxPreviewFax(
    params: {
      id: string;
      callerId: string;
      second: boolean;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.faxPreviewFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxDownloadFax()` */
  static readonly FaxDownloadFaxPath = '/api/fax/download-fax/{callerId}/{id}';

  /**
   * Download Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxDownloadFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDownloadFax$Response(
    params: {
      callerId: string;
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxDownloadFaxPath, 'get');
    if (params) {
      rb.path('callerId', params.callerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxDownloadFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDownloadFax(
    params: {
      callerId: string;
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.faxDownloadFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxDeleteFax()` */
  static readonly FaxDeleteFaxPath = '/api/fax/fax/{callerId}/{id}';

  /**
   * Delete Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxDeleteFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDeleteFax$Response(
    params: {
      callerId: string;
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxDeleteFaxPath, 'delete');
    if (params) {
      rb.path('callerId', params.callerId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxDeleteFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faxDeleteFax(
    params: {
      callerId: string;
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.faxDeleteFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxSendFax()` */
  static readonly FaxSendFaxPath = '/api/fax/send-fax';

  /**
   * Send Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxSendFax()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  faxSendFax$Response(
    params: {
  
    /**
     * Fax Attachments
     */
    body: TwoFilesUploadModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxSendFaxPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Send Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxSendFax$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  faxSendFax(
    params: {
  
    /**
     * Fax Attachments
     */
    body: TwoFilesUploadModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.faxSendFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxRetryFax()` */
  static readonly FaxRetryFaxPath = '/api/fax/retry-fax';

  /**
   * Retry Failed Fax.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxRetryFax()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxRetryFax$Response(
    params: {
  
    /**
     * Retry Fax Model
     */
    body: RetryFaxModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxRetryFaxPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Retry Failed Fax.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxRetryFax$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxRetryFax(
    params: {
  
    /**
     * Retry Fax Model
     */
    body: RetryFaxModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.faxRetryFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `faxUpdateFaxNotes()` */
  static readonly FaxUpdateFaxNotesPath = '/api/fax/update-fax-notes';

  /**
   * Update Fax Internal Notes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faxUpdateFaxNotes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxUpdateFaxNotes$Response(
    params: {
  
    /**
     * Fax Internal Notes Model
     */
    body: UpdateFaxNotesRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, FaxService.FaxUpdateFaxNotesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update Fax Internal Notes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faxUpdateFaxNotes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  faxUpdateFaxNotes(
    params: {
  
    /**
     * Fax Internal Notes Model
     */
    body: UpdateFaxNotesRequest
    },
    context?: HttpContext
  ): Observable<void> {
    return this.faxUpdateFaxNotes$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
