import {RouteHelperItem} from '../utilities/router-helper';

export const routes: RouteHelperItem[] = [
  {
    'key': 'LoginComponent',
    'path': '/auth/login',
    'data': {
      'meta': {
        'title': 'Login',
        'override': true,
        'description': 'Login Page'
      }
    }
  },
  {
    'key': 'ResetPasswordComponent',
    'path': '/auth/reset-password',
    'data': {
      'meta': {
        'title': 'Reset Password',
        'override': true,
        'description': 'Reset Password Page'
      }
    }
  },
  {
    'key': 'CreatePasswordComponent',
    'path': '/auth/create-password',
    'data': {
      'meta': {
        'title': 'Create Password',
        'override': true,
        'description': 'Create Password Page'
      }
    }
  },
  {
    'key': 'AuthErrorComponent',
    'path': '/auth/error',
    'data': {
      'meta': {
        'title': 'Authentication Error',
        'override': true,
        'description': 'Authentication Error Page'
      }
    }
  },
  {
    'key': 'HomeComponent',
    'path': '/home',
    'data': {
      'meta': {
        'title': 'Home',
        'override': true,
        'description': 'Home Page'
      }
    }
  },
  {
    'key': 'ForbiddenComponent',
    'path': '',
    'data': {}
  },
  {
    'key': 'ProfileComponent',
    'path': '/account/profile',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'UsersController',
      'permissions': [
        'AccountProfile.View',
        'AccountProfile.Edit'
      ],
      'meta': {
        'title': 'Profile',
        'override': true,
        'description': 'User Profile Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'person'
      }
    }
  },
  {
    'key': 'ProfileEditComponent',
    'path': '/account/profile/edit',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'UsersController',
      'permissions': [
        'AccountProfile.Edit'
      ],
      'meta': {
        'title': 'Edit Profile',
        'override': true,
        'description': 'Edit User Profile Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'person'
      }
    }
  },
  {
    'key': 'UsersComponent',
    'path': '/account/users',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountUsersController',
      'permissions': [
        'AccountUsers.View',
        'AccountUsers.Edit',
        'AccountUsers.Delete'
      ],
      'meta': {
        'title': 'Users',
        'override': true,
        'description': 'Users Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'person'
      }
    }
  },
  {
    'key': 'UserComponent',
    'path': '/account/user',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountUsersController',
      'permissions': [
        'AccountUsers.View',
        'AccountUsers.Edit',
        'AccountUsers.Delete'
      ],
      'meta': {
        'title': 'User Details',
        'override': true,
        'description': 'User Details Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'person'
      }
    }
  },
  {
    'key': 'UserEditComponent',
    'path': '/account/user/edit',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountUsersController',
      'permissions': [
        'AccountUsers.Edit',
        'AccountUsers.Delete'
      ],
      'meta': {
        'title': 'Edit User Details',
        'override': true,
        'description': 'Edit User Details Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'person'
      }
    }
  },
  {
    'key': 'RolesComponent',
    'path': '/account/roles',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountRolesController',
      'permissions': [
        'AccountRoles.View',
        'AccountRoles.Edit',
        'AccountRoles.Delete'
      ],
      'meta': {
        'title': 'Roles',
        'override': true,
        'description': 'Roles Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'admin_panel_settings'
      }
    }
  },
  {
    'key': 'RoleComponent',
    'path': '/account/role',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountRolesController',
      'permissions': [
        'AccountRoles.View',
        'AccountRoles.Edit',
        'AccountRoles.Delete'
      ],
      'meta': {
        'title': 'Role Details',
        'override': true,
        'description': 'Role Details Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'admin_panel_settings'
      }
    }
  },
  {
    'key': 'RoleEditComponent',
    'path': '/account/role/edit',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountRolesController',
      'permissions': [
        'AccountRoles.Edit',
        'AccountRoles.Delete'
      ],
      'meta': {
        'title': 'Edit Role Details',
        'override': true,
        'description': 'Edit Role Details Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'admin_panel_settings'
      }
    }
  },
  {
    'key': 'SettingsComponent',
    'path': '/account/settings',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountSettingsController',
      'permissions': [
        'AccountSettings.View',
        'AccountSettings.Edit',
        'AccountSettings.Delete'
      ],
      'meta': {
        'title': 'Settings',
        'override': true,
        'description': 'Account Settings Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'settings'
      }
    }
  },
  {
    'key': 'StorageComponent',
    'path': '/account/retention',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AccountStorageController',
      'permissions': [
        'AccountStorage.View',
        'AccountStorage.Edit',
        'AccountStorage.Delete'
      ],
      'meta': {
        'title': 'Retention',
        'override': true,
        'description': 'Account Retention Settings Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'storage'
      }
    }
  },
  {
    'key': 'CustomFieldsListComponent',
    'path': '/account/custom-fields/list',
    'data': {
      'menuGroup': 'SettingsGroup',
      'otherMenuGroups': [
        'ContactGroup'
      ],
      'package': 'CustomFieldsController',
      'permissions': [
        'CustomFields.View',
        'CustomFields.Edit',
        'CustomFields.Delete'
      ],
      'meta': {
        'title': 'Custom Fields',
        'override': true,
        'description': 'Custom Fields Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'view_list',
        'order': 3
      }
    }
  },
  {
    'key': 'CustomFieldEditComponent',
    'path': '/account/custom-fields/details/edit',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'CustomFieldsController',
      'permissions': [
        'CustomFields.Edit',
        'CustomFields.Delete'
      ],
      'meta': {
        'title': 'Custom Field Edit',
        'override': true,
        'description': 'Edit Custom Fields Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'view_list'
      }
    }
  },
  {
    'key': 'AuditComponent',
    'path': '/account/audit',
    'data': {
      'menuGroup': 'SettingsGroup',
      'package': 'AuditController',
      'permissions': [
        'Audit.View',
        'Audit.Edit',
        'Audit.Delete'
      ],
      'animation': 'main',
      'meta': {
        'title': 'Audit',
        'override': true,
        'description': 'Audit Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'policy',
        'order': 4
      }
    }
  },
  {
    'key': 'ActivityDashboardComponent',
    'path': '/reports/activity-dashboard',
    'data': {
      'menuGroup': 'LegacyReportsGroup',
      'package': 'ActivityDashboardController',
      'permissions': [
        'ActivityDashboard.View',
        'ActivityDashboard.Edit',
        'ActivityDashboard.Delete'
      ],
      'meta': {
        'title': 'Main Dashboard',
        'override': true,
        'description': 'Main Dashboard page',
        'inSideMenu': true,
        'iconClass': 'fa fa-tachometer-alt',
        'showDisabled': false,
        'badge': 'deprecated'
      }
    }
  },
  {
    'key': 'ActivityDashboardV2Component',
    'path': '/reports/activity-dashboard-v2',
    'data': {
      'menuGroup': 'ReportsGroup',
      'package': 'ActivityDashboardV2Controller',
      'permissions': [
        'ActivityDashboard.View',
        'ActivityDashboard.Edit',
        'ActivityDashboard.Delete'
      ],
      'meta': {
        'title': 'Main Dashboard',
        'override': true,
        'description': 'Main Dashboard page',
        'inSideMenu': true,
        'iconClass': 'fa fa-tachometer-alt',
        'showDisabled': true,
        'badge': 'none'
      }
    }
  },
  {
    'key': 'CallLogsComponent',
    'path': '/reports/call-logs',
    'data': {
      'menuGroup': 'LegacyReportsGroup',
      'package': 'CallLogsController',
      'permissions': [
        'CallLogs.View',
        'CallLogs.Edit',
        'CallLogs.Delete'
      ],
      'meta': {
        'title': 'Call Logs',
        'override': true,
        'description': 'Call Logs Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'description',
        'badge': 'deprecated'
      }
    }
  },
  {
    'key': 'CallLogsV2Component',
    'path': '/reports/call-logs-v2',
    'data': {
      'menuGroup': 'ReportsGroup',
      'package': 'CallLogsV2Controller',
      'permissions': [
        'CallLogs.View',
        'CallLogs.Edit',
        'CallLogs.Delete'
      ],
      'meta': {
        'title': 'Call Logs',
        'override': true,
        'description': 'Call Logs Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'description',
        'showDisabled': false,
        'badge': 'none'
      }
    }
  },
  {
    'key': 'UsersSummaryComponent',
    'path': '/reports/users-list',
    'data': {
      'menuGroup': 'LegacyReportsGroup',
      'package': 'UsersSummaryController',
      'permissions': [
        'UsersSummary.View',
        'UsersSummary.Edit',
        'UsersSummary.Delete'
      ],
      'meta': {
        'title': 'Agents Summary',
        'override': true,
        'description': 'Agents Summary Page',
        'inSideMenu': true,
        'iconClass': 'fa fa-user',
        'badge': 'deprecated'
      }
    }
  },
  {
    'key': 'UsersSummaryV2Component',
    'path': '/reports/users-summary-v2',
    'data': {
      'menuGroup': 'ReportsGroup',
      'package': 'UsersSummaryV2Controller',
      'permissions': [
        'UsersSummary.View',
        'UsersSummary.Edit',
        'UsersSummary.Delete'
      ],
      'meta': {
        'title': 'Agents Summary',
        'override': true,
        'description': 'Agents Summary Page',
        'inSideMenu': true,
        'iconClass': 'fa fa-user',
        'showDisabled': false,
        'badge': 'none'
      }
    }
  },
  {
    'key': 'AgentGroupsDashboardComponent',
    'path': '/reports/agent-groups-dashboard',
    'data': {
      'menuGroup': 'LegacyReportsGroup',
      'package': 'GroupsDashboardController',
      'permissions': [
        'GroupsDashboard.View',
        'GroupsDashboard.Edit',
        'GroupsDashboard.Delete'
      ],
      'meta': {
        'title': 'Agent Groups Dashboard',
        'override': true,
        'description': 'Agent Groups Dashboard Page',
        'inSideMenu': true,
        'iconClass': 'fa fa-group',
        'badge': 'deprecated'
      }
    }
  },
  {
    'key': 'AgentGroupsSummaryComponent',
    'path': '/reports/agent-groups-list',
    'data': {
      'menuGroup': 'LegacyReportsGroup',
      'package': 'GroupsSummaryController',
      'permissions': [
        'GroupsSummary.View',
        'GroupsSummary.Edit',
        'GroupsSummary.Delete'
      ],
      'meta': {
        'title': 'Groups Summary',
        'override': true,
        'description': 'Agent Groups Summary Page',
        'inSideMenu': true,
        'iconClass': 'fa fa-list',
        'badge': 'deprecated'
      }
    }
  },
  {
    'key': 'GroupsSummaryV2Component',
    'path': '/reports/groups-summary-v2',
    'data': {
      'menuGroup': 'ReportsGroup',
      'package': 'GroupsSummaryV2Controller',
      'permissions': [
        'GroupsSummary.View',
        'GroupsSummary.Edit',
        'GroupsSummary.Delete'
      ],
      'meta': {
        'title': 'Groups Summary',
        'override': true,
        'description': 'Groups Summary Page',
        'inSideMenu': true,
        'iconClass': 'fa fa-list',
        'showDisabled': false,
        'badge': 'none'
      }
    }
  },
  {
    'key': 'HuntGroupsDashboardComponent',
    'path': '/reports/hunt-groups-dashboard',
    'data': {
      'menuGroup': 'LegacyReportsGroup',
      'package': 'HuntGroupsDashboardController',
      'permissions': [
        'HuntGroupsDashboard.View',
        'HuntGroupsDashboard.Edit',
        'HuntGroupsDashboard.Delete'
      ],
      'meta': {
        'title': 'Hunt Groups Dashboard',
        'override': true,
        'description': 'Hunt Groups Dashboard Page',
        'inSideMenu': true,
        'iconClass': 'fa fa-group',
        'badge': 'deprecated'
      }
    }
  },
  {
    'key': 'CustomReportListComponent',
    'path': '/reports/custom-reports/list',
    'data': {
      'menuGroup': 'ReportsGroup',
      'package': 'CustomReportsController',
      'permissions': [
        'CustomReports.View',
        'CustomReports.Edit',
        'CustomReports.Delete'
      ],
      'meta': {
        'title': 'Custom Reports',
        'override': true,
        'description': 'Custom Reports',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'insert_chart_outlined',
        'showDisabled': true
      }
    }
  },
  {
    'key': 'CustomReportEditComponent',
    'path': '/reports/custom-reports/edit',
    'data': {
      'menuGroup': 'ReportsGroup',
      'package': 'CustomReportsController',
      'permissions': [
        'CustomReports.View',
        'CustomReports.Edit',
        'CustomReports.Delete'
      ],
      'meta': {
        'title': 'Edit Report',
        'override': true,
        'description': 'Edit Custom Report',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'insert_chart_outlined'
      }
    }
  },
  {
    'key': 'CustomReportUnsubscribeComponent',
    'path': '/reports/custom-reports/unsubscribe',
    'data': {
      'menuGroup': 'ReportsGroup',
      'package': 'CustomReportsController',
      'permissions': [
        'CustomReports.View',
        'CustomReports.Edit',
        'CustomReports.Delete'
      ],
      'meta': {
        'title': 'Unsubscribe',
        'override': true,
        'description': 'Unsubscribe From Reports',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'insert_chart_outlined',
        'showDisabled': false
      }
    }
  },
  {
    'key': 'BasicCallLogsComponent',
    'path': '/call-logs',
    'data': {
      'menuGroup': 'CallLogsGroup',
      'package': 'BasicCallLogsController',
      'permissions': [
        'BasicCallLogs.View',
        'BasicCallLogs.Edit',
        'BasicCallLogs.Delete'
      ],
      'meta': {
        'title': 'Call Logs',
        'override': true,
        'description': 'Call Logs Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'description'
      }
    }
  },
  {
    'key': 'PhoneNumbersComponent',
    'path': '/phone-numbers/list',
    'data': {
      'menuGroup': 'PhoneNumbersGroup',
      'package': 'PhoneNumbersController',
      'permissions': [
        'PhoneNumbers.View',
        'PhoneNumbers.Edit',
        'PhoneNumbers.Delete'
      ],
      'meta': {
        'title': 'Phone Numbers',
        'override': true,
        'description': 'Phone Numbers Page',
        'inSideMenu': true,
        'iconClass': 'fa fa-hashtag'
      }
    }
  },
  {
    'key': 'PhoneNumberEditComponent',
    'path': '/phone-numbers/phone-number/edit',
    'data': {
      'menuGroup': 'PhoneNumbersGroup',
      'package': 'PhoneNumbersController',
      'permissions': [
        'PhoneNumbers.Edit',
        'PhoneNumbers.Delete'
      ],
      'meta': {
        'title': 'Edit Phone Number Details',
        'override': true,
        'description': 'Edit Phone Number Details Page',
        'inSideMenu': false,
        'iconClass': 'fa fa-hashtag'
      }
    }
  },
  {
    'key': 'WhiteListComponent',
    'path': '/firewall/list',
    'data': {
      'menuGroup': 'PbxGroup',
      'package': 'FirewallController',
      'permissions': [
        'Firewall.View',
        'Firewall.Edit',
        'Firewall.Delete'
      ],
      'meta': {
        'title': 'IP Whitelist',
        'override': true,
        'description': 'IP Whitelist Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'security',
        'order': 3
      }
    }
  },
  {
    'key': 'FirewallEditComponent',
    'path': '/firewall/ipaddress/edit',
    'data': {
      'menuGroup': 'PbxGroup',
      'package': 'FirewallController',
      'permissions': [
        'Firewall.Edit',
        'Firewall.Delete'
      ],
      'meta': {
        'title': 'Whitelisting IP Addresses',
        'override': true,
        'description': 'Edit IP Address Details Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'security'
      }
    }
  },
  {
    'key': 'MessagingComponent',
    'path': '/messaging',
    'data': {
      'menuGroup': 'MessagingGroup',
      'package': 'MessagingController',
      'permissions': [
        'Messaging.View',
        'Messaging.Edit',
        'Messaging.Delete'
      ],
      'meta': {
        'title': 'Basic SMS',
        'override': true,
        'description': 'Basic SMS Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'textsms'
      }
    }
  },
  {
    'key': 'ProMessagingComponent',
    'path': '/advanced-messaging/messages',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'ProMessagingController',
      'permissions': [
        'ProMessaging.View',
        'ProMessaging.Edit',
        'ProMessaging.Delete'
      ],
      'meta': {
        'title': 'Messages',
        'override': true,
        'description': 'Pro Business SMS Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'try',
        'showDisabled': true
      }
    }
  },
  {
    'key': 'MessageBroadcastingComponent',
    'path': '/advanced-messaging/broadcast',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'MessageBroadcastingController',
      'permissions': [
        'MessageBroadcasting.View',
        'MessageBroadcasting.Edit',
        'MessageBroadcasting.Delete'
      ],
      'meta': {
        'title': 'Broadcast',
        'override': true,
        'description': 'Message Broadcast Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'podcasts',
        'showDisabled': true
      }
    }
  },
  {
    'key': 'ProMessagingAutomationListComponent',
    'path': '/advanced-messaging/automation',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'MessagingAutomationController',
      'permissions': [
        'MessagingAutomation.View',
        'MessagingAutomation.Edit',
        'MessagingAutomation.Delete'
      ],
      'meta': {
        'title': 'Automation',
        'override': true,
        'description': 'Auto reply for incoming messages',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'reply_all',
        'showDisabled': true
      }
    }
  },
  {
    'key': 'ProMessagingAutomationEditComponent',
    'path': '/advanced-messaging/automation/edit',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'MessagingAutomationController',
      'permissions': [
        'MessagingAutomation.Edit',
        'MessagingAutomation.Delete'
      ],
      'meta': {
        'title': 'Auto Reply Settings',
        'override': true,
        'description': 'Auto Reply Settings Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'reply_all'
      }
    }
  },
  {
    'key': 'ProMessagingAutomationEditScheduleComponent',
    'path': '/advanced-messaging/automation/edit-schedule',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'MessagingAutomationController',
      'permissions': [
        'MessagingAutomation.Edit',
        'MessagingAutomation.Delete'
      ],
      'meta': {
        'title': 'Scheduled Message Settings',
        'override': true,
        'description': 'Scheduled Message Settings Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'schedule'
      }
    }
  },
  {
    'key': 'ProMessagingTemplateListComponent',
    'path': '/advanced-messaging/template/list',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': [
        'MessagingTemplateController',
        'MessagingPrivateTemplateController'
      ],
      'permissions': [
        'MessagingTemplate.View',
        'MessagingTemplate.Edit',
        'MessagingTemplate.Delete',
        'MessagingPrivateTemplate.View',
        'MessagingPrivateTemplate.Edit',
        'MessagingPrivateTemplate.Delete'
      ],
      'meta': {
        'title': 'Templates',
        'override': true,
        'description': 'Messages templates for fast reply',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'quickreply',
        'showDisabled': true
      }
    }
  },
  {
    'key': 'ProMessagingTemplateEditComponent',
    'path': '/advanced-messaging/template/edit',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': [
        'MessagingTemplateController',
        'MessagingPrivateTemplateController'
      ],
      'permissions': [
        'MessagingTemplate.Edit',
        'MessagingTemplate.Delete',
        'MessagingPrivateTemplate.Edit',
        'MessagingPrivateTemplate.Delete'
      ],
      'meta': {
        'title': 'Edit Template',
        'override': true,
        'description': 'Messages templates for fast reply',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'quickreply'
      }
    }
  },
  {
    'key': 'ProMessagingWhatsAppTemplateListComponent',
    'path': '/advanced-messaging/whatsapp-template/list',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'MessagingWhatsAppTemplateController',
      'permissions': [
        'MessagingWhatsAppTemplate.View',
        'MessagingWhatsAppTemplate.Edit',
        'MessagingWhatsAppTemplate.Delete'
      ],
      'meta': {
        'title': 'WhatsApp Templates',
        'override': true,
        'description': 'WhatsApp Messages templates for initial conversation',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'quickreply',
        'showDisabled': false
      }
    }
  },
  {
    'key': 'ProMessagingWhatsAppTemplateEditComponent',
    'path': '/advanced-messaging/whatsapp-template/edit',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'MessagingWhatsAppTemplateController',
      'permissions': [
        'MessagingWhatsAppTemplate.Edit',
        'MessagingWhatsAppTemplate.Delete'
      ],
      'meta': {
        'title': 'Edit WhatsApp Template',
        'override': true,
        'description': 'WhatsApp templates',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'quickreply'
      }
    }
  },
  {
    'key': 'ProMessagingReportsSummaryComponent',
    'path': '/advanced-messaging/reports',
    'data': {
      'menuGroup': 'ProMessagingGroup',
      'package': 'ProMessagingReportsController',
      'permissions': [
        'ProMessagingReports.View',
        'ProMessagingReports.Edit',
        'ProMessagingReports.Delete'
      ],
      'meta': {
        'title': 'Reports',
        'override': true,
        'description': 'Messaging Reports',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'bar_chart',
        'showDisabled': true
      }
    }
  },
  {
    'key': 'FaxListComponent',
    'path': '/fax/list',
    'data': {
      'menuGroup': 'FaxGroup',
      'package': 'FaxController',
      'permissions': [
        'Fax.View',
        'Fax.Edit',
        'Fax.Delete'
      ],
      'animation': 'main',
      'meta': {
        'title': 'Fax Activity',
        'override': true,
        'description': 'Fax Activity Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'print'
      }
    }
  },
  {
    'key': 'SendFaxComponent',
    'path': '/fax/send-fax',
    'data': {
      'menuGroup': 'FaxGroup',
      'package': 'FaxController',
      'permissions': [
        'Fax.Edit',
        'Fax.Delete'
      ],
      'animation': 'main',
      'meta': {
        'title': 'Send Fax',
        'override': true,
        'description': 'Send Fax Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'send'
      }
    }
  },
  {
    'key': 'FaxReportsSummaryComponent',
    'path': '/fax/reports/summary',
    'data': {
      'menuGroup': 'FaxGroup',
      'package': 'FaxReportController',
      'permissions': [
        'FaxReport.View',
        'FaxReport.Edit',
        'FaxReport.Delete'
      ],
      'meta': {
        'title': 'Fax Reports',
        'override': true,
        'description': 'Fax Reports',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'insert_chart_outlined',
        'showDisabled': true
      }
    }
  },
  {
    'key': 'UserLoginComponent',
    'path': '/pbx/summary',
    'data': {
      'menuGroup': 'PbxGroup',
      'package': 'PbxController',
      'permissions': [
        'Pbx.View',
        'Pbx.Edit',
        'Pbx.Delete'
      ],
      'animation': 'main',
      'meta': {
        'title': 'My Pbx',
        'override': true,
        'description': 'Login To PBX Console',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'cloud_circle',
        'order': 4
      }
    }
  },
  {
    'key': 'HolidayGreetingsComponent',
    'path': '/pbx/holiday-greetings',
    'data': {
      'menuGroup': 'PbxGroup',
      'package': 'PbxHolidayGreetingsController',
      'permissions': [
        'PbxHolidayGreetings.View',
        'PbxHolidayGreetings.Edit',
        'PbxHolidayGreetings.Delete'
      ],
      'meta': {
        'title': 'Holiday Greetings',
        'override': true,
        'description': 'HolidayGreetings Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'event',
        'order': 2
      }
    }
  },
  {
    'key': 'HolidayGreetingsEditComponent',
    'path': '/pbx/holiday-greetings',
    'data': {
      'menuGroup': 'PbxGroup',
      'package': 'PbxHolidayGreetingsController',
      'permissions': [
        'PbxHolidayGreetings.Edit',
        'PbxHolidayGreetings.Delete'
      ],
      'meta': {
        'title': 'Holiday Greetings',
        'override': true,
        'description': 'Holiday Greetings Edit Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'event'
      }
    }
  },
  {
    'key': 'PbxAccountsComponent',
    'path': '/pbx/extensions',
    'data': {
      'menuGroup': 'PbxGroup',
      'package': 'PbxAccountsController',
      'permissions': [
        'PbxAccounts.View',
        'PbxAccounts.Edit',
        'PbxAccounts.EditOwn',
        'PbxAccounts.Delete'
      ],
      'meta': {
        'title': 'Extensions',
        'override': true,
        'description': 'Pbx Extensions Page',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'manage_accounts',
        'order': 1
      }
    }
  },
  {
    'key': 'EditPbxAccountComponent',
    'path': '/pbx/extensions',
    'data': {
      'menuGroup': 'PbxGroup',
      'package': 'PbxAccountsController',
      'permissions': [
        'PbxAccounts.Edit',
        'PbxAccounts.EditOwn',
        'PbxAccounts.Delete'
      ],
      'meta': {
        'title': 'Extension Details',
        'override': true,
        'description': 'Pbx Extensions Edit Page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'manage_accounts'
      }
    }
  },
  {
    'key': 'ContactListComponent',
    'path': '/contacts/list',
    'data': {
      'menuGroup': 'ContactGroup',
      'package': [
        'ContactController',
        'PrivateContactController'
      ],
      'permissions': [
        'CompanyContact.View',
        'CompanyContact.Edit',
        'CompanyContact.Delete',
        'PrivateContact.View',
        'PrivateContact.Edit',
        'PrivateContact.Delete'
      ],
      'meta': {
        'title': 'Contacts',
        'override': true,
        'description': 'Contacts',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'group'
      }
    }
  },
  {
    'key': 'ContactIntegrationComponent',
    'path': '/contacts/import',
    'data': {
      'menuGroup': 'ContactGroup',
      'package': [
        'PrivateContactController'
      ],
      'permissions': [
        'PrivateContact.View',
        'PrivateContact.Edit',
        'PrivateContact.Delete'
      ],
      'meta': {
        'title': 'Import Contacts',
        'override': true,
        'description': 'Import Contacts From csv file or other providers',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'sync'
      }
    }
  },
  {
    'key': 'ContactEditComponent',
    'path': '/contacts/contact/edit',
    'data': {
      'menuGroup': 'ContactGroup',
      'package': [
        'ContactController',
        'PrivateContactController'
      ],
      'permissions': [
        'CompanyContact.View',
        'CompanyContact.Edit',
        'CompanyContact.Delete',
        'PrivateContact.View',
        'PrivateContact.Edit',
        'PrivateContact.Delete'
      ],
      'meta': {
        'title': 'Contact Details',
        'override': true,
        'description': 'Contact details and edit page',
        'inSideMenu': false,
        'iconClass': 'material-icons',
        'iconClassCode': 'security'
      }
    }
  },
  {
    'key': 'SummaryComponent',
    'path': '/softphone/basic/summary',
    'data': {
      'menuGroup': 'SoftPhoneGroup',
      'package': 'BasicSoftPhoneController',
      'permissions': [
        'BasicSoftPhone.View',
        'BasicSoftPhone.Edit',
        'BasicSoftPhone.Delete'
      ],
      'animation': 'main',
      'meta': {
        'title': 'TeleGo Mobile App',
        'override': true,
        'description': 'TeleGo Mobile App Information',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'phone_android'
      }
    }
  },
  {
    'key': 'SummaryComponent',
    'path': '/softphone/premium/summary',
    'data': {
      'menuGroup': 'SoftPhoneGroup',
      'package': 'SoftPhoneController',
      'permissions': [
        'SoftPhone.View',
        'SoftPhone.Edit',
        'SoftPhone.Delete'
      ],
      'animation': 'main',
      'meta': {
        'title': 'TeleGo Desktop App',
        'override': true,
        'description': 'TeleGo Desktop App Information',
        'inSideMenu': true,
        'iconClass': 'material-icons',
        'iconClassCode': 'important_devices'
      }
    }
  },
  {
    'key': 'ListComponent',
    'path': '/downloads/list',
    'data': {
      'menuGroup': 'DownloadsGroup',
      'package': 'DownloadsController',
      'permissions': [
        'any'
      ],
      'animation': 'main',
      'meta': {
        'title': 'Downloads',
        'override': true,
        'description': 'Available Downloads',
        'inSideMenu': true,
        'iconClass': 'download',
        'iconClassCode': 'phone_iphone'
      }
    }
  },
  {
    'key': 'ConferenceComponent',
    'path': '/conference',
    'data': {
      'menuGroup': 'VideoConferenceGroup',
      'package': 'VideoConferenceController',
      'permissions': [
        'VideoConference.View',
        'VideoConference.Edit',
        'VideoConference.Delete'
      ],
      'animation': 'main',
      'meta': {
        'title': 'Video Conference',
        'override': true,
        'description': 'Video Conference Information',
        'inSideMenu': true,
        'iconClass': 'zoom-icon',
        'iconClassCode': ''
      }
    }
  }
];
