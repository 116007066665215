import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {NavigationEnd, Router} from '@angular/router';
import {GtagPageview} from './interfaces';
import {AuthService} from '../core/security/auth.service';
declare var gtag: any;

@Injectable({providedIn: 'root'})
export class GoogleAnalyticsService {
  private isLoaded = false;
  private debug = false;
  private browserGlobals = {
    windowRef(): any {
      return window;
    },
    documentRef(): any {
      return document;
    },
  };

  constructor(private router: Router, private authService: AuthService) {
    this.authService.currentUser.subscribe((user) => {
      console.log('setUserId');
      this.setUserId(user?.customerUserId, user?.customerId);
    })
  }

  public initGA() {
    this.addGTagToDom().then(() => {
      console.log('GTAG initiated', environment.googleTrackingCode);
    });
  }

  private checkForId(): boolean {
    return !!environment.googleTrackingCode;
  }

  public addGTagToDom(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.isLoaded) {
        return resolve(this.isLoaded);
      } else if (!this.checkForId()) {
        return resolve(false);
      }
      const doc = this.browserGlobals.documentRef();

      const gtagScript = doc.createElement('script');
      gtagScript.id = 'GTagScript';
      gtagScript.async = true;
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTrackingCode}`;
      gtagScript.addEventListener('load', () => {
        return resolve((this.isLoaded = true));
      });
      gtagScript.addEventListener('error', () => {
        return reject(false);
      });

      doc.head.insertBefore(gtagScript, doc.head.lastChild);

      const gaInitScript = document.createElement('script');
      gaInitScript.type = 'text/javascript';
      const code = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}';
      try {
        gaInitScript.appendChild(document.createTextNode(code));
        doc.head.insertBefore(gaInitScript, doc.head.lastChild);

        gtag('js', new Date());
        gtag('config', environment.googleTrackingCode, {'debug_mode': this.debug, send_page_view: false});
      } catch (e) {
        gaInitScript.text = code;
        doc.head.insertBefore(gaInitScript, doc.head.lastChild);

        gtag('js', new Date());
        gtag('config', environment.googleTrackingCode, {'debug_mode': this.debug, send_page_view: false});
      }
    });
  }

  public setUserId(userId?: string, customerId?: string) {
    if(userId || customerId) {
      this.config({
        'user_id': userId,
        'customer_id': customerId
      });
    }
  }

  public navigateTo(event: NavigationEnd) {
    setTimeout(() => {
      const currentUser = this.authService.currentUser.getValue();
      this.pageView({
        page_path: event.urlAfterRedirects,
        page_title: document.title,
        debug_mode: this.debug,
        customer_id: currentUser?.customerId
      });
    });
  }

  pageView(params?: GtagPageview) {
    try {
      const defaults = {
        page_path: this.router.url,
        page_title: document.title,
        page_location: window.location.href,
        send_to: environment.googleTrackingCode
      };

      params = {...defaults, ...params};
      gtag('event', 'page_view', params);
    } catch (err) {
      console.error('Google Analytics pageview error', err);
    }
  }

  config(params: any) {
    try {
      if (!params) {
        params = {};
      }

      if(window['gtag']) {
        gtag('config', environment.googleTrackingCode, params);
      }
    } catch (err) {
      console.error('Google Analytics config error', err);
    }
  }
}
