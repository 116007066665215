/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { TagModel } from '../models/tag-model';
import { TagsGridResponse } from '../models/tags-grid-response';
import { TagsRequest } from '../models/tags-request';

@Injectable({ providedIn: 'root' })
export class TagsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tagsGetTags()` */
  static readonly TagsGetTagsPath = '/api/tags/list';

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsGetTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsGetTags$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<TagModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TagsService.TagsGetTagsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TagModel>>;
      })
    );
  }

  /**
   * Get List Of Contacts.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsGetTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsGetTags(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<TagModel>> {
    return this.tagsGetTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagModel>>): Array<TagModel> => r.body)
    );
  }

  /** Path part for operation `tagsGetTagsForGrid()` */
  static readonly TagsGetTagsForGridPath = '/api/tags/grid';

  /**
   * Get List Of Tags.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsGetTagsForGrid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsGetTagsForGrid$Response(
    params: {
      body: TagsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TagsGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, TagsService.TagsGetTagsForGridPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagsGridResponse>;
      })
    );
  }

  /**
   * Get List Of Tags.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsGetTagsForGrid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsGetTagsForGrid(
    params: {
      body: TagsRequest
    },
    context?: HttpContext
  ): Observable<TagsGridResponse> {
    return this.tagsGetTagsForGrid$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagsGridResponse>): TagsGridResponse => r.body)
    );
  }

  /** Path part for operation `tagsSaveTag()` */
  static readonly TagsSaveTagPath = '/api/tags/save';

  /**
   * Save contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsSaveTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsSaveTag$Response(
    params: {
      body: TagModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TagsService.TagsSaveTagPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsSaveTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  tagsSaveTag(
    params: {
      body: TagModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.tagsSaveTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `tagsDeleteTagById()` */
  static readonly TagsDeleteTagByIdPath = '/api/tags/tag/{id}';

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tagsDeleteTagById()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsDeleteTagById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, TagsService.TagsDeleteTagByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete contact.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tagsDeleteTagById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tagsDeleteTagById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.tagsDeleteTagById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
