/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AuditGridResponse } from '../models/audit-grid-response';
import { AuditListRequest } from '../models/audit-list-request';
import { LookupModel } from '../models/lookup-model';

@Injectable({ providedIn: 'root' })
export class AuditService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `auditGetStatus()` */
  static readonly AuditGetStatusPath = '/api/audit/status';

  /**
   * Get Audit Service Status.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetStatus$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, AuditService.AuditGetStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get Audit Service Status.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetStatus(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.auditGetStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `auditGetAuditTypes()` */
  static readonly AuditGetAuditTypesPath = '/api/audit/types';

  /**
   * Get audit types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetAuditTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditTypes$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<LookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditService.AuditGetAuditTypesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LookupModel>>;
      })
    );
  }

  /**
   * Get audit types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetAuditTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditTypes(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<LookupModel>> {
    return this.auditGetAuditTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `auditGetAuditCategories()` */
  static readonly AuditGetAuditCategoriesPath = '/api/audit/categories';

  /**
   * Get audit categories.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetAuditCategories()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditCategories$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<LookupModel>>> {
    const rb = new RequestBuilder(this.rootUrl, AuditService.AuditGetAuditCategoriesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LookupModel>>;
      })
    );
  }

  /**
   * Get audit categories.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetAuditCategories$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  auditGetAuditCategories(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<LookupModel>> {
    return this.auditGetAuditCategories$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LookupModel>>): Array<LookupModel> => r.body)
    );
  }

  /** Path part for operation `auditGetList()` */
  static readonly AuditGetListPath = '/api/audit/list';

  /**
   * Get latest audit.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `auditGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auditGetList$Response(
    params: {
      body: AuditListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuditGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AuditService.AuditGetListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuditGridResponse>;
      })
    );
  }

  /**
   * Get latest audit.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `auditGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  auditGetList(
    params: {
      body: AuditListRequest
    },
    context?: HttpContext
  ): Observable<AuditGridResponse> {
    return this.auditGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuditGridResponse>): AuditGridResponse => r.body)
    );
  }

}
