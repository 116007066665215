/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BasicSoftPhoneChangeExtensionPasswordModel } from '../models/basic-soft-phone-change-extension-password-model';
import { BasicSoftPhoneChangePasswordModel } from '../models/basic-soft-phone-change-password-model';
import { BasicSoftPhoneSaveSettingsModel } from '../models/basic-soft-phone-save-settings-model';
import { BasicSoftPhoneSummaryModel } from '../models/basic-soft-phone-summary-model';

@Injectable({ providedIn: 'root' })
export class BasicSoftphoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `basicSoftphoneSummary()` */
  static readonly BasicSoftphoneSummaryPath = '/api/basic-softphone/summary';

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSummary$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BasicSoftPhoneSummaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, BasicSoftphoneService.BasicSoftphoneSummaryPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BasicSoftPhoneSummaryModel>;
      })
    );
  }

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSummary(
    params?: {
    },
    context?: HttpContext
  ): Observable<BasicSoftPhoneSummaryModel> {
    return this.basicSoftphoneSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSoftPhoneSummaryModel>): BasicSoftPhoneSummaryModel => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneSaveSoftPhoneSettings()` */
  static readonly BasicSoftphoneSaveSoftPhoneSettingsPath = '/api/basic-softphone/save-settings';

  /**
   * Change SoftPhone Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneSaveSoftPhoneSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneSaveSoftPhoneSettings$Response(
    params: {
      body: BasicSoftPhoneSaveSettingsModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BasicSoftphoneService.BasicSoftphoneSaveSoftPhoneSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change SoftPhone Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneSaveSoftPhoneSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneSaveSoftPhoneSettings(
    params: {
      body: BasicSoftPhoneSaveSettingsModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.basicSoftphoneSaveSoftPhoneSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneChangePassword()` */
  static readonly BasicSoftphoneChangePasswordPath = '/api/basic-softphone/change-password';

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangePassword$Response(
    params: {
      body: BasicSoftPhoneChangePasswordModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BasicSoftphoneService.BasicSoftphoneChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangePassword(
    params: {
      body: BasicSoftPhoneChangePasswordModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.basicSoftphoneChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneExtensionSummary()` */
  static readonly BasicSoftphoneExtensionSummaryPath = '/api/basic-softphone/extension-summary/{extension}';

  /**
   * Get extension softphone summary details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneExtensionSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneExtensionSummary$Response(
    params: {
      extension: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BasicSoftPhoneSummaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, BasicSoftphoneService.BasicSoftphoneExtensionSummaryPath, 'get');
    if (params) {
      rb.path('extension', params.extension, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BasicSoftPhoneSummaryModel>;
      })
    );
  }

  /**
   * Get extension softphone summary details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneExtensionSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneExtensionSummary(
    params: {
      extension: string;
    },
    context?: HttpContext
  ): Observable<BasicSoftPhoneSummaryModel> {
    return this.basicSoftphoneExtensionSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSoftPhoneSummaryModel>): BasicSoftPhoneSummaryModel => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneChangeExtensionPassword()` */
  static readonly BasicSoftphoneChangeExtensionPasswordPath = '/api/basic-softphone/change-extension-password';

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneChangeExtensionPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangeExtensionPassword$Response(
    params: {
      body: BasicSoftPhoneChangeExtensionPasswordModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, BasicSoftphoneService.BasicSoftphoneChangeExtensionPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneChangeExtensionPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSoftphoneChangeExtensionPassword(
    params: {
      body: BasicSoftPhoneChangeExtensionPasswordModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.basicSoftphoneChangeExtensionPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `basicSoftphoneSendQrCode()` */
  static readonly BasicSoftphoneSendQrCodePath = '/api/basic-softphone/send-qrcode/{customerUserId}';

  /**
   * Send softphone login information email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSoftphoneSendQrCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSendQrCode$Response(
    params: {
      customerUserId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BasicSoftPhoneSummaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, BasicSoftphoneService.BasicSoftphoneSendQrCodePath, 'get');
    if (params) {
      rb.path('customerUserId', params.customerUserId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BasicSoftPhoneSummaryModel>;
      })
    );
  }

  /**
   * Send softphone login information email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSoftphoneSendQrCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSoftphoneSendQrCode(
    params: {
      customerUserId: string;
    },
    context?: HttpContext
  ): Observable<BasicSoftPhoneSummaryModel> {
    return this.basicSoftphoneSendQrCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSoftPhoneSummaryModel>): BasicSoftPhoneSummaryModel => r.body)
    );
  }

}
