/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccountSettingsModel } from '../models/account-settings-model';
import { SaveAccountSettingsRequest } from '../models/save-account-settings-request';
import { ToggleBetaRequest } from '../models/toggle-beta-request';
import { ToggleBetaResponse } from '../models/toggle-beta-response';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountSettingsGetSettings()` */
  static readonly AccountSettingsGetSettingsPath = '/api/account/settings';

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsGetSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.AccountSettingsGetSettingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingsModel>;
      })
    );
  }

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsGetSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings(
    params?: {
    },
    context?: HttpContext
  ): Observable<AccountSettingsModel> {
    return this.accountSettingsGetSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsSaveSettings()` */
  static readonly AccountSettingsSaveSettingsPath = '/api/account/settings';

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsSaveSettings()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings$Response(
    params: {
      body: SaveAccountSettingsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.AccountSettingsSaveSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingsModel>;
      })
    );
  }

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsSaveSettings$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings(
    params: {
      body: SaveAccountSettingsRequest
    },
    context?: HttpContext
  ): Observable<AccountSettingsModel> {
    return this.accountSettingsSaveSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsPreviewFax()` */
  static readonly AccountSettingsPreviewFaxPath = '/api/account/preview-fax-cover/{filename}';

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsPreviewFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax$Response(
    params: {
      filename: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.AccountSettingsPreviewFaxPath, 'get');
    if (params) {
      rb.path('filename', params.filename, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsPreviewFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax(
    params: {
      filename: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountSettingsPreviewFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountSettingsToggleBeta()` */
  static readonly AccountSettingsToggleBetaPath = '/api/account/toggle-beta';

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsToggleBeta()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta$Response(
    params: {
      body: ToggleBetaRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ToggleBetaResponse>> {
    const rb = new RequestBuilder(this.rootUrl, SettingsService.AccountSettingsToggleBetaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToggleBetaResponse>;
      })
    );
  }

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsToggleBeta$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta(
    params: {
      body: ToggleBetaRequest
    },
    context?: HttpContext
  ): Observable<ToggleBetaResponse> {
    return this.accountSettingsToggleBeta$Response(params, context).pipe(
      map((r: StrictHttpResponse<ToggleBetaResponse>): ToggleBetaResponse => r.body)
    );
  }

}
