/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { DownloadLinkModel } from '../models/download-link-model';
import { SoftPhoneChangePasswordModel } from '../models/soft-phone-change-password-model';
import { SoftPhoneSummaryModel } from '../models/soft-phone-summary-model';

@Injectable({ providedIn: 'root' })
export class SoftphoneService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `softphoneSummary()` */
  static readonly SoftphoneSummaryPath = '/api/softphone/summary';

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneSummary$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SoftPhoneSummaryModel>> {
    const rb = new RequestBuilder(this.rootUrl, SoftphoneService.SoftphoneSummaryPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SoftPhoneSummaryModel>;
      })
    );
  }

  /**
   * Get user softphone summary details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneSummary(
    params?: {
    },
    context?: HttpContext
  ): Observable<SoftPhoneSummaryModel> {
    return this.softphoneSummary$Response(params, context).pipe(
      map((r: StrictHttpResponse<SoftPhoneSummaryModel>): SoftPhoneSummaryModel => r.body)
    );
  }

  /** Path part for operation `softphoneTest()` */
  static readonly SoftphoneTestPath = '/api/softphone/test';

  /**
   * Test CounterPath integration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneTest()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneTest$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, SoftphoneService.SoftphoneTestPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Test CounterPath integration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneTest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneTest(
    params?: {
    },
    context?: HttpContext
  ): Observable<string> {
    return this.softphoneTest$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `softphoneDownloadLinks()` */
  static readonly SoftphoneDownloadLinksPath = '/api/softphone/links';

  /**
   * Get list of softphone app download links.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneDownloadLinks()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneDownloadLinks$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<DownloadLinkModel>>> {
    const rb = new RequestBuilder(this.rootUrl, SoftphoneService.SoftphoneDownloadLinksPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DownloadLinkModel>>;
      })
    );
  }

  /**
   * Get list of softphone app download links.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneDownloadLinks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  softphoneDownloadLinks(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<DownloadLinkModel>> {
    return this.softphoneDownloadLinks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DownloadLinkModel>>): Array<DownloadLinkModel> => r.body)
    );
  }

  /** Path part for operation `softphoneChangePassword()` */
  static readonly SoftphoneChangePasswordPath = '/api/softphone/change-password';

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `softphoneChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softphoneChangePassword$Response(
    params: {
      body: SoftPhoneChangePasswordModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SoftphoneService.SoftphoneChangePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Change SoftPhone password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `softphoneChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  softphoneChangePassword(
    params: {
      body: SoftPhoneChangePasswordModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.softphoneChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
