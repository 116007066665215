/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BooleanResponse } from '../models/boolean-response';
import { CurrentUser } from '../models/current-user';
import { CustomerUserUiSettingsModel } from '../models/customer-user-ui-settings-model';
import { EditUserProfile } from '../models/edit-user-profile';
import { TwoFactorSetupModel } from '../models/two-factor-setup-model';
import { UserProfile } from '../models/user-profile';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersAlive()` */
  static readonly UsersAlivePath = '/api/users/alive';

  /**
   * Check if user session is valid.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlive()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlive$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CurrentUser>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersAlivePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrentUser>;
      })
    );
  }

  /**
   * Check if user session is valid.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlive(
    params?: {
    },
    context?: HttpContext
  ): Observable<CurrentUser> {
    return this.usersAlive$Response(params, context).pipe(
      map((r: StrictHttpResponse<CurrentUser>): CurrentUser => r.body)
    );
  }

  /** Path part for operation `usersProfile()` */
  static readonly UsersProfilePath = '/api/users/profile';

  /**
   * Current user account details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersProfile$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserProfile>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersProfilePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfile>;
      })
    );
  }

  /**
   * Current user account details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersProfile(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserProfile> {
    return this.usersProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfile>): UserProfile => r.body)
    );
  }

  /** Path part for operation `usersSaveProfile()` */
  static readonly UsersSaveProfilePath = '/api/users/profile';

  /**
   * Save current user account details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSaveProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveProfile$Response(
    params: {
      body: EditUserProfile
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersSaveProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save current user account details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSaveProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveProfile(
    params: {
      body: EditUserProfile
    },
    context?: HttpContext
  ): Observable<void> {
    return this.usersSaveProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersSaveUiSettings()` */
  static readonly UsersSaveUiSettingsPath = '/api/users/ui-settings';

  /**
   * Save current user UI Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersSaveUiSettings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveUiSettings$Response(
    params: {
      body: CustomerUserUiSettingsModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersSaveUiSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save current user UI Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersSaveUiSettings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usersSaveUiSettings(
    params: {
      body: CustomerUserUiSettingsModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.usersSaveUiSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersTwoFactorSetUp()` */
  static readonly UsersTwoFactorSetUpPath = '/api/users/two-factor-setup/{provider}';

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersTwoFactorSetUp()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorSetUp$Response(
    params: {
      provider: string;
      phoneNumber: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TwoFactorSetupModel>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersTwoFactorSetUpPath, 'get');
    if (params) {
      rb.path('provider', params.provider, {});
      rb.query('phoneNumber', params.phoneNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TwoFactorSetupModel>;
      })
    );
  }

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersTwoFactorSetUp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorSetUp(
    params: {
      provider: string;
      phoneNumber: string;
    },
    context?: HttpContext
  ): Observable<TwoFactorSetupModel> {
    return this.usersTwoFactorSetUp$Response(params, context).pipe(
      map((r: StrictHttpResponse<TwoFactorSetupModel>): TwoFactorSetupModel => r.body)
    );
  }

  /** Path part for operation `usersTwoFactorVerify()` */
  static readonly UsersTwoFactorVerifyPath = '/api/users/two-factor-verify/{provider}';

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersTwoFactorVerify()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorVerify$Response(
    params: {
      provider: string;
      token: string;
      phoneNumber: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UsersService.UsersTwoFactorVerifyPath, 'get');
    if (params) {
      rb.path('provider', params.provider, {});
      rb.query('token', params.token, {});
      rb.query('phoneNumber', params.phoneNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponse>;
      })
    );
  }

  /**
   * Provides Two Factor Auth Setup.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersTwoFactorVerify$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersTwoFactorVerify(
    params: {
      provider: string;
      token: string;
      phoneNumber: string;
    },
    context?: HttpContext
  ): Observable<BooleanResponse> {
    return this.usersTwoFactorVerify$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

}
