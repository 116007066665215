/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BooleanResponse } from '../models/boolean-response';
import { UserDeviceModel } from '../models/user-device-model';

@Injectable({ providedIn: 'root' })
export class UserDevicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userDevicesGetDevices()` */
  static readonly UserDevicesGetDevicesPath = '/api/user-devices/list';

  /**
   * Get current user devices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesGetDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesGetDevices$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserDeviceModel>>> {
    const rb = new RequestBuilder(this.rootUrl, UserDevicesService.UserDevicesGetDevicesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserDeviceModel>>;
      })
    );
  }

  /**
   * Get current user devices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesGetDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesGetDevices(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<UserDeviceModel>> {
    return this.userDevicesGetDevices$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserDeviceModel>>): Array<UserDeviceModel> => r.body)
    );
  }

  /** Path part for operation `userDevicesSaveDevice()` */
  static readonly UserDevicesSaveDevicePath = '/api/user-devices/save';

  /**
   * Save current user device information.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesSaveDevice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDevicesSaveDevice$Response(
    params: {
      body: UserDeviceModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UserDevicesService.UserDevicesSaveDevicePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponse>;
      })
    );
  }

  /**
   * Save current user device information.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesSaveDevice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userDevicesSaveDevice(
    params: {
      body: UserDeviceModel
    },
    context?: HttpContext
  ): Observable<BooleanResponse> {
    return this.userDevicesSaveDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

  /** Path part for operation `userDevicesDeleteDevice()` */
  static readonly UserDevicesDeleteDevicePath = '/api/user-devices/delete/{id}';

  /**
   * Delete User Device.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDevicesDeleteDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesDeleteDevice$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<BooleanResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UserDevicesService.UserDevicesDeleteDevicePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanResponse>;
      })
    );
  }

  /**
   * Delete User Device.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDevicesDeleteDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDevicesDeleteDevice(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<BooleanResponse> {
    return this.userDevicesDeleteDevice$Response(params, context).pipe(
      map((r: StrictHttpResponse<BooleanResponse>): BooleanResponse => r.body)
    );
  }

}
