/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { RecordingsCheckModel } from '../models/recordings-check-model';

@Injectable({ providedIn: 'root' })
export class SystemAdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminTestStorage()` */
  static readonly AdminTestStoragePath = '/api/system-admin/test-storage';

  /**
   * Sync historical faxes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTestStorage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTestStorage$Response(
    params: {
      body: RecordingsCheckModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemAdminService.AdminTestStoragePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sync historical faxes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTestStorage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminTestStorage(
    params: {
      body: RecordingsCheckModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminTestStorage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminFixTemplateRoles()` */
  static readonly AdminFixTemplateRolesPath = '/api/system-admin/fix-templates-roles';

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFixTemplateRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFixTemplateRoles$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemAdminService.AdminFixTemplateRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFixTemplateRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFixTemplateRoles(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminFixTemplateRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminUpdateDomainsCdrCache()` */
  static readonly AdminUpdateDomainsCdrCachePath = '/api/system-admin/update-domains-cdr-cache';

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateDomainsCdrCache()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUpdateDomainsCdrCache$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SystemAdminService.AdminUpdateDomainsCdrCachePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Fix SMS templates roles (use only first time).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateDomainsCdrCache$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUpdateDomainsCdrCache(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.adminUpdateDomainsCdrCache$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
