/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AccountRoleModel } from '../models/account-role-model';
import { AccountRolesGridResponse } from '../models/account-roles-grid-response';
import { AccountSettingsModel } from '../models/account-settings-model';
import { AccountStorageModel } from '../models/account-storage-model';
import { AccountUser } from '../models/account-user';
import { AccountUsersGridResponse } from '../models/account-users-grid-response';
import { LookupGridResponse } from '../models/lookup-grid-response';
import { RecordingsPlanModel } from '../models/recordings-plan-model';
import { RetentionWarningModel } from '../models/retention-warning-model';
import { RolesGridRequest } from '../models/roles-grid-request';
import { SaveAccountSettingsRequest } from '../models/save-account-settings-request';
import { ToggleBetaRequest } from '../models/toggle-beta-request';
import { ToggleBetaResponse } from '../models/toggle-beta-response';
import { UsersGridRequest } from '../models/users-grid-request';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountUsersGetRoles()` */
  static readonly AccountUsersGetRolesPath = '/api/account/roles';

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LookupGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountUsersGetRolesPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LookupGridResponse>;
      })
    );
  }

  /**
   * Get available roles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetRoles(
    params?: {
    },
    context?: HttpContext
  ): Observable<LookupGridResponse> {
    return this.accountUsersGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<LookupGridResponse>): LookupGridResponse => r.body)
    );
  }

  /** Path part for operation `accountRolesGetRoles()` */
  static readonly AccountRolesGetRolesPath = '/api/account/roles';

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles$Response(
    params: {
      body: RolesGridRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountRolesGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountRolesGetRolesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRolesGridResponse>;
      })
    );
  }

  /**
   * Get all account roles.
   *
   * Get the list of company roles
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesGetRoles(
    params: {
      body: RolesGridRequest
    },
    context?: HttpContext
  ): Observable<AccountRolesGridResponse> {
    return this.accountRolesGetRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRolesGridResponse>): AccountRolesGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUsers()` */
  static readonly AccountUsersGetUsersPath = '/api/account/users';

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUsers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers$Response(
    params: {
      body: UsersGridRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountUsersGridResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountUsersGetUsersPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountUsersGridResponse>;
      })
    );
  }

  /**
   * Get all account users.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUsers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersGetUsers(
    params: {
      body: UsersGridRequest
    },
    context?: HttpContext
  ): Observable<AccountUsersGridResponse> {
    return this.accountUsersGetUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUsersGridResponse>): AccountUsersGridResponse => r.body)
    );
  }

  /** Path part for operation `accountUsersGetUserById()` */
  static readonly AccountUsersGetUserByIdPath = '/api/account/user/{id}';

  /**
   * Get account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountUser>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountUsersGetUserByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountUser>;
      })
    );
  }

  /**
   * Get account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetUserById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AccountUser> {
    return this.accountUsersGetUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountUser>): AccountUser => r.body)
    );
  }

  /** Path part for operation `accountUsersDeleteUserById()` */
  static readonly AccountUsersDeleteUserByIdPath = '/api/account/user/{id}';

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersDeleteUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountUsersDeleteUserByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete account user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersDeleteUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersDeleteUserById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountUsersDeleteUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersSaveUser()` */
  static readonly AccountUsersSaveUserPath = '/api/account/user';

  /**
   * Save user details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersSaveUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser$Response(
    params: {
      body: AccountUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountUsersSaveUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save user details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersSaveUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountUsersSaveUser(
    params: {
      body: AccountUser
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountUsersSaveUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountUsersResendInvitation()` */
  static readonly AccountUsersResendInvitationPath = '/api/account/resend-invitation/{email}';

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersResendInvitation()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation$Response(
    params: {
      email: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountUsersResendInvitationPath, 'get');
    if (params) {
      rb.path('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Resend invitation email.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersResendInvitation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersResendInvitation(
    params: {
      email: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.accountUsersResendInvitation$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `accountUsersGetAccountDomains()` */
  static readonly AccountUsersGetAccountDomainsPath = '/api/account/domains';

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountUsersGetAccountDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountUsersGetAccountDomainsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get all account domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountUsersGetAccountDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountUsersGetAccountDomains(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<string>> {
    return this.accountUsersGetAccountDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `accountRolesGetRoleById()` */
  static readonly AccountRolesGetRoleByIdPath = '/api/account/role/{id}';

  /**
   * Get account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesGetRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountRoleModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountRolesGetRoleByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountRoleModel>;
      })
    );
  }

  /**
   * Get account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesGetRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesGetRoleById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<AccountRoleModel> {
    return this.accountRolesGetRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountRoleModel>): AccountRoleModel => r.body)
    );
  }

  /** Path part for operation `accountRolesDeleteRoleById()` */
  static readonly AccountRolesDeleteRoleByIdPath = '/api/account/role/{id}';

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesDeleteRoleById()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountRolesDeleteRoleByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete account role.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesDeleteRoleById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountRolesDeleteRoleById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountRolesDeleteRoleById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountRolesSaveRole()` */
  static readonly AccountRolesSaveRolePath = '/api/account/role';

  /**
   * Save role details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountRolesSaveRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole$Response(
    params: {
      body: AccountRoleModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountRolesSaveRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Save role details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountRolesSaveRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountRolesSaveRole(
    params: {
      body: AccountRoleModel
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountRolesSaveRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountSettingsGetSettings()` */
  static readonly AccountSettingsGetSettingsPath = '/api/account/settings';

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsGetSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountSettingsGetSettingsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingsModel>;
      })
    );
  }

  /**
   * Get account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsGetSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsGetSettings(
    params?: {
    },
    context?: HttpContext
  ): Observable<AccountSettingsModel> {
    return this.accountSettingsGetSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsSaveSettings()` */
  static readonly AccountSettingsSaveSettingsPath = '/api/account/settings';

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsSaveSettings()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings$Response(
    params: {
      body: SaveAccountSettingsRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountSettingsModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountSettingsSaveSettingsPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountSettingsModel>;
      })
    );
  }

  /**
   * Save account settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsSaveSettings$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  accountSettingsSaveSettings(
    params: {
      body: SaveAccountSettingsRequest
    },
    context?: HttpContext
  ): Observable<AccountSettingsModel> {
    return this.accountSettingsSaveSettings$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountSettingsModel>): AccountSettingsModel => r.body)
    );
  }

  /** Path part for operation `accountSettingsPreviewFax()` */
  static readonly AccountSettingsPreviewFaxPath = '/api/account/preview-fax-cover/{filename}';

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsPreviewFax()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax$Response(
    params: {
      filename: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountSettingsPreviewFaxPath, 'get');
    if (params) {
      rb.path('filename', params.filename, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Preview Fax Cover Page.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsPreviewFax$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountSettingsPreviewFax(
    params: {
      filename: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.accountSettingsPreviewFax$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountSettingsToggleBeta()` */
  static readonly AccountSettingsToggleBetaPath = '/api/account/toggle-beta';

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountSettingsToggleBeta()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta$Response(
    params: {
      body: ToggleBetaRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ToggleBetaResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountSettingsToggleBetaPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ToggleBetaResponse>;
      })
    );
  }

  /**
   * Become beta tester or switch to stable.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountSettingsToggleBeta$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountSettingsToggleBeta(
    params: {
      body: ToggleBetaRequest
    },
    context?: HttpContext
  ): Observable<ToggleBetaResponse> {
    return this.accountSettingsToggleBeta$Response(params, context).pipe(
      map((r: StrictHttpResponse<ToggleBetaResponse>): ToggleBetaResponse => r.body)
    );
  }

  /** Path part for operation `accountStorageGetCurrentUsage()` */
  static readonly AccountStorageGetCurrentUsagePath = '/api/account/current-usage';

  /**
   * Get current storage usage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageGetCurrentUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountStorageGetCurrentUsage$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountStorageModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountStorageGetCurrentUsagePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountStorageModel>;
      })
    );
  }

  /**
   * Get current storage usage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageGetCurrentUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountStorageGetCurrentUsage(
    params?: {
    },
    context?: HttpContext
  ): Observable<AccountStorageModel> {
    return this.accountStorageGetCurrentUsage$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountStorageModel>): AccountStorageModel => r.body)
    );
  }

  /** Path part for operation `accountStorageSaveRecordingsRetention()` */
  static readonly AccountStorageSaveRecordingsRetentionPath = '/api/account/save-recordings-retention';

  /**
   * Save account recordings retention limits.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageSaveRecordingsRetention()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageSaveRecordingsRetention$Response(
    params: {
      body: RecordingsPlanModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AccountStorageModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountStorageSaveRecordingsRetentionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AccountStorageModel>;
      })
    );
  }

  /**
   * Save account recordings retention limits.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageSaveRecordingsRetention$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageSaveRecordingsRetention(
    params: {
      body: RecordingsPlanModel
    },
    context?: HttpContext
  ): Observable<AccountStorageModel> {
    return this.accountStorageSaveRecordingsRetention$Response(params, context).pipe(
      map((r: StrictHttpResponse<AccountStorageModel>): AccountStorageModel => r.body)
    );
  }

  /** Path part for operation `accountStorageGetRetentionWarning()` */
  static readonly AccountStorageGetRetentionWarningPath = '/api/account/recordings-retention-warning';

  /**
   * Get hours that will be deleted after plan changes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountStorageGetRetentionWarning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageGetRetentionWarning$Response(
    params: {
      body: RecordingsPlanModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RetentionWarningModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.AccountStorageGetRetentionWarningPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RetentionWarningModel>;
      })
    );
  }

  /**
   * Get hours that will be deleted after plan changes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountStorageGetRetentionWarning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  accountStorageGetRetentionWarning(
    params: {
      body: RecordingsPlanModel
    },
    context?: HttpContext
  ): Observable<RetentionWarningModel> {
    return this.accountStorageGetRetentionWarning$Response(params, context).pipe(
      map((r: StrictHttpResponse<RetentionWarningModel>): RetentionWarningModel => r.body)
    );
  }

}
