/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { MessagingTemplateModel } from '../models/messaging-template-model';
import { TemplateListRequest } from '../models/template-list-request';
import { TemplateListResponse } from '../models/template-list-response';

@Injectable({ providedIn: 'root' })
export class MessagingTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagingTemplateGetList()` */
  static readonly MessagingTemplateGetListPath = '/api/messaging-template/list';

  /**
   * List message templates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateGetList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateGetList$Response(
    params: {
      body: TemplateListRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<TemplateListResponse>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingTemplateService.MessagingTemplateGetListPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TemplateListResponse>;
      })
    );
  }

  /**
   * List message templates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateGetList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateGetList(
    params: {
      body: TemplateListRequest
    },
    context?: HttpContext
  ): Observable<TemplateListResponse> {
    return this.messagingTemplateGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateListResponse>): TemplateListResponse => r.body)
    );
  }

  /** Path part for operation `messagingTemplateGetTemplateById()` */
  static readonly MessagingTemplateGetTemplateByIdPath = '/api/messaging-template/item/{id}';

  /**
   * Get template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateGetTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateGetTemplateById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessagingTemplateModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingTemplateService.MessagingTemplateGetTemplateByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagingTemplateModel>;
      })
    );
  }

  /**
   * Get template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateGetTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateGetTemplateById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<MessagingTemplateModel> {
    return this.messagingTemplateGetTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingTemplateModel>): MessagingTemplateModel => r.body)
    );
  }

  /** Path part for operation `messagingTemplateDeleteTemplateById()` */
  static readonly MessagingTemplateDeleteTemplateByIdPath = '/api/messaging-template/item/{id}';

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateDeleteTemplateById()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateDeleteTemplateById$Response(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingTemplateService.MessagingTemplateDeleteTemplateByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * Delete messaging template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateDeleteTemplateById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagingTemplateDeleteTemplateById(
    params: {
      id: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.messagingTemplateDeleteTemplateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `messagingTemplateSaveTemplate()` */
  static readonly MessagingTemplateSaveTemplatePath = '/api/messaging-template/item';

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagingTemplateSaveTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateSaveTemplate$Response(
    params: {
      body: MessagingTemplateModel
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessagingTemplateModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagingTemplateService.MessagingTemplateSaveTemplatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagingTemplateModel>;
      })
    );
  }

  /**
   * Save messaging template details.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagingTemplateSaveTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  messagingTemplateSaveTemplate(
    params: {
      body: MessagingTemplateModel
    },
    context?: HttpContext
  ): Observable<MessagingTemplateModel> {
    return this.messagingTemplateSaveTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessagingTemplateModel>): MessagingTemplateModel => r.body)
    );
  }

}
